import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
   Box,
   Button,
   Grid,
   IconButton,
   Typography,
   Modal,
} from "@mui/material";

import UploadFileIcon from "@mui/icons-material/UploadFile";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import CloseIcon from "@mui/icons-material/Close";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

export default function FontsEdit({ fonts }) {
   const [selectedFile, setSelectedFile] = useState();
   const [isSelected, setIsSelected] = useState(false);
   const [lastUpdate, setLastUpdate] = useState(Date.now());

   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   useEffect(() => {
      if (selectedFile) {
         handleSubmission();
      }
   }, [selectedFile]);

   const changeHandler = (event) => {
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
   };

   const handleSubmission = () => {
      const formData = new FormData();
      formData.append("FileName", selectedFile ? selectedFile.name : "none");
      formData.append("File", selectedFile);

      fetch(SERVER_URL + "/upload", {
         method: "POST",
         body: formData,
      })
         .then((response) => response.json())
         .then((result) => {
            setSelectedFile();
            setIsSelected(false);

            if (result.success) {
               setLastUpdate(Date.now());
            }
         })
         .catch((error) => {
            console.error("Error:", error);
            setSelectedFile();
            setIsSelected(false);
         });
   };

   return (
      <>
         <Button
            onClick={handleOpen}
            fullWidth
            component='label'
            color='primary'
            variant='outlined'
            startIcon={<FontDownloadIcon fontSize='medium' />}
         >
            Edit Fonts
         </Button>
         <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
         >
            <Box sx={style}>
               <Typography id='modal-modal-title' variant='h6' component='h2'>
                  <Grid container>
                     <Grid item xs={6}>
                        Fonts
                     </Grid>
                     <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <IconButton onClick={handleClose}>
                           <CloseIcon />
                        </IconButton>
                     </Grid>
                     <Grid item xs={12} sx={{ mt: 2 }}>
                        <Button
                           fullWidth
                           component='label'
                           color='primary'
                           variant='outlined'
                        >
                           <input
                              type='file'
                              accept='.ttf, .otf, .woff, .woff2'
                              hidden
                              onChange={changeHandler}
                           />
                           UPLOAD FONT
                        </Button>
                     </Grid>
                  </Grid>
               </Typography>
               <Typography id='modal-modal-description' sx={{ mt: 2 }}>
                  <Grid container sx={{ maxHeight: 600, overflow: "scroll" }}>
                     {fonts.map((font) => (
                        <Grid
                           item
                           xs={12}
                           sx={{
                              fontFamily: font.split(".")[0],
                              fontSize: 20,
                              py: 1,
                              px: 1,
                              borderTop: "1px solid #ccc",
                              "&:first-child": { borderTop: "none" },
                           }}
                        >
                           {font}
                        </Grid>
                     ))}
                  </Grid>
               </Typography>
            </Box>
         </Modal>
      </>
   );
}
