import { useState, useEffect, useRef } from "react";
import { motion, AnimatePresence, layout } from "framer-motion";
import { Rnd } from "react-rnd";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from "@mui/material/Select";

import StyleMenu from "../editor/StyleMenu";
import StyleEditPanel from "../editor/StyleEditPanel";

import FontsEdit from "../editor/FontsEdit";
import ViewerComponent from "../viewer/ViewerComponentTugofWar";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function EditorTugofWar({
   activePoll,
   fonts,
   styles,
   selectedLayout,
   setSelectedLayout,
   updateDB,
   openDataEditor,
}) {
   const previewWidth = useRef();

   const [activeLayout, setActiveLayout] = useState();
   const [lastUpdate, setLastUpdate] = useState(Date.now());
   const [modal, setModal] = useState("global");
   const [saved, setSaved] = useState(true);

   const [previewScale, setPreviewScale] = useState();

   useEffect(() => {
      setTimeout(function () {
         window.dispatchEvent(new Event("resize"));

         setPreviewScale(
            parseInt(window.getComputedStyle(previewWidth.current).width) / 1920
         );
      }, 100);
      {
         /* console.log(window.getComputedStyle(previewWidth.current).width); */
      }
   }, [activeLayout]);

   // RESIZE LISTENER
   const [dimensions, setDimensions] = useState({
      height: window.innerHeight,
      width: window.innerWidth,
   });

   useEffect(() => {
      function handleResize() {
         setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);
      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   // HANDLERS

   useEffect(() => {
      {
         /* console.log(activeLayout); */
      }
   }, [activeLayout]);

   useEffect(() => {
      setActiveLayout(styles[selectedLayout]);
   }, [styles, selectedLayout]);

   const handleUpdate = (key, type, data) => {
      setSaved(false);

      setActiveLayout((prevState) => {
         let newState = { ...prevState };
         newState[key][type] = data;
         return newState;
      });
   };

   const handleModal = (key, data) => {
      setModal(key);
   };

   const handleAdjust = (key, adjustment) => {
      let newPosition = { ...activeLayout[key].position };

      switch (adjustment) {
         case "fit":
            newPosition.width =
               activeLayout.frame.position.width -
               activeLayout.frame.border.left;
            newPosition.xPos =
               0 + activeLayout.frame.border.left * previewScale;

            handleUpdate(key, "position", newPosition);
            break;
         case "center":
            newPosition.xPos =
               activeLayout.frame.position.width / 2 -
               activeLayout[key].position.width / 2;

            handleUpdate(key, "position", newPosition);
            break;
         default:
      }
   };

   return (
      <Grid container spacing={0}>
         <Grid
            item
            xs={5}
            sx={{ py: 2, px: 4, height: "100vh", overflowY: "scroll" }}
         >
            <Typography variant='h4' component='div' sx={{ mb: 3 }}>
               Tug of War Template Editor
            </Typography>

            {!saved && (
               <Typography
                  variant='h6'
                  component='div'
                  sx={{
                     fontSize: 12,
                     color: "#fff",
                     position: "absolute",
                     top: 0,
                     left: 0,
                     backgroundColor: "rgba(255,0,0,.7)",
                     width: "100%",
                     py: 1,
                     px: 2,
                  }}
               >
                  UNSAVED CHANGES
               </Typography>
            )}
            <Grid
               container
               justifyContent='center'
               alignItems='center'
               spacing={4}
            >
               <Grid item xs={8}>
                  <Button variant='outlined' href='/'>
                     BACK TO DASHBOARD
                  </Button>

                  {/* <Button
                     variant='outlined'
                     sx={{ ml: 2 }}
                     color='warning'
                     onClick={() => openDataEditor(true)}
                  >
                     Edit Preview Data
                  </Button> */}
               </Grid>
               <Grid item xs={4}>
                  <FontsEdit fonts={fonts} />
               </Grid>
               <Grid item xs={6}>
                  <StyleMenu
                     styles={styles}
                     selectedLayout={selectedLayout}
                     setSelectedLayout={setSelectedLayout}
                  />
               </Grid>
               <Grid item xs={6}>
                  {selectedLayout && (
                     <>
                        <Button
                           color='success'
                           variant='contained'
                           sx={{ mr: 2 }}
                           onClick={() => {
                              updateDB({
                                 action: "update",
                                 key: selectedLayout,
                                 layout: activeLayout,
                              });
                              setSaved(true);
                           }}
                        >
                           SAVE
                        </Button>
                        <Button
                           color='warning'
                           variant='contained'
                           onClick={() => {
                              updateDB({
                                 action: "insert",
                                 key: null,
                                 layout: activeLayout,
                              });
                           }}
                        >
                           SAVE AS...
                        </Button>
                        {selectedLayout !== "default" && (
                           <Button
                              sx={{ ml: 4 }}
                              color='error'
                              variant='contained'
                              onClick={() => {
                                 updateDB({
                                    action: "delete",
                                    key: selectedLayout,
                                    layout: null,
                                 });
                              }}
                           >
                              DELETE
                           </Button>
                        )}
                     </>
                  )}
               </Grid>
            </Grid>

            {activeLayout && (
               <StyleEditPanel
                  layoutId={selectedLayout}
                  activePanel={modal}
                  data={activeLayout}
                  fonts={fonts}
                  callback={handleUpdate}
               />
            )}
         </Grid>
         <Grid
            item
            xs={7}
            sx={{
               position: "relative",
               backgroundColor: "#333",
            }}
            ref={previewWidth}
         >
            {previewScale && (
               <Typography
                  variant='body1'
                  component='div'
                  sx={{
                     transform: "scale(" + previewScale + ")",
                     transformOrigin: "top left",
                     position: "relative",

                     // TRANSPARENCY BG
                     backgroundImage:
                        "linear-gradient(45deg, #444 25%, transparent 25%, transparent 75%, #444 75%), linear-gradient(45deg, #444 25%, #333 25%, #333 75%, #444 75%)",
                     backgroundSize: "40px 40px",
                     backgroundPosition: "0px 0px, 20px 20px",

                     width: 1920,
                     height: 1080,
                     boxSizing: "border-box",
                     fontFamily: activeLayout
                        ? activeLayout.global.style.fontFamily.split(".")[0]
                        : "unset",
                     fontWeight: activeLayout
                        ? activeLayout.global.style.fontWeight
                        : "normal",
                     overflow: "hidden",
                  }}
               >
                  <ViewerComponent
                     key={
                        "selectedLayout-" + selectedLayout + "-" + previewScale
                     }
                     edit={true}
                     activeLayout={activeLayout}
                     activePoll={activePoll}
                     previewScale={previewScale}
                     handleModal={handleModal}
                     handleUpdate={handleUpdate}
                     handleAdjust={handleAdjust}
                  />
               </Typography>
            )}
         </Grid>
      </Grid>
   );
}
