import React, { useState, useEffect, useContext } from "react";
import { SocketContext } from "../context/socket";
import _ from "lodash";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";

function Emotes(props) {
   const socket = useContext(SocketContext);
   const [emotes, setEmotes] = useState([]);
   const [channelEmotes, setChannelEmotes] = useState([]);
   const [filter, setFilter] = useState("");
   const [filterChannel, setFilterChannel] = useState("");

   useEffect(() => {
      // INITIAL LOAD
      socket.emit("getEmotes", (response) => {
         setEmotes(response.global);
         setChannelEmotes(response.channel);
      });
   });

   const handleEmoteSelect = (id) => {
      props.callback(props.name, id);
      props.closeModal({});
   };

   const clearFilter = () => {
      setFilter("");
   };

   const clearFilterChannel = () => {
      setFilterChannel("");
   };

   return (
      <>
         {channelEmotes.length === 0 && (
            <Typography
               variant='h6'
               component='div'
               sx={{ ml: 3, mt: 10, mb: 2 }}
            >
               No Channel Emotes!
            </Typography>
         )}

         {channelEmotes.length > 0 && (
            <>
               <Typography
                  variant='h4'
                  component='div'
                  sx={{ ml: 3, mt: 10, mb: 2 }}
               >
                  Channel Emotes
               </Typography>

               <TextField
                  key='filterChannel'
                  label='Filter'
                  value={filterChannel}
                  placeholder='Channel Emote Title'
                  name='filterChannel'
                  id='filled-channel'
                  variant='outlined'
                  size='small'
                  onChange={(e) => setFilterChannel(e.target.value)}
                  sx={{ ml: 3 }}
                  InputProps={{
                     endAdornment: (
                        <>
                           {filterChannel && (
                              <InputAdornment position='end'>
                                 <IconButton
                                    edge='end'
                                    color='primary'
                                    onClick={clearFilterChannel}
                                 >
                                    <CloseIcon />
                                 </IconButton>
                              </InputAdornment>
                           )}
                        </>
                     ),
                  }}
               />
            </>
         )}

         <Grid container sx={{ m: 2, alignItems: "center" }}>
            {channelEmotes &&
               channelEmotes.length > 0 &&
               _.chain(channelEmotes)
                  .filter(function (row) {
                     if (filterChannel) {
                        return _.includes(
                           row.name.toLowerCase(),
                           filterChannel.toLowerCase()
                        );
                     } else {
                        return row;
                     }
                  })
                  .orderBy(["name"], ["asc"])
                  .value()
                  .map((emote, i) => (
                     <React.Fragment key={emote.id}>
                        <Grid
                           item
                           xs={2}
                           sx={{
                              textAlign: "center",
                              border: "1px solid #ccc",
                              m: 1,
                           }}
                        >
                           <Box
                              sx={{
                                 p: 3,
                              }}
                              className='emoteBox'
                              onClick={() => handleEmoteSelect(emote.id)}
                           >
                              <img
                                 src={emote.images.url_4x}
                                 height='80'
                                 alt='Emote'
                              />
                              {emote.name}
                              <br />
                              ID:{" "}
                              {emote.id.length > 10
                                 ? emote.id.slice(0, 10) + "..."
                                 : emote.id}
                           </Box>
                        </Grid>
                     </React.Fragment>
                  ))}
         </Grid>

         <Typography variant='h4' component='div' sx={{ ml: 3, mt: 10 }}>
            Global Emotes
         </Typography>

         <TextField
            key='filter'
            label='Filter'
            value={filter}
            placeholder='Emote Title'
            name='filter'
            id='filled-size-small'
            variant='outlined'
            size='small'
            onChange={(e) => setFilter(e.target.value)}
            sx={{ ml: 3 }}
            InputProps={{
               endAdornment: (
                  <>
                     {filter && (
                        <InputAdornment position='end'>
                           <IconButton
                              edge='end'
                              color='primary'
                              onClick={clearFilter}
                           >
                              <CloseIcon />
                           </IconButton>
                        </InputAdornment>
                     )}
                  </>
               ),
            }}
         />

         <Grid container sx={{ m: 2, alignItems: "center" }}>
            {emotes &&
               emotes.length > 0 &&
               _.chain(emotes)
                  .filter(function (row) {
                     if (filter) {
                        return _.includes(
                           row.name.toLowerCase(),
                           filter.toLowerCase()
                        );
                     } else {
                        return row;
                     }
                  })
                  .orderBy(["name"], ["asc"])
                  .value()
                  .map((emote, i) => (
                     <React.Fragment key={emote.id}>
                        <Grid
                           item
                           xs={2}
                           sx={{
                              textAlign: "center",
                              border: "1px solid #ccc",
                              m: 1,
                           }}
                        >
                           <Box
                              sx={{
                                 p: 3,
                              }}
                              className='emoteBox'
                              onClick={() => handleEmoteSelect(emote.id)}
                           >
                              <img
                                 src={emote.images.url_4x}
                                 height='80'
                                 alt='Emote'
                              />
                              {emote.name}
                              <br />
                              ID:{" "}
                              {emote.id.length > 10
                                 ? emote.id.slice(0, 10) + "..."
                                 : emote.id}
                           </Box>
                        </Grid>
                     </React.Fragment>
                  ))}
         </Grid>
      </>
   );
}

export default Emotes;
