import { useState, useEffect } from "react";
import { motion, useTransform, useSpring } from "framer-motion";
import Box from "@mui/material/Box";

import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function GraphBar(props) {
   const [widthInput, setWidthInput] = useState(0);
   const [heightInput, setHeightInput] = useState(0);
   const colorOutput = ["#596063", "#8d2618", "#0f39c3", "#0da72e"];

   const width = useSpring(widthInput, { damping: 10, stiffness: 28 });
   const widthPct = useTransform(
      width,
      (value) => `${value + (props.showLetter ? 0 : 0)}%`
   );
   const widthRange = [0, 10, 50, 100];
   const widthColor = useTransform(width, widthRange, colorOutput);

   const height = useSpring(heightInput, { damping: 10, stiffness: 28 });
   const heightPct = useTransform(height, (value) => `${value}%`);
   const heightRange = [0, 10, 50, 100];
   const heightColor = useTransform(height, heightRange, colorOutput);

   const updateGraph = _.debounce(function () {
      if (props.pct) {
         setWidthInput(props.pct);
         width.set(props.pct);

         setHeightInput(props.pct);
         height.set(props.pct);
      } else {
         setWidthInput(0);
         width.set(0);

         setHeightInput(0);
         height.set(0);
      }
   }, 1000);

   useEffect(() => {
      updateGraph();
   }, [props.pct]);

   return (
      <Box
         sx={{
            position: "relative",
            px: props.barStyles.px,
            py: props.barStyles.py,
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
         }}
      >
         {props.orientation === "horizontal" && (
            <>
               <motion.div
                  className='outline'
                  key={"graphbar" + props.pollId + props.barColor}
                  style={{
                     top: 0,
                     left: 0,
                     position: "relative",
                     backgroundImage: `url(${SERVER_URL}/uploads/${props.barBackground.image})`,
                     backgroundPosition: `${props.barBackground.topBottom} ${props.barBackground.leftRight}`,
                     backgroundRepeat: props.barBackground.repeat,
                     backgroundSize: props.barBackground.size
                        ? props.barBackground.size
                        : "auto",
                     backgroundColor: props.barCustomColors
                        ? props.barCustomColors[
                             "bar" + parseInt(props.barId + 1) + "Color"
                          ]
                        : props.barColor
                        ? props.barColor
                        : heightColor,
                     borderTopWidth: props.barBorders
                        ? props.barBorders.top
                        : 0,
                     borderLeftWidth: props.barBorders
                        ? props.barBorders.left
                        : 0,
                     borderRightWidth: props.barBorders
                        ? props.barBorders.right
                        : 0,
                     borderBottomWidth: props.barBorders
                        ? props.barBorders.bottom
                        : 0,
                     borderTopColor: props.barBorders
                        ? props.barBorders.color
                        : "transparent",
                     borderLeftColor: props.barBorders
                        ? props.barBorders.color
                        : "transparent",
                     borderRightColor: props.barBorders
                        ? props.barBorders.color
                        : "transparent",
                     borderBottomColor: props.barBorders
                        ? props.barBorders.color
                        : "transparent",
                     borderStyle: props.barBorders
                        ? props.barBorders.style
                        : "none",
                     borderTopLeftRadius:
                        props.barBorders &&
                        props.barBorders.top &&
                        props.barBorders.left
                           ? props.barBorders.radius
                           : 0,
                     borderTopRightRadius:
                        props.barBorders &&
                        props.barBorders.top &&
                        props.barBorders.right
                           ? props.barBorders.radius
                           : 0,
                     borderBottomLeftRadius:
                        props.barBorders &&
                        props.barBorders.bottom &&
                        props.barBorders.left
                           ? props.barBorders.radius
                           : 0,
                     borderBottomRightRadius:
                        props.barBorders &&
                        props.barBorders.bottom &&
                        props.barBorders.right
                           ? props.barBorders.radius
                           : 0,
                     height: "100%",
                     minWidth: props.barStyles.minWidth,
                     width: widthPct,
                     boxSizing: "border-box",
                  }}
                  animate={{ width }}
                  transition={{ duration: 0.5 }}
               >
                  {props.showPct && (
                     <div
                        style={{
                           position: "absolute",
                           [props.barStyles.pctAlign]: 0,
                           zIndex: 10,
                           width: "100%",
                           height: "100%",
                           textAlign: props.barStyles.pctAlign,
                           color: props.barStyles.pctColor,
                           WebkitTextStroke: `${props.barStyles.pctStroke}px ${props.barStyles.pctStrokeColor}`,
                           fontSize: props.barStyles.pctFontSize,
                           boxSizing: "border-box",
                        }}
                     >
                        <div
                           className='outline'
                           style={{
                              position: "relative",
                              left: props.barStyles.pctX,
                              [props.barStyles.pctTopBottom]:
                                 props.barStyles.pctY + 50 + "%",
                              transform: "translateY(-50%)",
                           }}
                        >
                           {widthInput}%
                        </div>
                     </div>
                  )}
               </motion.div>
               <div
                  className='outline'
                  style={{
                     [props.barStyles.letterTopBottom]:
                        props.barStyles.letterY + 50 + "%",
                     transform: "translateY(-50%)",
                     left: props.barStyles.letterX + "%",
                     position: "absolute",
                     zIndex: 10,
                     height: "auto",
                     lineHeight: "100%",
                     fontSize: props.barStyles.letterFontSize,
                     color: props.barStyles.letterColor,
                     WebkitTextStroke: `${props.barStyles.letterStroke}px ${props.barStyles.letterStrokeColor}`,
                  }}
               >
                  {props.optionText}
               </div>
            </>
         )}
         {props.orientation === "vertical" && (
            <>
               <motion.div
                  className='outline'
                  key={"bar" + props.barId}
                  style={{
                     bottom: 0,
                     left: 0,
                     position: "absolute",
                     zIndex: 1,
                     backgroundImage: `url(${SERVER_URL}/uploads/${props.barBackground.image})`,
                     backgroundPosition: `${props.barBackground.topBottom} ${props.barBackground.leftRight}`,
                     backgroundRepeat: props.barBackground.repeat,
                     backgroundSize: props.barBackground.size
                        ? props.barBackground.size
                        : "auto",
                     backgroundColor: props.barCustomColors
                        ? props.barCustomColors[
                             "bar" + parseInt(props.barId + 1) + "Color"
                          ]
                        : props.barColor
                        ? props.barColor
                        : heightColor,
                     borderTopWidth: props.barBorders
                        ? props.barBorders.top
                        : 0,
                     borderLeftWidth: props.barBorders
                        ? props.barBorders.left
                        : 0,
                     borderRightWidth: props.barBorders
                        ? props.barBorders.right
                        : 0,
                     borderBottomWidth: props.barBorders
                        ? props.barBorders.bottom
                        : 0,
                     borderTopColor: props.barBorders
                        ? props.barBorders.color
                        : "transparent",
                     borderLeftColor: props.barBorders
                        ? props.barBorders.color
                        : "transparent",
                     borderRightColor: props.barBorders
                        ? props.barBorders.color
                        : "transparent",
                     borderBottomColor: props.barBorders
                        ? props.barBorders.color
                        : "transparent",
                     borderStyle: props.barBorders
                        ? props.barBorders.style
                        : "none",
                     borderTopLeftRadius:
                        props.barBorders &&
                        props.barBorders.top &&
                        props.barBorders.left
                           ? props.barBorders.radius
                           : 0,
                     borderTopRightRadius:
                        props.barBorders &&
                        props.barBorders.top &&
                        props.barBorders.right
                           ? props.barBorders.radius
                           : 0,
                     borderBottomLeftRadius:
                        props.barBorders &&
                        props.barBorders.bottom &&
                        props.barBorders.left
                           ? props.barBorders.radius
                           : 0,
                     borderBottomRightRadius:
                        props.barBorders &&
                        props.barBorders.bottom &&
                        props.barBorders.right
                           ? props.barBorders.radius
                           : 0,
                     width: "100%",
                     height: heightPct,
                     minHeight: props.barStyles.minHeight,
                     maxHeight: "100%",
                     boxSizing: "border-box",
                  }}
                  animate={{ height }}
                  transition={{ duration: 0 }}
                  key={"barId" + props.barId}
               >
                  {props.showPct && (
                     <div
                        className='outline'
                        style={{
                           [props.barStyles.pctTopBottom]: 0,
                           left: 50 + props.barStyles.pctX + "%",
                           transform: "translateX(-50%)",
                           position: "absolute",
                           zIndex: 10,
                           width: "100%",
                           textAlign: props.barStyles.pctAlign,
                           color: props.barStyles.pctColor,
                           WebkitTextStroke: `${props.barStyles.pctStroke}px ${props.barStyles.pctStrokeColor}`,
                           fontSize: props.barStyles.pctFontSize,
                        }}
                     >
                        <div
                           className='outline'
                           style={{
                              [props.barStyles.pctAlign]: 0,
                              [props.barStyles.pctTopBottom]:
                                 props.barStyles.pctY,
                              position: "absolute",
                              width: "100%",
                           }}
                        >
                           {heightInput}%
                        </div>
                     </div>
                  )}
               </motion.div>
               <div
                  className='outline'
                  style={{
                     [props.barStyles.letterTopBottom]:
                        props.barStyles.letterY + "%",
                     transform: "translateX(-50%)",
                     left: 50 + props.barStyles.letterX + "%",
                     position: "absolute",
                     zIndex: 10,
                     width: "100%",
                     textAlign: "center",
                     fontSize: props.barStyles.letterFontSize,
                     color: props.barStyles.letterColor,
                     WebkitTextStroke: `${props.barStyles.letterStroke}px ${props.barStyles.letterStrokeColor}`,
                  }}
               >
                  {props.optionText}
               </div>
            </>
         )}
      </Box>
   );
}

export default GraphBar;
