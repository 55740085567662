import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function SelectMenus({
   init,
   parentKey,
   fieldKey,
   handleSelectChange,
   fieldLabel = null,
}) {
   const [selected, setSelected] = useState(init);
   const [label, setLabel] = useState("");
   const [menuOptions, setMenuOptions] = useState([]);

   // USE EFFECT
   useEffect(() => {
      handleSelectChange(parentKey, fieldKey, selected);
   }, [selected]);

   useEffect(() => {
      if (fieldKey.includes("xAlign")) {
         setMenuOptions([
            { label: "Left", value: "left" },
            { label: "Right", value: "right" },
         ]);
      }
      if (
         fieldKey.toLowerCase().includes("leftright") ||
         fieldKey.toLowerCase().includes("textalign") ||
         fieldKey.toLowerCase().includes("pctalign")
      ) {
         setMenuOptions([
            { label: "Left", value: "left" },
            { label: "Center", value: "center" },
            { label: "Right", value: "right" },
         ]);
      }

      if (fieldKey.includes("yAlign")) {
         setMenuOptions([
            { label: "Top", value: "top" },
            { label: "Bottom", value: "bottom" },
         ]);
      }

      if (fieldKey == "pctTopBottom" || fieldKey == "letterTopBottom") {
         setMenuOptions([
            { label: "Top", value: "top" },
            { label: "Bottom", value: "bottom" },
         ]);
      }

      if (
         fieldKey.toLowerCase().includes("topbottom") &&
         fieldKey != "pctTopBottom" &&
         fieldKey != "letterTopBottom"
      ) {
         setMenuOptions([
            { label: "Top", value: "top" },
            { label: "Center", value: "center" },
            { label: "Bottom", value: "bottom" },
         ]);
      }

      if (fieldKey.toLowerCase().includes("repeat")) {
         setMenuOptions([
            { label: "No Repeat", value: "no-repeat" },
            { label: "Repeat", value: "repeat" },
            { label: "Horizontal Repeat", value: "repeat-x" },
            { label: "Vertical Repeat", value: "repeat-y" },
         ]);
      }

      if (fieldKey.toLowerCase().includes("fontweight")) {
         setMenuOptions([
            { label: "Default", value: "unset" },
            { label: "Bold", value: "bold" },
            { label: "Light", value: "lighter" },
         ]);
      }

      if (fieldKey.includes("direction")) {
         setMenuOptions([
            { label: "Columns", value: "row" },
            { label: "Rows", value: "column" },
         ]);
      }

      if (fieldKey.includes("orientation")) {
         setMenuOptions([
            { label: "Horizontal", value: "horizontal" },
            { label: "Vertical", value: "vertical" },
         ]);
      }
   }, [fieldKey]);

   const handleStyleChange = (event) => {
      setSelected(event.target.value);
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Grid
            container
            alignItems='center'
            justifyContent='center'
            sx={{ textAlign: "left" }}
         >
            {fieldLabel && (
               <Grid
                  item
                  xs={4}
                  sx={{
                     p: 1,
                     fontWeight: "bold",
                     textTransform: "uppercase",
                     fontSize: 11,
                  }}
               >
                  {fieldLabel}
               </Grid>
            )}
            <Grid item xs={fieldLabel ? 8 : 12}>
               {menuOptions.length > 0 && (
                  <Select
                     value={selected}
                     onChange={handleStyleChange}
                     sx={{ width: "100%" }}
                     size='small'
                  >
                     {menuOptions.map((row) => (
                        <MenuItem value={row.value}>{row.label}</MenuItem>
                     ))}
                  </Select>
               )}

               {menuOptions.length === 0 && <>Loading...</>}
            </Grid>
         </Grid>
      </Box>
   );
}
