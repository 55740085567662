import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function FontMenu({
   layoutId,
   activePanel,
   section,
   field,
   font,
   fonts,
   callback,
}) {
   const handleChange = (event) => {
      callback(activePanel, section, field, event.target.value);
   };

   const handleClearClick = () => {
      callback(activePanel, section, field, "unset");
   };

   return (
      <Box
         sx={{
            width: "100%",
            backgroundColor: "rgba(255,255,255,.7)",
            py: 1,
         }}
      >
         <FormControl fullWidth>
            <InputLabel id='styles-menu'>Fonts</InputLabel>
            <Select
               labelId='styles-menu'
               id='styles-menu-select'
               value={font ? font : ""}
               label='Font'
               onChange={handleChange}
               displayEmpty
               sx={{
                  "& .MuiSelect-iconOutlined": { display: font ? "none" : "" },
                  "&.Mui-focused .MuiIconButton-root": {
                     color: "primary.main",
                  },
                  fontFamily: font.split(".")[0],
                  fontSize: 18,
               }}
               endAdornment={
                  <IconButton
                     sx={{ visibility: font ? "visible" : "hidden" }}
                     onClick={handleClearClick}
                  >
                     <ClearIcon />
                  </IconButton>
               }
            >
               <MenuItem value='unset' key={"font-" + font}>
                  Default (Global)
               </MenuItem>
               {fonts &&
                  fonts.map((font) => (
                     <MenuItem
                        value={font}
                        key={"font-" + font}
                        sx={{ fontFamily: font.split(".")[0], fontSize: 20 }}
                     >
                        {font}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
      </Box>
   );
}
