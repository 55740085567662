"use strict";

import React from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

class ColorPicker extends React.Component {
   state = {
      displayColorPicker: false,
      color: this.props.color,
   };

   handleClick = () => {
      this.props.updateSwatches();
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
      this.setState({
         color:
            parseInt(this.state.color.a) == 0
               ? { ...this.state.color.a, a: 1 }
               : this.state.color,
      });
   };

   handleClose = () => {
      this.setState({ displayColorPicker: false });
   };

   handleChange = (color) => {
      this.setState({ color: color.rgb });
      this.props.callback(color.rgb, this.props.id, this.props.col);
   };

   render() {
      const styles = reactCSS({
         default: {
            color: {
               width: this.props.width,
               height: this.props.height,
               borderRadius: "2px",
               background: `rgba(${this.props.color.r}, ${this.props.color.g}, ${this.props.color.b}, ${this.props.color.a})`,
            },
            swatch: {
               padding: "0",
               background: "#fff",
               borderRadius: "1px",
               boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
               display: "inline-block",
               cursor: "pointer",
               backgroundImage:
                  "linear-gradient(45deg, #444 25%, transparent 25%, transparent 75%, #444 75%), linear-gradient(45deg, #444 25%, #333 25%, #333 75%, #444 75%)",
               backgroundSize: "20px 20px",
               backgroundPosition: "0px 0px, 10px 10px",
            },
            popover: {
               position: "absolute",
               zIndex: "1000",
            },
            cover: {
               position: "fixed",
               top: "0px",
               right: "0px",
               bottom: "0px",
               left: "0px",
            },
         },
      });

      return (
         <div style={{ marginTop: 20 }}>
            <div style={styles.swatch} onClick={this.handleClick}>
               <div
                  style={{
                     position: "absolute",
                     color: "#666",
                     top: -20,
                     fontSize: 12,
                  }}
               >
                  COLOR PICKER:
               </div>
               <div style={styles.color} />
            </div>

            <IconButton
               sx={{ top: -10 }}
               onClick={() =>
                  this.handleChange({ rgb: { r: 0, g: 0, b: 0, a: 0 } })
               }
            >
               <ClearIcon />
            </IconButton>

            {this.state.displayColorPicker ? (
               <div style={styles.popover}>
                  <div style={styles.cover} onClick={this.handleClose} />
                  <SketchPicker
                     presetColors={this.props.presetColors}
                     color={this.state.color}
                     onChange={this.handleChange}
                  />
               </div>
            ) : null}
         </div>
      );
   }
}

export default ColorPicker;
