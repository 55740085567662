import * as React from "react";
import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

// CONTROLS
import Borders from "./controls/Borders";
import Background from "./controls/Background";
import DropShadow from "./controls/DropShadow";
import Leaderboard from "./controls/Leaderboard";

// ICONS
import SettingsIcon from "@mui/icons-material/Settings";
import PictureInPictureIcon from "@mui/icons-material/PictureInPicture";
import StyleIcon from "@mui/icons-material/Style";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import PaletteIcon from "@mui/icons-material/Palette";
import LayersIcon from "@mui/icons-material/Layers";
import ListAltIcon from "@mui/icons-material/ListAlt";

// COMPONENTS
import RowWrapper from "./RowWrapper";
import ObjectEdit from "./ObjectEdit";

// TABS
interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function ParentTabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`parent-tabpanel-${index}`}
         aria-labelledby={`parent-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ px: 3, backgroundColor: "#efefef" }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`tabpanel-${index}`}
         aria-labelledby={`tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ pt: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

function a11yTabsProps(index: number) {
   return {
      id: `parent-tab-${index}`,
      "aria-controls": `parent-tabpanel-${index}`,
   };
}

function a11yProps(index: number) {
   return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
   };
}

function getTabIcon(section) {
   switch (section) {
      case "parameters":
         return <SettingsIcon />;
         break;
      case "position":
         return <PictureInPictureIcon />;
         break;
      case "style":
         return <StyleIcon />;
         break;
      case "background":
         return <WallpaperIcon />;
         break;
      case "border":
         return <BorderOuterIcon />;
         break;
      case "dropshadow":
         return <LayersIcon />;
         break;
      case "leaderboard":
         return <ListAltIcon />;
         break;
      case "customColors":
         return <PaletteIcon />;
         break;
   }
}

// STYLE EDIT PANEL COMPONENT

export default function StyleEditPanel({
   layoutId,
   activePanel,
   data,
   fonts,
   callback,
   setSaved,
}) {
   const [id, setId] = React.useState();
   const [tab, setTab] = React.useState(0);
   const [subTab, setSubTab] = React.useState(0);

   const [presetColors, setPresetColors] = useState([]);

   // COLOR PICKER
   const updatePresetColors = () => {
      let colors = ["#000", "#fff", "rgba(0,0,0,0)"];

      setPresetColors(colors);
   };

   const setColor = (parentKey, fieldKey, obj) => {
      const value = colorObj2rgba(obj);

      const newData = {
         ...data,
         [id]: {
            ...data[id],
            [parentKey]: { ...data[id][parentKey], [fieldKey]: value },
         },
      };
      callback(id, parentKey, newData[id][parentKey]);
   };

   const colorObj2rgba = (object) => {
      return `rgba(${object.r},${object.g},${object.b},${object.a})`;
   };

   const convertColor2Obj = (color) => {
      if (color.includes("#")) {
         return hexToRGB(color);
      } else {
         let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
         return { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
      }
   };

   const hexToRGB = (hex) => {
      var r = parseInt(hex.slice(1, 3), 16),
         g = parseInt(hex.slice(3, 5), 16),
         b = parseInt(hex.slice(5, 7), 16);
      return { r: r, g: g, b: b, a: 1 };
   };

   const rgba2hex = (color) => {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
         (1 << 24) +
         (parseInt(rgba[0]) << 16) +
         (parseInt(rgba[1]) << 8) +
         parseInt(rgba[2])
      )
         .toString(16)
         .slice(1)}`;

      return hex;
   };

   useEffect(() => {
      if (activePanel) {
         const keys = Object.keys(data);
         let index = keys.findIndex(function (element) {
            return element === activePanel;
         });

         setTab(index);
         setSubTab(0);
         setId(activePanel);
      }
   }, [activePanel]);

   const handleTabsChange = (event: React.SyntheticEvent, newValue: number) => {
      setId(Object.keys(data)[newValue]);
      setTab(newValue);
      setSubTab(0);
   };

   const handleSubTabsChange = (
      event: React.SyntheticEvent,
      newValue: number
   ) => {
      setSubTab(newValue);
   };

   const handleCheckboxChange = (e) => {
      const parentKey = e.target.getAttribute("parentKey");
      const key = e.target.getAttribute("fieldKey");
      const value = e.target.checked;

      const newData = {
         ...data,
         [id]: {
            ...data[id],
            [parentKey]: { ...data[id][parentKey], [key]: value },
         },
      };

      callback(id, parentKey, newData[id][parentKey]);
   };

   const handleNumberChange = (e) => {
      const parentKey = e.target.getAttribute("parentKey");
      const fieldKey = e.target.getAttribute("fieldKey");
      const value = parseFloat(e.target.value);

      const newData = {
         ...data,
         [id]: {
            ...data[id],
            [parentKey]: { ...data[id][parentKey], [fieldKey]: value },
         },
      };
      callback(id, parentKey, newData[id][parentKey]);
   };

   const handleNumberSliderChange = (parentKey, fieldKey, value) => {
      const newData = {
         ...data,
         [id]: {
            ...data[id],
            [parentKey]: { ...data[id][parentKey], [fieldKey]: value },
         },
      };
      callback(id, parentKey, newData[id][parentKey]);
   };

   const handleTextChange = (e) => {
      const parentKey = e.target.getAttribute("parentKey");
      const key = e.target.getAttribute("fieldKey");
      const value = e.target.value;

      const newData = {
         ...data,
         [id]: {
            ...data[id],
            [parentKey]: { ...data[id][parentKey], [key]: value },
         },
      };

      callback(id, parentKey, newData[id][parentKey]);
   };

   const handleSelectChange = (parentKey, fieldKey, value) => {
      const newData = {
         ...data,
         [id]: {
            ...data[id],
            [parentKey]: { ...data[id][parentKey], [fieldKey]: value },
         },
      };
      callback(id, parentKey, newData[id][parentKey]);
   };

   const handleFileChange = (id, parentKey, key, value) => {
      const newData = {
         ...data,
         [id]: {
            ...data[id],
            [parentKey]: { ...data[id][parentKey], [key]: value },
         },
      };

      callback(id, parentKey, newData[id][parentKey]);
   };

   const handleObjectChange = (parentKey, fieldKey, value) => {
      const newData = {
         ...data,
         [id]: {
            ...data[id],
            [parentKey]: { ...data[id][parentKey], [fieldKey]: value },
         },
      };

      callback(id, parentKey, newData[id][parentKey]);
   };

   return (
      <>
         {data && (
            <Box sx={{ mt: 2, p: 0 }}>
               <Typography
                  id='title'
                  sx={{ mt: 2, textTransform: "uppercase" }}
                  variant='h3'
               >
                  {Object.keys(data)[tab]}
               </Typography>
               <Typography id='description' sx={{ mt: 2 }}>
                  <Grid container>
                     <Grid item xs={3}>
                        <Tabs
                           value={tab}
                           onChange={handleTabsChange}
                           orientation='vertical'
                           sx={{
                              borderLeft: 1,
                              borderRight: 1,
                              borderColor: "divider",
                              width: "100%",
                           }}
                        >
                           {Object.keys(data).map((block) => (
                              <Tab
                                 label={block}
                                 sx={{
                                    alignItems: "flex-start",
                                 }}
                                 {...a11yTabsProps(
                                    activePanel ? activePanel : 0
                                 )}
                              />
                           ))}
                        </Tabs>
                     </Grid>
                     <Grid item xs={9}>
                        {Object.keys(data).map((block, c) => (
                           <ParentTabPanel value={tab} index={c}>
                              <Grid
                                 container
                                 direction='row'
                                 justifyContent='center'
                                 alignItems='flex-start'
                                 spacing={2}
                              >
                                 <Grid item xs={12}>
                                    <Tabs
                                       value={subTab}
                                       onChange={handleSubTabsChange}
                                    >
                                       {Object.keys(data[block]).map(
                                          (section) => (
                                             <Tab
                                                sx={{
                                                   fontSize: 9,
                                                }}
                                                className={"tab"}
                                                icon={getTabIcon(section)}
                                                label={section}
                                                {...a11yProps(0)}
                                             />
                                          )
                                       )}
                                    </Tabs>

                                    {Object.keys(data[block]).map(
                                       (section, i) => (
                                          <TabPanel value={subTab} index={i}>
                                             <Grid
                                                container
                                                direction='row'
                                                justifyContent='center'
                                                alignItems='flex-start'
                                                sx={{ mb: 8 }}
                                             >
                                                <Grid item xs={12}>
                                                   {section ===
                                                      "background" && (
                                                      <RowWrapper
                                                         label={section}
                                                      >
                                                         <Background
                                                            init={
                                                               data[block][
                                                                  section
                                                               ]
                                                            }
                                                            parentKey={block}
                                                            fieldKey={section}
                                                            layoutId={layoutId}
                                                            activePanel={
                                                               activePanel
                                                            }
                                                            handleBackgroundChange={
                                                               callback
                                                            }
                                                         />
                                                      </RowWrapper>
                                                   )}
                                                   {section === "border" && (
                                                      <RowWrapper
                                                         label={section}
                                                      >
                                                         <Borders
                                                            init={
                                                               data[block][
                                                                  section
                                                               ]
                                                            }
                                                            parentKey={block}
                                                            fieldKey={section}
                                                            handleBorderChange={
                                                               callback
                                                            }
                                                         />
                                                      </RowWrapper>
                                                   )}
                                                   {section ===
                                                      "leaderboard" && (
                                                      <RowWrapper
                                                         label={section}
                                                      >
                                                         <Leaderboard
                                                            init={
                                                               data[block][
                                                                  section
                                                               ]
                                                            }
                                                            activePanel={id}
                                                            layoutId={layoutId}
                                                            fonts={fonts}
                                                            parentKey={block}
                                                            fieldKey={section}
                                                            callback={callback}
                                                         />
                                                      </RowWrapper>
                                                   )}
                                                   {section ===
                                                      "dropshadow" && (
                                                      <RowWrapper
                                                         label={section}
                                                      >
                                                         <DropShadow
                                                            init={
                                                               data[block][
                                                                  section
                                                               ]
                                                            }
                                                            parentKey={block}
                                                            fieldKey={section}
                                                            callback={callback}
                                                         />
                                                      </RowWrapper>
                                                   )}
                                                   {section !== "background" &&
                                                      section !== "border" &&
                                                      section !==
                                                         "dropshadow" &&
                                                      section !==
                                                         "leaderboard" && (
                                                         <ObjectEdit
                                                            fullWidth
                                                            layoutId={layoutId}
                                                            fonts={fonts}
                                                            data={
                                                               data[block][
                                                                  section
                                                               ]
                                                            }
                                                            activePanel={id}
                                                            parentKey={section}
                                                            handleCheckboxChange={
                                                               handleCheckboxChange
                                                            }
                                                            handleNumberChange={
                                                               handleNumberChange
                                                            }
                                                            handleNumberSliderChange={
                                                               handleNumberSliderChange
                                                            }
                                                            handleTextChange={
                                                               handleTextChange
                                                            }
                                                            handleSelectChange={
                                                               handleSelectChange
                                                            }
                                                            handleFileChange={
                                                               handleFileChange
                                                            }
                                                            handleObjectChange={
                                                               handleObjectChange
                                                            }
                                                            handleBackgroundChange={
                                                               callback
                                                            }
                                                            handleBorderChange={
                                                               callback
                                                            }
                                                            colorPicker={{
                                                               convertColor2Obj:
                                                                  convertColor2Obj,
                                                               updatePresetColors:
                                                                  updatePresetColors,
                                                               setColor:
                                                                  setColor,
                                                               presetColors:
                                                                  presetColors,
                                                            }}
                                                         />
                                                      )}
                                                </Grid>
                                             </Grid>
                                          </TabPanel>
                                       )
                                    )}
                                 </Grid>
                              </Grid>
                           </ParentTabPanel>
                        ))}
                     </Grid>
                  </Grid>
               </Typography>
            </Box>
         )}
      </>
   );
}
