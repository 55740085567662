import { useState, useContext, useEffect, useRef } from "react";
import { SocketContext } from "../context/socket";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// COMPONENTS
import WheelForm from "../components/WheelForm";
import WheelDataPreview from "../components/WheelDataPreview";
import ScrollingBoxWrapper from "../components/ScrollingBoxWrapper";

// ACCORDION
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// ICONS
import Publish from "@mui/icons-material/Publish";

//MODAL
import Modal from "@mui/material/Modal";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 600,
   bgcolor: "#333",
   border: "2px solid #cccccc",
   boxShadow: 24,
   p: 4,
   overflow: "scroll",
   maxHeight: "80vh",
};

export default function Wheel(props) {
   const socket = useContext(SocketContext);

   const [answer, setAnswer] = useState();
   const [wheels, setWheels] = useState([]);
   const [activeWheel, setActiveWheel] = useState();
   const [styles, setStyles] = useState({});

   const [modalAddOpen, setModalAddOpen] = useState(false);

   const handleAddOpen = () => {
      setModalAddOpen(true);
   };

   const handleAddClose = () => {
      setModalAddOpen(false);
   };

   const refreshWheelStyles = () => {
      socket.emit("refreshWheelsStyles");
   };

   // ACCORDION
   const [expanded, setExpanded] = useState(false);

   const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
   };

   const [expandedCompleted, setExpandedCompleted] = useState(false);

   const handleChangeCompleted = (panel) => (event, isExpanded) => {
      setExpandedCompleted(isExpanded ? panel : false);
   };

   //GRID SIZING
   const headerHeight = useRef();
   const gridHeight = headerHeight.current
      ? props.height -
        parseInt(window.getComputedStyle(headerHeight.current).height)
      : 0;
   const gridRow = gridHeight;

   // MODAL
   const [editWheel, setEditWheel] = useState({});
   const [modalOpen, setModalOpen] = useState(false);
   const handleOpen = (wheel) => {
      setEditWheel(wheel);
      setModalOpen(true);
   };
   const handleClose = () => {
      setModalOpen(false);
   };

   useEffect(() => {
      socket.emit("getWheels", (response) => {
         setWheels(response.wheels);
         setStyles(response.styles);
         setActiveWheel(response.activeWheel);
      });
   }, []);

   useEffect(() => {
      socket.on("refreshWheels", () => {
         socket.emit("getWheels", (response) => {
            setWheels(response.wheels);
            setStyles(response.styles);
            setActiveWheel(response.activeWheel);
            setAnswer();
         });
      });

      socket.on("spin", (answer) => {
         setAnswer(answer);
      });

      socket.on("reset", (answer) => {
         setAnswer();
      });
   }, [socket]);

   const handleSpinWheel = () => {
      socket.emit("spinWheel", Object.keys(activeWheel.answers).length);
   };

   const handleResetWheel = () => {
      socket.emit("resetWheel");
   };

   const handleStageWheel = (wheel) => {
      socket.emit("stageWheel", wheel);
   };

   const handleUpdateWheel = (wheel) => {
      socket.emit("updateWheel", wheel);
   };

   const handleLoadWheel = (wheel) => {
      socket.emit("resetWheel");
      socket.emit("loadWheel", wheel);
   };

   const handleDeleteWheel = (id) => {
      socket.emit("deleteWheel", id);
   };

   return (
      <>
         <Box
            sx={{
               boxSizing: "border-box",
               height: "100%",
               width: "100%",
               position: "relative",
            }}
         >
            {/* PARENT GRID */}
            <Grid
               container
               sx={{ height: "100%" }}
               direction='row'
               justifyContent='center'
               alignItems='stretch'
               columnSpacing={2}
               rowSpacing={2}
               className='parent'
            >
               <Grid item xs={12} ref={headerHeight}>
                  <Grid
                     container
                     direction='row'
                     justifyContent='center'
                     alignItems='center'
                  >
                     {/* HEADER */}
                     <Grid item xs={12}>
                        <Grid
                           container
                           columnSpacing={2}
                           rowSpacing={1}
                           justifyContent='right'
                           alignItems='flex-start'
                        >
                           <Grid item>
                              <Button
                                 variant='outlined'
                                 href='/editor/spinthewheel'
                                 size='small'
                                 sx={{ ml: 3, float: "right" }}
                              >
                                 EDIT WHEEL LAYOUTS
                              </Button>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>

               {/* STAGED */}
               <Grid item xs={6} sx={{ height: gridRow }}>
                  <ScrollingBoxWrapper
                     title='Staged Wheels'
                     parentHeight={gridRow}
                     border='2px solid #333'
                     buttons={
                        <>
                           <Button
                              color='info'
                              onClick={() => handleAddOpen()}
                              size='small'
                              sx={{ ml: 1 }}
                           >
                              + ADD WHEEL
                           </Button>
                        </>
                     }
                  >
                     {wheels.length > 0 &&
                        wheels.map((wheel, p) => (
                           <Accordion
                              expanded={expanded === "accordion" + p}
                              onChange={handleChange("accordion" + p)}
                           >
                              <AccordionSummary
                                 expandIcon={<ExpandMoreIcon />}
                                 aria-controls='panel1bh-content'
                                 id='panel1bh-header'
                              >
                                 <Grid
                                    container
                                    direction='row'
                                    justifyContent='center'
                                    alignItems='center'
                                    spacing={2}
                                 >
                                    <Grid item xs={8}>
                                       {wheel.title}
                                    </Grid>
                                    <Grid
                                       item
                                       xs={4}
                                       sx={{
                                          textAlign: "right",
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       <Button
                                          variant='outlined'
                                          size='small'
                                          sx={{ mr: 2 }}
                                          color='info'
                                          onClick={(e) => {
                                             e.stopPropagation();
                                             handleLoadWheel(wheel);
                                          }}
                                          startIcon={<Publish />}
                                       >
                                          Load
                                       </Button>
                                    </Grid>
                                 </Grid>
                              </AccordionSummary>
                              <AccordionDetails sx={{ position: "relative" }}>
                                 <WheelForm
                                    key={"wheel" + wheel.id}
                                    wheel={wheel}
                                    handleOpen={handleOpen}
                                    updateWheel={handleUpdateWheel}
                                    deleteWheel={handleDeleteWheel}
                                    loadWheel={handleLoadWheel}
                                    edit={true}
                                    layouts={styles}
                                 />
                              </AccordionDetails>
                           </Accordion>
                        ))}
                  </ScrollingBoxWrapper>
               </Grid>

               {/* ACTIVE */}
               <Grid item xs={6} sx={{ height: gridRow }}>
                  <ScrollingBoxWrapper
                     title='Active Wheel'
                     parentHeight={gridRow}
                     border='2px solid #333'
                  >
                     {activeWheel && Object.entries(activeWheel).length > 0 && (
                        <Box
                           sx={{
                              px: 3,
                              py: 2,
                              mb: 2,
                              position: "relative",
                           }}
                        >
                           {activeWheel.title && (
                              <>
                                 <b>Title:</b>
                                 <br />
                                 {activeWheel.title}
                                 <br />
                                 <br />
                                 Layout:{" "}
                                 {styles[activeWheel.layout] &&
                                    styles[activeWheel.layout].global.parameters
                                       .layoutTitle}
                                 <br />
                              </>
                           )}
                           <br />

                           {typeof answer === "undefined" && (
                              <Button
                                 variant='contained'
                                 size='small'
                                 color='success'
                                 onClick={handleSpinWheel}
                                 sx={{ mb: 3 }}
                              >
                                 Spin the Wheel
                              </Button>
                           )}

                           {answer >= 0 && (
                              <Button
                                 variant='contained'
                                 size='small'
                                 color='error'
                                 onClick={handleResetWheel}
                                 sx={{ mb: 3 }}
                              >
                                 Reset
                              </Button>
                           )}
                           <br />

                           <b>Answers</b>
                           <ol>
                              {Object.entries(activeWheel.answers).map(
                                 (answerRow, i) => (
                                    <li
                                       key={
                                          "active-" + activeWheel.id + answerRow
                                       }
                                       className={i === answer ? "result" : ""}
                                    >
                                       {answerRow[1]}
                                    </li>
                                 )
                              )}
                           </ol>
                        </Box>
                     )}
                  </ScrollingBoxWrapper>
               </Grid>
            </Grid>
         </Box>

         {/* MODAL - EDIT */}
         <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Edit Wheel
               </Typography>
               <Typography id='modal-description' sx={{ mt: 2 }}>
                  <WheelDataPreview
                     wheel={editWheel}
                     updateWheel={handleUpdateWheel}
                     edit={true}
                     closeModal={handleClose}
                     layouts={styles}
                  />
               </Typography>
            </Box>
         </Modal>

         {/* MODAL - ADD */}
         <Modal
            open={modalAddOpen}
            onClose={handleAddClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
         >
            <Box sx={modalStyle}>
               <Typography
                  id='modal-title'
                  variant='h6'
                  component='h2'
                  sx={{ textTransform: "uppercase" }}
               >
                  Add New Wheel
               </Typography>
               <Typography id='modal-description' sx={{ mt: 2 }}>
                  <WheelDataPreview
                     stageWheel={handleStageWheel}
                     layouts={styles}
                     closeModal={handleAddClose}
                  />
               </Typography>
            </Box>
         </Modal>

         <Button
            sx={{ mt: 3 }}
            variant='outlined'
            color='error'
            onClick={refreshWheelStyles}
         >
            Reload Wheel Styles from DB
         </Button>
      </>
   );
}
