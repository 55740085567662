import { useState, useEffect } from "react";
import _ from "lodash";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function TugofWar(props) {
   const name = "tugofwar";
   const listKeys = ["A", "B", "C", "D", "E", "F", "G", "H"];

   const [id, setId] = useState();
   const [numOptions, setNumOptions] = useState(2);
   const [title, setTitle] = useState("");
   const [answers, setAnswers] = useState({});
   const [layout, setLayout] = useState("");

   useEffect(() => {
      if (props.edit) {
         setId(props.poll.id);
         setTitle(props.poll.title);
         setAnswers(props.poll.answers);
         setNumOptions(_.size(props.poll.answers));
         setLayout(props.poll.layout);
      }
   }, [props.edit]);

   const updateTitle = (e) => {
      setTitle(e.target.value);
   };

   const updateAnswers = (e) => {
      setAnswers((prevState) => {
         let newState = { ...prevState };

         let name = e.target.getAttribute("name");
         let value = e.target.value;

         if (value) {
            newState[name] = value;
         } else {
            delete newState[name];
         }

         return newState;
      });
   };

   const handleLayoutChange = (event) => {
      setLayout(event.target.value);
   };

   return (
      <>
         <TextField
            key={`inputTitle`}
            label='Title'
            name='title'
            value={title}
            id='filled-size-small'
            variant='outlined'
            size='small'
            onChange={updateTitle}
            sx={{ mb: 1.5 }}
            fullWidth
         />

         <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id={"addTugofWarLayouts"}>Layout</InputLabel>
            <Select
               labelId={"layoutMenu"}
               value={layout}
               label='Layout'
               onChange={handleLayoutChange}
            >
               {props.layouts &&
                  props.layouts.map((layout, i) => (
                     <MenuItem key={"polllayout" + i} value={layout.id}>
                        {layout.title}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>

         {[...Array(numOptions)].map((value, index) => (
            <TextField
               key={`inputKey${index}`}
               label={listKeys[index]}
               name={listKeys[index]}
               id='filled-size-small'
               value={answers[listKeys[index]] ? answers[listKeys[index]] : ""}
               variant='outlined'
               size='small'
               onChange={updateAnswers}
               sx={{ mb: 1.5 }}
               fullWidth
            />
         ))}

         {!props.edit && (
            <Button
               variant='contained'
               disabled={
                  title && layout && Object.keys(answers).length === numOptions
                     ? false
                     : true
               }
               onClick={() => {
                  props.stagePoll({
                     id: id,
                     type: name,
                     title: title,
                     answers: answers,
                     layout: layout,
                  });
                  setNumOptions(2);
                  setTitle("");
                  setAnswers({});
               }}
            >
               Stage Poll
               {props.edit && <>Save Poll</>}
            </Button>
         )}

         {props.edit && (
            <Button
               variant='contained'
               disabled={
                  title && layout && Object.keys(answers).length === numOptions
                     ? false
                     : true
               }
               onClick={() => {
                  props.updatePoll({
                     id: id,
                     type: name,
                     title: title,
                     answers: answers,
                     layout: layout,
                  });
                  setNumOptions(2);
                  setTitle("");
                  setAnswers({});
                  props.closeModal({});
               }}
            >
               Update Poll
            </Button>
         )}
      </>
   );
}

export default TugofWar;
