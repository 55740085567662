import * as React from "react";
import Grid from "@mui/material/Grid";

// LANGUAGE FOR LABELS
import { language } from "./Language";

export default function RowWrapper({ children, label, type }) {
   return (
      <>
         {type !== "checkbox" && (
            <Grid
               item
               xs={12}
               sx={{
                  fontWeight: "bold",
                  textTransform: "uppercase",
                  fontSize: 11,
                  color: "#fff",
                  backgroundColor: "#666",
                  border: "1px solid #ccc",
                  px: 2,
                  py: 0.5,
               }}
            >
               {language[label]}
            </Grid>
         )}

         <Grid
            item
            xs={12}
            sx={{
               border: "1px solid #ccc",
               py: 1,
               px: 3,
               textAlign: "left",
               mb: 1,
               backgroundColor: "#fff",
            }}
         >
            {type === "checkbox" && <>{language[label]}</>}
            {children}
         </Grid>
      </>
   );
}
