import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

// COMPONENT
export default function TextScroller(props) {
   const textRef = useRef(null);
   const parentRef = useRef(null);

   const [scaleX, setScaleX] = useState(1);

   const [parentWidth, setParentWidth] = useState();
   const [width, setWidth] = useState();
   const [height, setHeight] = useState();

   const [opacity, setOpacity] = useState(0);

   useEffect(() => {
      setTimeout(function () {
         setParentWidth(parentRef.current.clientWidth);
         setHeight(textRef.current.clientHeight);
         setWidth(textRef.current.clientWidth);

         setOpacity(1);
      }, 500);
   }, []);

   useEffect(() => {
      setScaleX(width > parentWidth ? parentWidth / width : 1);
   }, [width, parentWidth]);

   return (
      <div
         ref={parentRef}
         style={{
            WebkitMaskImage:
               "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,1) 95%, rgba(0,0,0,0) 100%)",
            maskImage:
               "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 5%, rgba(0,0,0,1) 95%, rgba(0,0,0,0) 100%)",
            maskSize: "cover",
            position: "relative",
            width: parentWidth,
            height: height,
            top: 0,
            left: 0,
            padding: 0,
            margin: 0,
         }}
      >
         <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
         >
            <motion.div
               ref={textRef}
               initial={{ x: 0, y: 0, opacity: 1 }}
               animate={{
                  x: width > parentWidth ? -(width + 20) : 0,
               }}
               transition={{
                  ease: "easeInOut",
                  duration: width > parentWidth ? 8 : 0,
                  repeat: width > parentWidth ? Infinity : 0,
                  delay: 0,
               }}
               style={{
                  minWidth: "100%",
                  position: "absolute",
                  whiteSpace: "nowrap",
               }}
            >
               <span style={{ display: "inline-block", width: 30 }} />
               {props.text}{" "}
               <span style={{ display: "inline-block", width: 30 }} />
               {width > parentWidth && (
                  <div
                     style={{
                        position: "absolute",
                        top: 0,
                        left: width + 20,
                     }}
                  >
                     <span style={{ display: "inline-block", width: 30 }} />
                     {props.text}{" "}
                     <span style={{ display: "inline-block", width: 30 }} />
                  </div>
               )}
            </motion.div>

            {props.dropshadow && props.dropshadow.enable && (
               <motion.div
                  ref={textRef}
                  initial={{ x: 0, y: 0, opacity: 1 }}
                  animate={{
                     x: width > parentWidth ? -(width + 20) : 0,
                  }}
                  transition={{
                     ease: "easeInOut",
                     duration: width > parentWidth ? 8 : 0,
                     repeat: width > parentWidth ? Infinity : 0,
                     delay: 0,
                  }}
                  style={{
                     minWidth: "100%",
                     position: "absolute",
                     whiteSpace: "nowrap",
                     top: props.dropshadow.yPos,
                     left: props.dropshadow.xPos,
                     color: props.dropshadow.color,

                     WebkitTextStroke:
                        props.dropshadow.borderWidth +
                        "px " +
                        props.dropshadow.borderColor,
                     zIndex: -1,
                  }}
               >
                  <span style={{ display: "inline-block", width: 30 }} />
                  {props.text}{" "}
                  <span style={{ display: "inline-block", width: 30 }} />
                  {width > parentWidth && (
                     <div
                        style={{
                           position: "absolute",
                           top: 0,
                           left: width + 20,
                        }}
                     >
                        <span style={{ display: "inline-block", width: 30 }} />
                        {props.text}{" "}
                        <span style={{ display: "inline-block", width: 30 }} />
                     </div>
                  )}
               </motion.div>
            )}
         </motion.div>
      </div>
   );
}
