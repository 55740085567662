import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import LayoutTemplateEditor from "../layouts/LayoutTemplateEditor";

import EditorDataForm from "../editor/EditorPollDataForm";

// MODAL
import Modal from "@mui/material/Modal";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 600,
   bgcolor: "#efefef",
   border: "2px solid #cccccc",
   boxShadow: 24,
   p: 4,
};

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function StyleEditor(props) {
   // MODAL
   const [activePoll, setActivePoll] = useState({
      title: "Sample Poll Title",
      duration: 30,
      answers: {
         A: "This Option Is Long",
         B: "Dinosaur",
         C: "Bat",
         D: "Thing",
         E: "Giraffe",
         F: "Goat",
         G: "Horse",
         H: "Mouse",
         I: "Dog",
         J: "Anteater",
      },
      results: {
         A: {
            answer: "This Option Is Really Really Long",
            qty: 10,
         },
         B: {
            answer: "Dinosaur",
            qty: 5,
         },
         C: {
            answer: "Bat",
            qty: 2,
         },
         D: {
            answer: "Thing",
            qty: 0,
         },
         E: {
            answer: "Giraffe",
            qty: 0,
         },
         F: {
            answer: "Goat",
            qty: 0,
         },
         G: {
            answer: "Horse",
            qty: 0,
         },
         H: {
            answer: "Mouse",
            qty: 0,
         },
         I: {
            answer: "Dog",
            qty: 0,
         },
         J: {
            answer: "Anteater",
            qty: 0,
         },
      },
      totalResults: 10,
      lastRun: 1656377605851,
      layout: "sample",
   });

   const [modalOpen, setModalOpen] = useState(false);

   const handleOpen = (poll) => {
      setModalOpen(true);
   };
   const handleClose = () => {
      setModalOpen(false);
   };

   const handleUpdatePoll = (poll) => {
      setActivePoll(poll);
   };

   // SOCKET
   const socket = useContext(SocketContext);

   // STYLES
   const [styles, setStyles] = useState();
   const [fonts, setFonts] = useState();
   const [selectedLayout, setSelectedLayout] = useState();

   const [polls, setPolls] = useState([]);

   useEffect(() => {
      // INITIAL LOAD
      socket.emit("getData", (response) => {
         setPolls(
            response.polls.filter((poll) => {
               return poll.type === "list";
            })
         );
      });

      socket.emit("getStyles", (response) => {
         setStyles(response.styles);
         setFonts(response.fonts);
      });
   }, []);

   useEffect(() => {
      socket.on("refreshStyles", () => {
         socket.emit("getData", (response) => {
            socket.emit("getStyles", (styleResponse) => {
               setStyles(styleResponse.styles);
               setFonts(styleResponse.fonts);
            });
         });
      });
   }, [socket]);

   const handleUpdateDB = (data) => {
      if (data.action == "insert") {
         data.layout.global.parameters.layoutTitle =
            "Copy of " + data.layout.global.parameters.layoutTitle;
      }

      socket.emit("updateLayout", data, (response) => {
         setSelectedLayout(response.id);
      });
   };

   useEffect(() => {
      if (fonts) {
         fonts.map((font) => {
            var fontFace = new FontFace(
               font.split(".")[0],
               "url(" + SERVER_URL + "/fonts/" + font + ")"
            );

            fontFace
               .load()
               .then(function (loaded_face) {
                  document.fonts.add(loaded_face);
                  document.body.style.fontFamily =
                     '"' + font.split(".")[0] + '", Arial';
               })
               .catch(function (error) {
                  console.log(error);
               });
         });
      }
   }, [fonts]);

   return (
      <>
         {styles && (
            <LayoutTemplateEditor
               key={selectedLayout}
               activePoll={activePoll}
               fonts={fonts}
               styles={styles}
               selectedLayout={selectedLayout}
               setSelectedLayout={setSelectedLayout}
               updateDB={handleUpdateDB}
               openDataEditor={handleOpen}
            />
         )}

         {/* MODAL - EDIT PREVIEW DATA */}
         <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Edit Preview Data
               </Typography>
               <Typography id='modal-description' sx={{ mt: 2 }}>
                  <EditorDataForm
                     poll={activePoll}
                     stagedPolls={polls}
                     updatePoll={handleUpdatePoll}
                     edit={true}
                     closeModal={handleClose}
                  />
               </Typography>
            </Box>
         </Modal>
      </>
   );
}
