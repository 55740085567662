import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useParams } from "react-router-dom";

import ViewerWrapper from "./ViewerWrapper";
import ViewerComponent from "./ViewerComponentLeaderboard";

//HOOKS
import useGlobal from "../hooks/useGlobal";

export default function Viewer(props) {
   // USE GLOBAL HOOK
   const [fonts, activeLayout, state, updateState, { subscribeLeaderboard }] =
      useGlobal();

   useEffect(() => {
      subscribeLeaderboard();
   }, []);

   useEffect(() => {
      {
         /* console.log(state); */
      }
   }, [state]);

   return (
      <>
         {activeLayout && (
            <ViewerWrapper key='leaderboardViewer' activeLayout={activeLayout}>
               <ViewerComponent
                  key={"viewerLeaderboard"}
                  edit={false}
                  activeLayout={activeLayout}
                  activeLeaderboard={state.data}
               />
            </ViewerWrapper>
         )}
      </>
   );
}
