import { useState, useEffect } from "react";
import { motion, useTransform, useSpring } from "framer-motion";

import _ from "lodash";

import Box from "@mui/material/Box";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function TugofWarGraphBar({
   activeLayout,
   activeResults = {
      A: {
         answer: "1906",
         qty: 0,
      },
      B: {
         answer: "74",
         qty: 0,
      },
   },
   reduction,
}) {
   const [widthInput, setWidthInput] = useState(0);
   const [heightInput, setHeightInput] = useState(0);
   const colorOutput = ["#596063", "#8d2618", "#0f39c3", "#0da72e"];

   const width = useSpring(widthInput, { damping: 10, stiffness: 28 });
   const widthRange = [0, 10, 50, 100];

   const height = useSpring(heightInput, { damping: 10, stiffness: 28 });
   const heightRange = [0, 10, 50, 100];

   const updateGraph = _.debounce(function () {
      if (activeResults.pct) {
         setWidthInput(activeResults.pct);
         width.set(activeResults.pct);

         setHeightInput(activeResults.pct);
         height.set(activeResults.pct);
      } else {
         setWidthInput(0);
         width.set(0);

         setHeightInput(0);
         height.set(0);
      }
   }, 1000);

   function scale(value, inMin, inMax, outMin, outMax) {
      const result =
         ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

      if (result < outMin) {
         return outMin;
      } else if (result > outMax) {
         return outMax;
      }

      return result;
   }

   const calcPct = (results, total) => {
      let adjustment = 1 - (total < 100 ? 100 - total : 0) / 100;

      if (results.A.qty > results.B.qty) {
         return (
            -scale(Math.floor((results.A.qty / total) * 100), 50, 100, 0, 100) *
            adjustment
         );
      } else if (results.B.qty > results.A.qty) {
         return (
            scale(Math.floor((results.B.qty / total) * 100), 50, 100, 0, 100) *
            adjustment
         );
      } else {
         return 0;
      }
   };

   const calcReducedPct = (results, reduction, total) => {
      let adjustment = 1 + parseFloat(reduction);
      if (results.A.qty > results.B.qty) {
         return -scale(
            Math.floor((results.A.qty / total) * 100) * adjustment,
            0,
            95,
            0,
            95
         );
      } else if (results.B.qty > results.A.qty) {
         return scale(
            Math.floor((results.B.qty / total) * 100) * adjustment,
            0,
            95,
            0,
            95
         );
      } else {
         return 0;
      }
   };

   const total = activeResults.A.qty + activeResults.B.qty;

   const pct = calcReducedPct(activeResults, reduction, total);

   useEffect(() => {
      updateGraph();
   }, [activeResults]);

   return (
      <Box
         sx={{
            width: "100%",
            height: "100%",
            marginTop:
               activeLayout.container.style.tugofwar.background.yPos + "px",
            position: "relative",
         }}
      >
         {activeLayout.container.style.tugofwar.icon.image != "none" && (
            <Box
               sx={{
                  position: "absolute",
                  top: "50%",
                  left: 0,
                  width: "100%",
                  zIndex: 20,
               }}
            >
               <motion.div
                  animate={{ left: pct / 2.3 + "%" }}
                  transition={{
                     type: "spring",
                     mass: 15,
                     damping: 200,
                     stiffness: 150,
                  }}
                  style={{
                     position: "relative",
                     height: "auto",
                  }}
               >
                  <div
                     style={{
                        position: "relative",
                        height: "auto",
                        width: "auto",
                        textAlign: "center",
                     }}
                  >
                     <img
                        src={`${SERVER_URL}/uploads/${activeLayout.container.style.tugofwar.icon.image}`}
                        width='auto'
                        style={{
                           position: "relative",
                           top: "50%",
                           marginTop:
                              activeLayout.container.style.tugofwar.icon.yPos,
                           marginLeft:
                              activeLayout.container.style.tugofwar.icon.xPos,
                           transform:
                              "translateY(-50%) scale(" +
                              activeLayout.container.style.tugofwar.icon.scale +
                              ")",
                        }}
                     />
                  </div>
               </motion.div>
            </Box>
         )}

         {activeLayout.container.style.tugofwar.overlay.image != "none" && (
            <Box
               sx={{
                  top: activeLayout.container.style.tugofwar.overlay.yPos,
                  left: activeLayout.container.style.tugofwar.overlay.xPos,
                  position: "absolute",
                  top: "50%",
                  left: 0,
                  width: "100%",
                  zIndex: 10,
               }}
            >
               <img
                  src={`${SERVER_URL}/uploads/${activeLayout.container.style.tugofwar.overlay.image}`}
                  width='100%'
                  style={{
                     position: "relative",
                     top: "50%",
                     marginTop:
                        activeLayout.container.style.tugofwar.overlay.yPos,
                     marginLeft:
                        activeLayout.container.style.tugofwar.overlay.xPos,
                     transform: "translateY(-50%)",
                  }}
               />
            </Box>
         )}

         {activeLayout.container.style.tugofwar.background.image != "none" && (
            <Box
               sx={{
                  position: "relative",
                  top: "50%",
                  left: 0,
                  width: "100%",
                  zIndex: 1,
               }}
            >
               <img
                  src={`${SERVER_URL}/uploads/${activeLayout.container.style.tugofwar.background.image}`}
                  width='100%'
                  style={{
                     position: "relative",
                     top: "50%",
                     transform: "translateY(-50%)",
                  }}
               />
            </Box>
         )}
      </Box>
   );
}

export default TugofWarGraphBar;
