import { useState, useEffect } from "react";
import _ from "lodash";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// COMPONENTS
import EmotesComponent from "../components/EmotesComponent.js";

//MODAL
import Modal from "@mui/material/Modal";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   height: "80vh",
   width: "80vw",
   color: "#000",
   bgcolor: "#ffffff",
   border: "2px solid #cccccc",
   boxShadow: 24,
   p: 4,
   overflow: "scroll",
};

function TugofWarEmote(props) {
   const name = "tugofwar";
   const listKeys = ["A", "B", "C", "D", "E", "F", "G", "H"];

   const [id, setId] = useState();
   const [numOptions, setNumOptions] = useState(2);
   const [title, setTitle] = useState("");
   const [answers, setAnswers] = useState({});
   const [layout, setLayout] = useState("");

   // MODAL
   const [modalOpen, setModalOpen] = useState(false);
   const [modalField, setModalField] = useState();

   const handleOpen = (name) => {
      setModalField(name);
      setModalOpen(true);
   };
   const handleClose = () => {
      setModalOpen(false);
   };

   useEffect(() => {
      if (props.edit) {
         setId(props.poll.id);
         setTitle(props.poll.title);
         setAnswers(props.poll.answers);
         setNumOptions(_.size(props.poll.answers));
         setLayout(props.poll.layout);
      }
   }, [props.edit]);

   const updateTitle = (e) => {
      setTitle(e.target.value);
   };

   const setEmoteAnswer = (name, value) => {
      setAnswers((prevState) => {
         let newState = { ...prevState };

         if (value) {
            newState[name] = value;
         } else {
            delete newState[name];
         }

         return newState;
      });
   };

   const handleLayoutChange = (event) => {
      setLayout(event.target.value);
   };

   return (
      <>
         <TextField
            key={`inputTitle`}
            label='Title'
            name='title'
            value={title}
            id='filled-size-small'
            variant='outlined'
            size='small'
            onChange={updateTitle}
            sx={{ mb: 1.5 }}
            fullWidth
         />

         <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id={"addTugofWarLayouts"}>Layout</InputLabel>
            <Select
               labelId={"layoutMenu"}
               value={layout}
               label='Layout'
               onChange={handleLayoutChange}
            >
               {props.layouts &&
                  props.layouts.map((layout, i) => (
                     <MenuItem key={"polllayout" + i} value={layout.id}>
                        {layout.title}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>

         <Grid container sx={{ my: 2 }}>
            {[...Array(numOptions)].map((value, index) => (
               <Grid item xs={12} sx={{ my: 1 }} key={"tugofwarOption" + index}>
                  <Grid container justifyContent='center' alignItems='center'>
                     <Grid item xs={2} sx={{ textAlign: "center" }}>
                        {listKeys[index]}
                     </Grid>
                     <Grid item xs={4} sx={{ textAlign: "center" }}>
                        {answers[listKeys[index]] && (
                           <img
                              src={
                                 "https://static-cdn.jtvnw.net/emoticons/v2/" +
                                 answers[listKeys[index]] +
                                 "/static/light/2.0"
                              }
                           />
                        )}
                     </Grid>
                     <Grid item xs={6}>
                        {answers[listKeys[index]] && (
                           <Button
                              onClick={() => handleOpen(listKeys[index])}
                              sx={{ my: 1 }}
                              fullWidth
                              variant='outlined'
                           >
                              Change Emote
                           </Button>
                        )}
                        {!answers[listKeys[index]] && (
                           <Button
                              onClick={() => handleOpen(listKeys[index])}
                              sx={{ my: 1 }}
                              fullWidth
                              variant='outlined'
                           >
                              Select Emote
                           </Button>
                        )}
                     </Grid>
                  </Grid>
               </Grid>
            ))}
         </Grid>

         {!props.edit && (
            <Button
               variant='contained'
               disabled={
                  title && layout && Object.keys(answers).length === numOptions
                     ? false
                     : true
               }
               onClick={() => {
                  props.stagePoll({
                     id: id,
                     type: name,
                     title: title,
                     answers: answers,
                     layout: layout,
                     emote: true,
                  });
                  setNumOptions(2);
                  setTitle("");
                  setAnswers({});
               }}
            >
               Stage Poll
               {props.edit && <>Save Poll</>}
            </Button>
         )}

         {props.edit && (
            <Button
               variant='contained'
               disabled={
                  title && layout && Object.keys(answers).length === numOptions
                     ? false
                     : true
               }
               onClick={() => {
                  props.updatePoll({
                     id: id,
                     type: name,
                     title: title,
                     answers: answers,
                     layout: layout,
                     emote: true,
                  });
                  setNumOptions(2);
                  setTitle("");
                  setAnswers({});
                  props.closeModal({});
               }}
            >
               Update Poll
            </Button>
         )}

         <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Select Emote (Option {modalField})
               </Typography>
               <Typography id='modal-description' sx={{ mt: 2 }}>
                  <EmotesComponent
                     name={modalField}
                     closeModal={handleClose}
                     callback={setEmoteAnswer}
                  />
               </Typography>
            </Box>
         </Modal>
      </>
   );
}

export default TugofWarEmote;
