import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useParams } from "react-router-dom";

import ViewerWrapper from "./ViewerWrapper";
import ViewerComponent from "./ViewerComponentTugofWar";

//HOOKS
import useGlobal from "../hooks/useGlobal";

export default function Viewer(props) {
   // USE GLOBAL HOOK
   const [
      fonts,
      activeLayout,
      state,
      updateState,
      { subscribePolls, subscribeTugofWar },
   ] = useGlobal();

   useEffect(() => {
      subscribeTugofWar();
   }, []);

   useEffect(() => {
      // console.log(state);
   }, [state]);

   return (
      <>
         {activeLayout && (
            <ViewerWrapper key='pollViewer' activeLayout={activeLayout}>
               <ViewerComponent
                  key={"viewerTugofWar"}
                  edit={false}
                  activeLayout={activeLayout}
                  activePoll={state.data}
                  activeResults={state.activeResults}
               />
            </ViewerWrapper>
         )}
      </>
   );
}
