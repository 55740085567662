import { useEffect } from "react";
import { motion, AnimatePresence, layout } from "framer-motion";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import RndContainer from "../editor/RndContainer";
import TextScroller from "./TextScroller";
import TextSquisher from "./TextSquisher";
import GraphBar from "./GraphBar";
import Container from "../editor/Container";

import Countdown, { zeroPad } from "react-countdown";

import LeaderboardComponent from "./LeaderboardComponent";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ViewerComponent({
   edit,
   activeLayout,
   activeLeaderboard,
   previewScale,
   handleModal,
   handleUpdate,
   handleAdjust,
   activeResultTotal,
   activeResults,
}) {
   return (
      <>
         {activeLayout && (
            <>
               {/* LOGO */}
               <Container
                  previewScale={previewScale}
                  handleModal={handleModal}
                  handleUpdate={handleUpdate}
                  handleAdjust={handleAdjust}
                  edit={edit}
                  key='logo'
                  name='logo'
                  data={activeLayout.logo}
                  zIndex={200}
               >
                  <Grid
                     container
                     alignItems='center'
                     justifyContent='center'
                     className='logo'
                     sx={{
                        width: "100%",
                        height: "100%",
                        opacity: activeLayout.logo.parameters.visible ? 1 : 0,
                        backgroundColor: activeLayout.logo.background.color,
                        px: activeLayout.logo.style.px,
                        py: activeLayout.logo.style.py,
                        boxSizing: "border-box",
                        borderTopWidth: activeLayout.logo.border.top,
                        borderLeftWidth: activeLayout.logo.border.left,
                        borderRightWidth: activeLayout.logo.border.right,
                        borderBottomWidth: activeLayout.logo.border.bottom,
                        borderTopColor: activeLayout.logo.border.color,
                        borderLeftColor: activeLayout.logo.border.color,
                        borderRightColor: activeLayout.logo.border.color,
                        borderBottomColor: activeLayout.logo.border.color,
                        borderStyle: activeLayout.logo.border.style,
                        borderRadius: activeLayout.logo.border.radius,
                     }}
                  >
                     <Grid
                        item
                        sx={{
                           position: "relative",
                           width: "100%",
                           height: "100%",
                        }}
                     >
                        {activeLayout.logo.style.image !== "none" &&
                           activeLayout.logo.parameters.visible && (
                              <img
                                 src={`${SERVER_URL}/uploads/${activeLayout.logo.style.image}`}
                                 alt='Logo'
                                 style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    maxWidth: "100%",
                                    transform:
                                       "translateY(-50%) translateX(-50%)",
                                    zIndex: 5,
                                 }}
                              />
                           )}
                        {activeLayout.logo.background.image !== "none" &&
                           activeLayout.logo.parameters.visible && (
                              <motion.div
                                 initial={{ opacity: 0 }}
                                 animate={{ opacity: 1 }}
                              >
                                 <img
                                    src={`${SERVER_URL}/uploads/${activeLayout.logo.background.image}`}
                                    style={{
                                       width: "100%",
                                       position: "absolute",
                                       top: "50%",
                                       left: "50%",
                                       zIndex: 1,
                                       transform:
                                          "translateX(-50%) translateY(-50%)",
                                    }}
                                 />
                              </motion.div>
                           )}
                     </Grid>
                  </Grid>
               </Container>

               {/* FRAME */}
               <Box
                  className='frame'
                  sx={{
                     position: "absolute",
                     boxSizing: "border-box",
                     width: activeLayout.frame.position.width,
                     height: activeLayout.frame.position.height,
                     borderTopWidth: activeLayout.frame.border.top,
                     borderLeftWidth: activeLayout.frame.border.left,
                     borderRightWidth: activeLayout.frame.border.right,
                     borderBottomWidth: activeLayout.frame.border.bottom,
                     borderTopColor: activeLayout.frame.border.color,
                     borderLeftColor: activeLayout.frame.border.color,
                     borderRightColor: activeLayout.frame.border.color,
                     borderBottomColor: activeLayout.frame.border.color,
                     borderStyle: activeLayout.frame.border.style,
                     borderRadius: activeLayout.frame.border.radius,
                     backgroundColor: activeLayout.frame.background.color,
                     backgroundImage:
                        activeLayout.frame.background.image != "none" &&
                        `url(${SERVER_URL}/uploads/${activeLayout.frame.background.image})`,
                     backgroundPosition: `${activeLayout.frame.background.topBottom} ${activeLayout.frame.background.leftRight}`,
                     backgroundRepeat: activeLayout.frame.background.repeat,
                     backgroundSize: activeLayout.frame.background.size
                        ? activeLayout.frame.background.size
                        : "auto",
                     [activeLayout.frame.position.xAlign]:
                        activeLayout.frame.position.xPos,
                     [activeLayout.frame.position.yAlign]:
                        activeLayout.frame.position.yPos,
                     fontFamily: activeLayout.global.style.fontFamily
                        ? activeLayout.global.style.fontFamily.split(".")[0]
                        : "unset",
                     fontSize: activeLayout.global.style.fontSize,
                     fontWeight: activeLayout.global.style.fontWeight,
                  }}
               >
                  <Box
                     sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: -activeLayout.frame.border.top,
                        left: -activeLayout.frame.border.left,
                     }}
                  >
                     {/* TITLE */}
                     <Container
                        previewScale={previewScale}
                        handleModal={handleModal}
                        handleUpdate={handleUpdate}
                        handleAdjust={handleAdjust}
                        edit={edit}
                        key='header'
                        name='header'
                        data={activeLayout.header}
                        zIndex={150}
                     >
                        {activeLayout.header.parameters.visible && (
                           <Box
                              sx={{
                                 position: "relative",
                                 display: "block",
                                 boxSizing: "border-box",
                                 height: "100%",
                                 width: "100%",
                                 textAlign: activeLayout.header.style.textAlign,
                                 fontFamily: activeLayout.header.style
                                    .fontFamily
                                    ? activeLayout.header.style.fontFamily.split(
                                         "."
                                      )[0]
                                    : "unset",
                                 fontSize: activeLayout.header.style.fontSize,
                                 fontWeight:
                                    activeLayout.header.style.fontWeight,
                                 maxWidth: activeLayout.header.style.width
                                    ? activeLayout.header.width
                                    : "auto",

                                 backgroundColor:
                                    activeLayout.header.background.color,
                                 backgroundImage:
                                    activeLayout.header.background.image !=
                                       "none" &&
                                    `url(${SERVER_URL}/uploads/${activeLayout.header.background.image})`,
                                 backgroundPosition: `${activeLayout.header.background.topBottom} ${activeLayout.header.background.leftRight}`,
                                 backgroundRepeat:
                                    activeLayout.header.background.repeat,
                                 backgroundSize: activeLayout.header.background
                                    .size
                                    ? activeLayout.header.background.size
                                    : "auto",

                                 borderTopWidth: activeLayout.header.border.top,
                                 borderLeftWidth:
                                    activeLayout.header.border.left,
                                 borderRightWidth:
                                    activeLayout.header.border.right,
                                 borderBottomWidth:
                                    activeLayout.header.border.bottom,
                                 borderTopColor:
                                    activeLayout.header.border.color,
                                 borderLeftColor:
                                    activeLayout.header.border.color,
                                 borderRightColor:
                                    activeLayout.header.border.color,
                                 borderBottomColor:
                                    activeLayout.header.border.color,
                                 borderStyle: activeLayout.header.border.style,
                                 borderRadius:
                                    activeLayout.header.border.radius,
                              }}
                           >
                              <Box
                                 sx={{
                                    width: "100%",
                                    height: "auto",
                                    position: "relative",
                                    display: "block",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    pt: activeLayout.header.style.py,
                                    px: activeLayout.header.style.px,
                                    color: activeLayout.header.style.color,
                                    WebkitTextStroke:
                                       activeLayout.header.style
                                          .textBorderWidth +
                                       "px " +
                                       activeLayout.header.style
                                          .textBorderColor,
                                    boxSizing: "border-box",
                                    zIndex: 2,
                                 }}
                              >
                                 {activeLeaderboard.title &&
                                    activeLayout.header.parameters.visible && (
                                       <>{activeLeaderboard.title}</>
                                    )}
                                 {!activeLeaderboard.title &&
                                    activeLayout.header.parameters.visible && (
                                       <>Leaderboard</>
                                    )}
                                 {!activeLayout.header.parameters.visible && (
                                    <> </>
                                 )}
                              </Box>

                              {/* TITLE DROP SHADOW */}
                              {activeLayout.header.dropshadow.enable && (
                                 <Box
                                    sx={{
                                       width: "100%",
                                       height: "auto",
                                       position: "absolute",
                                       left:
                                          activeLayout.header.dropshadow.xPos +
                                          "px",
                                       top:
                                          "calc(53% + " +
                                          activeLayout.header.dropshadow.yPos +
                                          "px)",
                                       transform: "translateY(-50%)",
                                       pt: activeLayout.header.style.py,
                                       px: activeLayout.header.style.px,
                                       display: "block",
                                       maxWidth: activeLayout.header.style.width
                                          ? activeLayout.header.style.width
                                          : "auto",
                                       color: activeLayout.header.dropshadow
                                          .color,

                                       WebkitTextStroke:
                                          activeLayout.header.dropshadow
                                             .borderWidth +
                                          "px " +
                                          activeLayout.header.dropshadow
                                             .borderColor,
                                       zIndex: 1,
                                       boxSizing: "border-box",
                                    }}
                                 >
                                    {activeLeaderboard.title &&
                                       activeLayout.header.parameters
                                          .visible && (
                                          <>{activeLeaderboard.title}</>
                                       )}
                                    {!activeLeaderboard.title &&
                                       activeLayout.header.parameters
                                          .visible && <>Leaderboard</>}
                                    {!activeLayout.header.parameters
                                       .visible && <> </>}
                                 </Box>
                              )}
                           </Box>
                        )}
                     </Container>

                     {/* CONTAINER */}
                     <Container
                        previewScale={previewScale}
                        handleModal={handleModal}
                        handleUpdate={handleUpdate}
                        handleAdjust={handleAdjust}
                        edit={edit}
                        key='container'
                        name='container'
                        data={activeLayout.container}
                        zIndex={50}
                     >
                        <Box
                           className='container'
                           sx={{
                              position: "absolute",
                              paddingTop: activeLayout.container.style.py,
                              paddingBottom:
                                 activeLayout.container.style.py / 3,
                              paddingLeft: activeLayout.container.style.px,
                              paddingRight: activeLayout.container.style.px,

                              borderTopWidth: activeLayout.container.border.top,
                              borderLeftWidth:
                                 activeLayout.container.border.left,
                              borderRightWidth:
                                 activeLayout.container.border.right,
                              borderBottomWidth:
                                 activeLayout.container.border.bottom,
                              borderTopColor:
                                 activeLayout.container.border.color,
                              borderLeftColor:
                                 activeLayout.container.border.color,
                              borderRightColor:
                                 activeLayout.container.border.color,
                              borderBottomColor:
                                 activeLayout.container.border.color,
                              borderStyle: activeLayout.container.border.style,
                              borderRadius:
                                 activeLayout.container.border.radius,
                              backgroundColor:
                                 activeLayout.container.background.color,
                              backgroundImage:
                                 activeLayout.container.background.image !=
                                    "none" &&
                                 `url(${SERVER_URL}/uploads/${activeLayout.container.background.image})`,
                              backgroundPosition: `${activeLayout.container.background.topBottom} ${activeLayout.container.background.leftRight}`,
                              backgroundRepeat:
                                 activeLayout.container.background.repeat,
                              backgroundSize: activeLayout.container.background
                                 .size
                                 ? activeLayout.container.background.size
                                 : "auto",

                              boxSizing: "border-box",
                              width: "100%",
                              height: "100%",
                           }}
                        >
                           <LeaderboardComponent
                              leaderboard={activeLeaderboard.teams}
                              ranking={activeLeaderboard.ranking}
                              multiplier={activeLeaderboard.multiplier}
                              roundTitles={activeLeaderboard.roundTitles}
                              styles={activeLayout}
                           />
                        </Box>
                     </Container>
                  </Box>
               </Box>
            </>
         )}
      </>
   );
}
