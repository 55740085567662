import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import LayoutTemplateEditor from "../layouts/LayoutTemplateEditorSpintheWheel";

import EditorDataForm from "../editor/EditorSpintheWheelDataForm";

// MODAL
import Modal from "@mui/material/Modal";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 600,
   bgcolor: "#efefef",
   border: "2px solid #cccccc",
   boxShadow: 24,
   p: 4,
};

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function StyleEditor(props) {
   // MODAL
   const [active, setActive] = useState({
      title: "Who will win?",
      answers: {
         0: "John",
         1: "Christian",
         2: "Kris",
         3: "Stewart",
      },
      id: "624e76c3159604a7be0ec2c8318c637f6eaa9d52",
      type: "list",
   });

   const [modalOpen, setModalOpen] = useState(false);

   const handleOpen = (poll) => {
      setModalOpen(true);
   };
   const handleClose = () => {
      setModalOpen(false);
   };

   const handleUpdate = (data) => {
      setActive(data);
      handleClose();
   };

   // SOCKET
   const socket = useContext(SocketContext);

   // STYLES
   const [styles, setStyles] = useState();
   const [fonts, setFonts] = useState();
   const [selectedLayout, setSelectedLayout] = useState();

   const [data, setData] = useState();

   useEffect(() => {
      // INITIAL LOAD
      socket.emit("getWheels", (response) => {
         setData(response);
      });

      socket.emit("getStyles", (response) => {
         setStyles(response.stylesSpintheWheel);
         setFonts(response.fonts);
      });
   }, []);

   useEffect(() => {
      socket.on("refreshStyles", () => {
         socket.emit("getWheels", (response) => {
            socket.emit("getStyles", (styleResponse) => {
               setStyles(styleResponse.stylesSpintheWheel);
               setFonts(styleResponse.fonts);
            });
         });
      });
   }, [socket]);

   const handleUpdateDB = (data) => {
      if (data.action == "insert") {
         data.layout.global.parameters.layoutTitle =
            "Copy of " + data.layout.global.parameters.layoutTitle;
      }

      socket.emit("updateLayoutSpintheWheel", data, (response) => {
         setSelectedLayout(response.id);
      });
   };

   useEffect(() => {
      if (fonts) {
         fonts.map((font) => {
            var fontFace = new FontFace(
               font.split(".")[0],
               "url(" + SERVER_URL + "/fonts/" + font + ")"
            );

            fontFace
               .load()
               .then(function (loaded_face) {
                  document.fonts.add(loaded_face);
                  document.body.style.fontFamily =
                     '"' + font.split(".")[0] + '", Arial';
               })
               .catch(function (error) {
                  console.log(error);
               });
         });
      }
   }, [fonts]);

   return (
      <>
         {styles && (
            <LayoutTemplateEditor
               key={selectedLayout}
               active={active}
               fonts={fonts}
               styles={styles}
               selectedLayout={selectedLayout}
               setSelectedLayout={setSelectedLayout}
               updateDB={handleUpdateDB}
               openDataEditor={handleOpen}
            />
         )}

         {/* MODAL - EDIT PREVIEW DATA */}
         <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Edit Preview Data
               </Typography>
               <Typography id='modal-description' sx={{ mt: 2 }}>
                  <EditorDataForm
                     data={data}
                     update={handleUpdate}
                     closeModal={handleClose}
                  />
               </Typography>
            </Box>
         </Modal>
      </>
   );
}
