import React, {
   useState,
   useRef,
   useCallback,
   useContext,
   useEffect,
} from "react";
import ReactDOM from "react-dom";

import { SocketContext } from "../context/socket";
import { motion, AnimatePresence, Reorder } from "framer-motion";
import _ from "lodash";

import Cropper from "react-easy-crop";
import getCroppedImg from "../components/CropImage";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import uploadImage from "../images/uploadImage.png";
import "../tierlists.css";

// MODAL
import Modal from "@mui/material/Modal";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 600,
   bgcolor: "#efefef",
   border: "2px solid #cccccc",
   boxShadow: 24,
   p: 4,
};

function Tierlist() {
   const socket = useContext(SocketContext);
   const firstUpdate = useRef(true);
   const firstTierlistUpdate = useRef(true);
   const firstTierlistImagesUpdate = useRef(true);
   const firstModalUpdate = useRef(true);

   const [sidebar, setSidebar] = useState([]);
   const [tierlist, setTierlist] = useState({});
   const [tierlistImages, setTierlistImages] = useState([]);
   const [modalIsOpen, setIsOpen] = useState(false);
   const [modalActiveRow, setModalActiveRow] = useState();
   const [tierlistDBs, setTierlistDBs] = useState([]);

   // MODAL

   function openModal(row) {
      setModalActiveRow(row);
      setIsOpen(true);
   }

   function closeModal() {
      setIsOpen(false);
   }

   // IMAGE UPLOADER

   const imgRef = useRef(null);
   const previewCanvasRef = useRef(null);

   const [crop, setCrop] = useState({ x: 0, y: 0 });
   const [zoom, setZoom] = useState(1);
   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
   const [croppedImage, setCroppedImage] = useState(null);
   const [upImg, setUpImg] = useState();

   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels);
   }, []);

   const showCroppedImage = useCallback(async () => {
      try {
         const croppedImage = await getCroppedImg(upImg, croppedAreaPixels, 0);

         setCroppedImage(croppedImage);
      } catch (e) {
         console.error(e);
      }
   }, [croppedAreaPixels]);

   const onSelectFile = (e) => {
      if (e.target.files && e.target.files.length > 0) {
         const reader = new FileReader();
         reader.addEventListener("load", () => setUpImg(reader.result));
         reader.readAsDataURL(e.target.files[0]);
      }
   };

   const onLoad = useCallback((img) => {
      imgRef.current = img;
   }, []);

   // FUNCTIONS

   function changeSelectedDB(event) {
      if (event.target.value != "") {
         socket.emit("loadTierlist", event.target.value, (tierlist, images) => {
            setTierlist(tierlist);
            setTierlistImages(images);

            socket.emit("clientBroadcast", {
               command: "reloadDB",
            });
         });
      }
   }

   const tierlistAddRemove = (e) => {
      setTierlist((prevState) => {
         let newData = [...prevState.items];

         let rowid = parseInt(e.target.getAttribute("rowid"));
         let name = e.target.getAttribute("name");
         let index = _.findIndex(
            newData,
            (row) => {
               return row.rowid == rowid;
            },
            0
         );

         if (name == "delete") {
            newData.splice(index, 1);
         }

         if (name == "add") {
            let maxRowId = _.max(_.map(newData, "rowid"));
            let maxOrder = _.max(_.map(newData, "order"));

            maxRowId = !isNaN(maxRowId) ? maxRowId + 1 : 0;
            maxOrder = !isNaN(maxOrder) ? maxOrder + 1 : 0;

            newData.push({
               rowid: maxRowId,
               text: "",
               slot: "",
               active: false,
               updated: Date.now(),
               order: maxOrder,
               image: "",
            });
         }

         socket.emit("clientBroadcast", {
            command: "setTierlist",
            value: { ...prevState, items: newData },
         });

         return { ...prevState, items: newData };
      });

      setTierlistImages((prevState) => {
         let newData = [...prevState];
         let name = e.target.getAttribute("name");

         if (name == "delete") {
            let rowid = parseInt(e.target.getAttribute("rowid"));
            let index = _.findIndex(
               newData,
               (row) => {
                  return row.rowid == rowid;
               },
               0
            );
            newData.splice(index, 1);
         }

         socket.emit("clientBroadcast", {
            command: "setTierlistImages",
            value: newData,
         });

         return newData;
      });
   };

   const removeImage = (e) => {
      setTierlistImages((prevState) => {
         let newData = [...prevState];

         let rowid = parseInt(e.target.getAttribute("rowid"));
         let index = _.findIndex(
            newData,
            (row) => {
               return row.rowid == rowid;
            },
            0
         );

         newData.splice(index, 1);

         socket.emit("clientBroadcast", {
            command: "setTierlistImages",
            value: newData,
         });

         return newData;
      });
   };

   const updateTierlistValue = (e) => {
      setTierlist((prevState) => {
         let newData = [...prevState.items];

         let rowid = parseInt(e.target.getAttribute("rowid"));
         let name = e.target.getAttribute("name");
         let value = e.target.value;
         let index = _.findIndex(
            newData,
            (row) => {
               return row.rowid == rowid;
            },
            0
         );

         if (name == "active") {
            _.each(newData, function (row) {
               _.set(row, "active", false);
            });

            newData[index][name] = JSON.parse(value);
         } else {
            newData[index][name] = value;
         }

         if (name == "slot") {
            newData[index]["active"] = false;
         }

         if (name != "text") {
            newData[index]["updated"] = Date.now();
         }

         socket.emit("clientBroadcast", {
            command: "setTierlist",
            value: { ...prevState, items: newData },
         });

         return { ...prevState, items: newData };
      });
   };

   const updateTierlistName = (e) => {
      setTierlist((prevState) => {
         let value = e.target.value;

         socket.emit("clientBroadcast", {
            command: "setTierlist",
            value: { ...prevState, category: value },
         });

         socket.emit("updateTierlistName", value, (callback) => {
            setTierlistDBs(callback);
         });

         return { ...prevState, category: value };
      });
   };

   const deactivateAllTier = () => {
      setTierlist((prevState) => {
         let newData = [...prevState.items];

         _.each(newData, function (row) {
            _.set(row, "active", false);
         });

         socket.emit("clientBroadcast", {
            command: "setTierlist",
            value: { ...prevState, items: newData },
         });

         return { ...prevState, items: newData };
      });
   };

   useEffect(() => {
      if (firstModalUpdate.current) {
         firstModalUpdate.current = false;
         return;
      }

      if (modalActiveRow) {
         setTierlistImages((prevState) => {
            let newData = [...prevState];
            let index = _.findIndex(
               newData,
               (row) => {
                  return row.rowid == modalActiveRow.rowid;
               },
               0
            );

            if (index == -1) {
               newData.push({
                  rowid: modalActiveRow.rowid,
                  image: croppedImage,
               });
            } else {
               newData[index]["image"] = croppedImage;
            }

            socket.emit("clientBroadcast", {
               command: "setTierlistImages",
               value: newData,
            });

            return newData;
         });
      }

      closeModal();
      setModalActiveRow();
      setUpImg();
   }, [croppedImage]);

   useEffect(() => {
      if (firstTierlistUpdate.current) {
         firstTierlistUpdate.current = false;
         return;
      }

      socket.emit("updateTierlistDB", tierlist);
   }, [tierlist]);

   useEffect(() => {
      if (firstTierlistImagesUpdate.current) {
         firstTierlistImagesUpdate.current = false;
         return;
      }

      socket.emit("updateTierlistImagesDB", tierlistImages);
   }, [tierlistImages]);

   useEffect(() => {
      socket.emit("getTierlistDB", (tierlist, images) => {
         setTierlist(tierlist);
         setTierlistImages(images);
      });

      socket.emit("getTierlists", (payload) => {
         setTierlistDBs(payload);
      });
   }, []);

   useEffect(() => {
      socket.on("broadcastData", (payload) => {
         switch (payload.command) {
            case "setTierlist":
               setTierlist(payload.value);
               break;
            case "setTierlistImages":
               setTierlistImages(payload.value);
               break;
            case "reloadDB":
               socket.emit("getTierlistDB", (tierlist, images) => {
                  setTierlist(tierlist);
                  setTierlistImages(images);
               });
               break;
            case "updateActive":
               break;
            default:
         }
      });
   }, [socket]);

   return (
      <>
         <button
            onClick={tierlistAddRemove}
            style={{ float: "right" }}
            name='add'
            className='activate'
         >
            + ADD ROW
         </button>
         <h2>
            Tier Lists
            <br />
            {tierlistDBs.length > 0 && (
               <>
                  <select onChange={changeSelectedDB}>
                     <option value=''>--</option>
                     {tierlistDBs.map((el, i) => (
                        <>
                           <option
                              selected={
                                 el == tierlist.category ? "selected" : ""
                              }
                              value={i}
                           >
                              {el}
                           </option>
                        </>
                     ))}
                  </select>
               </>
            )}
            {tierlist && (
               <input
                  className='categoryTitle'
                  type='text'
                  placeholder='Category Title'
                  value={tierlist.category ? tierlist.category : ""}
                  onChange={updateTierlistName}
               />
            )}
         </h2>
         <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
               {tierlist && tierlistImages && (
                  <>
                     <Grid item xs={6}>
                        <h2>Staging</h2>
                        {
                           <motion.div className='slot'>
                              {_(tierlist.items)
                                 .filter((x) => x.slot == "")
                                 .orderBy("order", "asc")
                                 .value()
                                 .map((row, i) => (
                                    <div
                                       key={row.rowid}
                                       className={
                                          "tierlistRow " +
                                          (row.active == 1 ? "active" : "")
                                       }
                                    >
                                       {_(tierlistImages)
                                          .filter((x) => x.rowid == row.rowid)
                                          .value()
                                          .map((imagerow, i) => (
                                             <>
                                                {imagerow && (
                                                   <img
                                                      onClick={removeImage}
                                                      rowid={imagerow.rowid}
                                                      name='image'
                                                      src={imagerow.image}
                                                   />
                                                )}

                                                {!imagerow && (
                                                   <img
                                                      onClick={() =>
                                                         openModal(row)
                                                      }
                                                      src={uploadImage}
                                                   />
                                                )}
                                             </>
                                          ))}
                                       {_(tierlistImages)
                                          .filter((x) => x.rowid == row.rowid)
                                          .value().length == 0 && (
                                          <>
                                             <img
                                                onClick={() => openModal(row)}
                                                src={uploadImage}
                                             />
                                          </>
                                       )}

                                       <input
                                          className='textInput'
                                          type='text'
                                          placeholder='Text'
                                          tabIndex='1'
                                          name='text'
                                          value={row.text}
                                          rowid={row.rowid}
                                          onChange={updateTierlistValue}
                                       />

                                       {row.active && row.slot == "" && (
                                          <>
                                             <button
                                                rowid={row.rowid}
                                                name='slot'
                                                value='S'
                                                onClick={updateTierlistValue}
                                                className={
                                                   "slotButton" +
                                                   (row.slot == "S"
                                                      ? " slotPos"
                                                      : "")
                                                }
                                             >
                                                S
                                             </button>
                                             <button
                                                rowid={row.rowid}
                                                name='slot'
                                                value='A'
                                                onClick={updateTierlistValue}
                                                className={
                                                   "slotButton" +
                                                   (row.slot == "A"
                                                      ? " slotPos"
                                                      : "")
                                                }
                                             >
                                                A
                                             </button>
                                             <button
                                                rowid={row.rowid}
                                                name='slot'
                                                value='B'
                                                onClick={updateTierlistValue}
                                                className={
                                                   "slotButton" +
                                                   (row.slot == "B"
                                                      ? " slotPos"
                                                      : "")
                                                }
                                             >
                                                B
                                             </button>
                                             <button
                                                rowid={row.rowid}
                                                name='slot'
                                                value='C'
                                                onClick={updateTierlistValue}
                                                className={
                                                   "slotButton" +
                                                   (row.slot == "C"
                                                      ? " slotPos"
                                                      : "")
                                                }
                                             >
                                                C
                                             </button>
                                             <button
                                                rowid={row.rowid}
                                                name='slot'
                                                value='D'
                                                onClick={updateTierlistValue}
                                                className={
                                                   "slotButton" +
                                                   (row.slot == "D"
                                                      ? " slotPos"
                                                      : "")
                                                }
                                             >
                                                D
                                             </button>
                                             <button
                                                rowid={row.rowid}
                                                name='slot'
                                                value='F'
                                                onClick={updateTierlistValue}
                                                className={
                                                   "slotButton" +
                                                   (row.slot == "F"
                                                      ? " slotPos"
                                                      : "")
                                                }
                                             >
                                                F
                                             </button>
                                          </>
                                       )}

                                       {!row.active && (
                                          <button
                                             rowid={row.rowid}
                                             className='show'
                                             name='active'
                                             value={true}
                                             onClick={updateTierlistValue}
                                          >
                                             SHOW
                                          </button>
                                       )}

                                       <button
                                          rowid={row.rowid}
                                          className='deactivate'
                                          name='delete'
                                          onClick={tierlistAddRemove}
                                       >
                                          X
                                       </button>
                                    </div>
                                 ))}
                           </motion.div>
                        }
                     </Grid>
                     <Grid item xs={6}>
                        <h2>Slots</h2>
                        {["S", "A", "B", "C", "D", "F"].map((slot, c) => (
                           <motion.div className='slot'>
                              <div className='slotTitle'>{slot}</div>

                              {_(tierlist.items)
                                 .filter((x) => x.slot == slot)
                                 .orderBy("updated", "asc")
                                 .value()
                                 .map((row, i) => (
                                    <div
                                       key={row.rowid}
                                       className={
                                          "tierlistRow " +
                                          (row.active == 1 ? "active" : "")
                                       }
                                    >
                                       {_(tierlistImages)
                                          .filter((x) => x.rowid == row.rowid)
                                          .value()
                                          .map((imagerow, i) => (
                                             <>
                                                {imagerow && (
                                                   <img
                                                      onClick={removeImage}
                                                      rowid={imagerow.rowid}
                                                      name='image'
                                                      src={imagerow.image}
                                                   />
                                                )}

                                                {!imagerow && (
                                                   <img
                                                      onClick={() =>
                                                         openModal(row)
                                                      }
                                                      src={uploadImage}
                                                   />
                                                )}
                                             </>
                                          ))}
                                       {_(tierlistImages)
                                          .filter((x) => x.rowid == row.rowid)
                                          .value().length == 0 && (
                                          <>
                                             <img
                                                onClick={() => openModal(row)}
                                                src={uploadImage}
                                             />
                                          </>
                                       )}
                                       <input
                                          className='textInput'
                                          type='text'
                                          placeholder='Text'
                                          tabIndex='2'
                                          name='text'
                                          value={row.text}
                                          rowid={row.rowid}
                                          onChange={updateTierlistValue}
                                       />
                                       <button
                                          rowid={row.rowid}
                                          name='slot'
                                          value='S'
                                          onClick={updateTierlistValue}
                                          className={
                                             "slotButton" +
                                             (row.slot == "S" ? " slotPos" : "")
                                          }
                                       >
                                          S
                                       </button>
                                       <button
                                          rowid={row.rowid}
                                          name='slot'
                                          value='A'
                                          onClick={updateTierlistValue}
                                          className={
                                             "slotButton" +
                                             (row.slot == "A" ? " slotPos" : "")
                                          }
                                       >
                                          A
                                       </button>
                                       <button
                                          rowid={row.rowid}
                                          name='slot'
                                          value='B'
                                          onClick={updateTierlistValue}
                                          className={
                                             "slotButton" +
                                             (row.slot == "B" ? " slotPos" : "")
                                          }
                                       >
                                          B
                                       </button>
                                       <button
                                          rowid={row.rowid}
                                          name='slot'
                                          value='C'
                                          onClick={updateTierlistValue}
                                          className={
                                             "slotButton" +
                                             (row.slot == "C" ? " slotPos" : "")
                                          }
                                       >
                                          C
                                       </button>
                                       <button
                                          rowid={row.rowid}
                                          name='slot'
                                          value='D'
                                          onClick={updateTierlistValue}
                                          className={
                                             "slotButton" +
                                             (row.slot == "D" ? " slotPos" : "")
                                          }
                                       >
                                          D
                                       </button>
                                       <button
                                          rowid={row.rowid}
                                          name='slot'
                                          value='F'
                                          onClick={updateTierlistValue}
                                          className={
                                             "slotButton" +
                                             (row.slot == "F" ? " slotPos" : "")
                                          }
                                       >
                                          F
                                       </button>
                                       <button
                                          rowid={row.rowid}
                                          name='slot'
                                          value=''
                                          onClick={updateTierlistValue}
                                          className={"deactivate"}
                                       >
                                          HIDE
                                       </button>
                                       {row.slot == "" && (
                                          <button
                                             rowid={row.rowid}
                                             className='show'
                                             name='active'
                                             value={true}
                                             onClick={updateTierlistValue}
                                          >
                                             SHOW
                                          </button>
                                       )}
                                    </div>
                                 ))}
                           </motion.div>
                        ))}
                     </Grid>
                  </>
               )}
            </Grid>
         </Box>

         {modalActiveRow && (
            <Modal
               isOpen={modalIsOpen}
               onRequestClose={closeModal}
               style={modalStyle}
            >
               <button onClick={closeModal}>X</button>
               <h3>{modalActiveRow.text}</h3>

               <div style={{ position: "relative", width: 580, height: 400 }}>
                  <Cropper
                     image={upImg}
                     crop={crop}
                     zoom={zoom}
                     aspect={29 / 20}
                     onCropChange={setCrop}
                     onCropComplete={onCropComplete}
                     onZoomChange={setZoom}
                  />
               </div>

               <div className='zoomcontrol'>
                  <input
                     type='range'
                     value={zoom}
                     min={1}
                     max={6}
                     step={0.1}
                     onChange={(e) => {
                        setZoom(e.target.value);
                     }}
                     className='zoom-range'
                  />
               </div>

               <label for='imageSelect'>
                  <div className='dragDrop'>Select Image</div>
                  <input
                     type='file'
                     id='imageSelect'
                     accept='image/*'
                     onChange={onSelectFile}
                  />
               </label>

               <button
                  onClick={showCroppedImage}
                  variant='contained'
                  color='primary'
                  style={{
                     display: "block",
                     padding: ".5em 2em",
                     margin: "0 auto",
                     backgroundColor: "#333",
                     color: "#fff",
                  }}
               >
                  SAVE
               </button>
            </Modal>
         )}
      </>
   );
}

export default Tierlist;
