import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Box, Button, Grid, IconButton } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import PhotoSizeSelectActualIcon from "@mui/icons-material/PhotoSizeSelectActual";
import DeleteIcon from "@mui/icons-material/Delete";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const SERVER_PORT = process.env.REACT_APP_SERVER_PORT;

export default function ImageUploader({
   layoutId,
   activePanel,
   section,
   field,
   image,
   callback,
}) {
   const [selectedFile, setSelectedFile] = useState(null);
   const [isSelected, setIsSelected] = useState(false);
   const [lastUpdate, setLastUpdate] = useState(Date.now());

   useEffect(() => {
      if (selectedFile) {
         handleSubmission();
      }
   }, [selectedFile]);

   const changeHandler = (event) => {
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
   };

   const handleSubmission = () => {
      const formData = new FormData();
      formData.append(
         "FileName",
         layoutId +
            "-" +
            activePanel +
            "-" +
            section +
            "-" +
            field +
            "-" +
            Date.now()
      );
      formData.append("File", selectedFile);

      fetch(SERVER_URL + "/upload", {
         method: "POST",
         body: formData,
      })
         .then((response) => response.json())
         .then((result) => {
            setSelectedFile();
            setIsSelected(false);

            if (result.success) {
               setLastUpdate(Date.now());
               callback(activePanel, section, field, result.filename);
            }
         })
         .catch((error) => {
            console.error("Error:", error);
            setSelectedFile();
            setIsSelected(false);
         });
   };

   return (
      <Grid container justifyContent='center' alignItems='center'>
         <Grid
            item
            as={motion.div}
            sx={{
               top: 0,
               left: 0,
               position: "absolute",
               width: "100%",
               height: "100%",
               backgroundColor: "#000",
               zIndex: 100,
            }}
            initial={{ opacity: 0 }}
            whileHover={{ opacity: 0.8 }}
         >
            <Grid
               container
               sx={{ height: "100%" }}
               justifyContent='center'
               alignItems='center'
            >
               <Grid item sx={{ textAlign: "center" }}>
                  <Button
                     component='label'
                     color='primary'
                     variant='contained'
                     startIcon={<PhotoSizeSelectActualIcon fontSize='medium' />}
                  >
                     <input
                        type='file'
                        accept='image/png, image/jpeg'
                        hidden
                        onChange={changeHandler}
                     />
                     SELECT IMAGE
                  </Button>
                  <br />
                  <Button
                     component='label'
                     color='error'
                     variant='contained'
                     sx={{ mt: 1 }}
                     startIcon={<DeleteIcon fontSize='medium' />}
                     onClick={() =>
                        callback(activePanel, section, field, "none")
                     }
                  >
                     DELETE
                  </Button>
               </Grid>
            </Grid>
         </Grid>
         <Grid
            item
            sx={{
               width: "100%",
               textAlign: "center",
               border: "1px solid #ccc",
               height: 150,

               // TRANSPARENCY BG
               backgroundImage:
                  "linear-gradient(45deg, #444 25%, transparent 25%, transparent 75%, #444 75%), linear-gradient(45deg, #444 25%, #333 25%, #333 75%, #444 75%)",
               backgroundSize: "40px 40px",
               backgroundPosition: "0px 0px, 20px 20px",
            }}
         >
            <Box
               sx={{
                  height: "100%",
                  p: 2,
               }}
            >
               {isSelected ? (
                  <img
                     src={URL.createObjectURL(selectedFile)}
                     style={{
                        width: "auto",
                        height: "100%",
                        maxHeight: 115,
                     }}
                  />
               ) : (
                  <>
                     {image == "none" ? (
                        <> No Image </>
                     ) : (
                        <img
                           src={`${SERVER_URL}/uploads/${image}`}
                           style={{ maxWidth: "100%", maxHeight: 115 }}
                        />
                     )}
                  </>
               )}
            </Box>
         </Grid>
      </Grid>
   );
}
