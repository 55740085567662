import * as React from "react";
import Grid from "@mui/material/Grid";

// LANGUAGE FOR LABELS
import { language } from "./Language";

export default function PropWrapper({ children, label, type }) {
   return (
      <Grid
         container
         sx={{
            border: "1px solid #efefef",
            borderColor: "#efefef",
         }}
      >
         <Grid
            item
            xs={12}
            sx={{
               fontWeight: "bold",
               textTransform: "uppercase",
               fontSize: 11,
               color: "#666",
               backgroundColor: "#efefef",
               px: 2,
               py: 0.5,
            }}
         >
            {language[label]}
         </Grid>

         <Grid
            item
            xs={12}
            sx={{
               py: 1,
               px: 3,
               textAlign: "left",
               mb: 1,
               backgroundColor: "#fff",
            }}
         >
            {children}
         </Grid>
      </Grid>
   );
}
