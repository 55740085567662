import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useParams } from "react-router-dom";

import ViewerWrapper from "./ViewerWrapper";
import ViewerComponent from "./ViewerComponentSpintheWheel";

//HOOKS
import useGlobal from "../hooks/useGlobal";

export default function Viewer(props) {
   // USE GLOBAL HOOK
   const [
      fonts,
      activeLayout,
      state,
      updateState,
      {
         subscribePolls,
         subscribeTugofWar,
         subscribeLeaderboard,
         subscribeSpintheWheel,
      },
   ] = useGlobal();

   useEffect(() => {
      subscribeSpintheWheel();
   }, []);

   useEffect(() => {
      // console.log(state);
   }, [state]);

   useEffect(() => {
      //console.log(activeLayout);
   }, [activeLayout]);

   return (
      <>
         {activeLayout && (
            <ViewerWrapper key='spintheWheelViewer' activeLayout={activeLayout}>
               <ViewerComponent
                  key={"wheel"}
                  edit={false}
                  activeLayout={activeLayout}
                  data={state.data.activeWheel}
                  result={state.activeResults}
               />
            </ViewerWrapper>
         )}
      </>
   );
}
