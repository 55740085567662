import React, { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function useGlobal(init = {}) {
   const socket = useContext(SocketContext);

   const [fonts, setFonts] = useState();
   const [activeLayout, setActiveLayout] = useState();
   const [state, setState] = useState({
      data: null,
      activeResultTotal: 0,
      activeResults: null,
      activeTimer: false,
   });

   useEffect(() => {
      console.log(state);
   }, [state]);

   const updateState = (stateFunc, value) => {
      setState((prevState) => {
         let newState = { ...prevState };
         if (typeof value !== "undefined") {
            newState[stateFunc] = value;
         }
         return newState;
      });
   };

   const updateNestedState = (stateFunc, prop, value) => {
      setState((prevState) => {
         let newState = { ...prevState };
         if (value) {
            newState[stateFunc][prop] = value;
         }
         return newState;
      });
   };

   useEffect(() => {
      if (fonts) {
         fonts.map((font) => {
            var fontFace = new FontFace(
               font.split(".")[0],
               "url(" + SERVER_URL + "/fonts/" + font + ")"
            );

            fontFace
               .load()
               .then(function (loaded_face) {
                  document.fonts.add(loaded_face);
                  document.body.style.fontFamily =
                     '"' + font.split(".")[0] + '", Arial';
               })
               .catch(function (error) {
                  console.log(error);
               });
         });
      }
   }, [fonts]);

   const refreshStyles = (type, layout) => {
      socket.emit("getStyles", (styleResponse) => {
         setActiveLayout(styleResponse[type][layout]);
         setFonts(styleResponse.fonts);
      });
   };

   const handlers = React.useMemo(
      () => ({
         subscribePolls: () => {
            // INIT POLL DATA
            socket.emit("getData", (response) => {
               updateState("data", response.activePoll);

               if (response.activePoll.endTime > Date.now()) {
                  updateState("activeTimer", true);
               } else {
                  updateState("activeTimer", false);
               }

               refreshStyles("styles", response.activePoll.layout);
            });

            // UPDATE ACTIVE RESULTS TALLIES WHEN THERE ARE RESULTS
            socket.on("activeResults", (total, results) => {
               updateState("activeResultTotal", total);
               updateState("activeResults", results);
            });

            // REFRESH THE POLL DATA AS NECESSARY
            socket.on("refreshPolls", () => {
               socket.emit("getData", (response) => {
                  updateState("data", response.activePoll);

                  if (response.activePoll.endTime > Date.now()) {
                     updateState("activeTimer", true);
                  } else {
                     updateState("activeTimer", false);
                  }

                  refreshStyles("styles", response.activePoll.layout);
               });
            });

            // REFRESH STYLES
            socket.on("refreshStyles", (type) => {
               if (type === "poll") {
                  socket.emit("getData", (response) => {
                     refreshStyles("styles", response.activePoll.layout);
                  });
               }
            });
         },
         subscribeTugofWar: () => {
            // INITIAL LOAD
            socket.emit("getData", (response) => {
               updateState("data", response.activeTugofWar);
               refreshStyles("stylesTugofWar", response.activeTugofWar.layout);
            });

            // UPDATE ACTIVE RESULTS TALLIES WHEN THERE ARE RESULTS
            socket.on("activeTugofWarResults", (total, results) => {
               updateState("activeResultTotal", total);
               updateState("activeResults", results);
            });

            // REFRESH THE POLL DATA AS NECESSARY
            socket.on("refreshPolls", () => {
               socket.emit("getData", (response) => {
                  updateState("data", response.activeTugofWar);
                  refreshStyles(
                     "stylesTugofWar",
                     response.activeTugofWar.layout
                  );
               });
            });

            // REFRESH STYLES
            socket.on("refreshStyles", (type) => {
               if (type === "tugofwar") {
                  socket.emit("getData", (response) => {
                     refreshStyles(
                        "stylesTugofWar",
                        response.activeTugofWar.layout
                     );
                  });
               }
            });
         },
         subscribeLeaderboard: () => {
            // INITIAL LOAD
            socket.emit("getDBLeaderboard", (response) => {
               updateState("data", response);
               refreshStyles("stylesLeaderboard", response.activeLayout);
            });

            socket.on("refreshLeaderboard", () => {
               socket.emit("getDBLeaderboard", (response) => {
                  updateState("data", response);
                  refreshStyles("stylesLeaderboard", response.activeLayout);
               });
            });

            socket.on("refreshStyles", (type) => {
               if (type === "leaderboard") {
                  socket.emit("getDBLeaderboard", (response) => {
                     refreshStyles("stylesLeaderboard", response.activeLayout);
                  });
               }
            });

            socket.on("refreshData", (arrayKey, payload) => {
               updateNestedState("data", arrayKey, payload);
            });
         },
         subscribeSpintheWheel: () => {
            // INITIAL LOAD
            socket.emit("getWheels", (response) => {
               updateState("activeResults", -1);
               updateState("data", response);
               refreshStyles("stylesSpintheWheel", response.activeWheel.layout);
            });

            socket.on("refreshAll", () => {
               updateState("activeResults", -1);
               socket.emit("getWheels", (response) => {
                  updateState("data", response);
                  refreshStyles(
                     "stylesSpintheWheel",
                     response.activeWheel.layout
                  );
               });
            });

            socket.on("refreshWheels", () => {
               socket.emit("getWheels", (response) => {
                  updateState("data", response);
                  refreshStyles(
                     "stylesSpintheWheel",
                     response.activeWheel.layout
                  );
               });
            });

            socket.on("refreshStyles", (type) => {
               if (type === "spinthewheel") {
                  socket.emit("getWheels", (response) => {
                     refreshStyles(
                        "stylesSpintheWheel",
                        response.activeWheel.layout
                     );
                  });
               }
            });

            socket.on("spin", (answer) => {
               updateState("activeResults", -1);
               updateState("activeResults", answer);
            });

            socket.on("reset", () => {
               updateState("activeResults", -1);
            });
         },
      }),
      [init]
   );

   return [fonts, activeLayout, state, updateState, handlers];
}

export default useGlobal;
