import { useState, useEffect } from "react";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

function DurationComponent(props) {
   const [value, setValue] = useState("");

   useEffect(() => {
      if (props.duration) {
         setValue(props.duration);
      }
   }, [props]);

   const handleChange = (e) => {
      setValue(e.target.value);
      props.callback(e.target.value);
   };

   return (
      <FormControl fullWidth sx={{ my: 2 }}>
         <FormLabel id={`duration-radio-buttons-${props.name}-label`}>
            <b>Duration</b>
         </FormLabel>
         <RadioGroup
            aria-labelledby={`duration-radio-buttons-${props.name}-label`}
            value={value}
            name={`${props.name}-radio-buttons-group`}
            onChange={handleChange}
         >
            {props.name === "tierlist" && (
               <FormControlLabel
                  value='15'
                  control={<Radio />}
                  label='15 seconds'
               />
            )}
            <FormControlLabel
               value='30'
               control={<Radio />}
               label='30 seconds'
            />
            <FormControlLabel
               value='45'
               control={<Radio />}
               label='45 seconds'
            />
            <FormControlLabel value='60' control={<Radio />} label='1 minute' />
            <FormControlLabel
               value='120'
               control={<Radio />}
               label='2 minutes'
            />
         </RadioGroup>
      </FormControl>
   );
}

export default DurationComponent;
