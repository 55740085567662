import _ from "lodash";

export default function Test() {
   const schema = { a: 1, b: 2, c: { opt1: 1, opt2: 4 }, d: 4, e: 5, f: 6 };
   const data = { c: { opt1: 10, opt2: 2, opt3: 3, opt4: 4 }, e: 86 };

   return (
      <>
         Schema:
         <br />
         <pre>{JSON.stringify(schema, null, 4)}</pre>
         <br />
         <br />
         Data:
         <br />
         <pre>{JSON.stringify(data, null, 4)}</pre>
         <br />
         <br />
         Intersection:
         <br />
         <pre>
            {JSON.stringify(
               _.intersection(_.keys(schema), _.keys(data)),
               null,
               4
            )}
         </pre>
         <br />
         <br />
         Merged:
         <br />
         <pre>{JSON.stringify(_.merge(schema, data), null, 4)}</pre>
      </>
   );
}
