import { useEffect } from "react";
import { motion, AnimatePresence, layout } from "framer-motion";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import RndContainer from "../editor/RndContainer";
import TextScroller from "./TextScroller";
import TextSquisher from "./TextSquisher";
import GraphBar from "./GraphBar";
import Container from "../editor/Container";

import Countdown, { zeroPad } from "react-countdown";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

// COUNTDOWN
const renderer = ({ minutes, seconds, completed }) => {
   return (
      <AnimatePresence>
         {!completed && (
            <motion.div
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               exit={{ opacity: 0 }}
            >
               {zeroPad(minutes)}:{zeroPad(seconds)}
            </motion.div>
         )}
      </AnimatePresence>
   );
};

// COUNTDOWN EDIT
const rendererEdit = ({ minutes, seconds, completed }) => {
   return (
      <div>
         {zeroPad(minutes)}:{zeroPad(seconds)}
      </div>
   );
};

export default function ViewerComponent({
   edit,
   activeLayout,
   activePoll,
   previewScale,
   handleModal,
   handleUpdate,
   handleAdjust,
   activeResultTotal,
   activeResults,
   activeTimer,
   setActiveTimer,
}) {
   return (
      <>
         {activeLayout && (
            <div className={edit ? "editMode" : ""}>
               {/* LOGO */}
               <Container
                  previewScale={previewScale}
                  handleModal={handleModal}
                  handleUpdate={handleUpdate}
                  handleAdjust={handleAdjust}
                  edit={edit}
                  key='logo'
                  name='logo'
                  data={activeLayout.logo}
                  zIndex={200}
               >
                  <Grid
                     container
                     alignItems='center'
                     justifyContent='center'
                     className='logo'
                     sx={{
                        width: "100%",
                        height: "100%",
                        opacity: activeLayout.logo.parameters.visible ? 1 : 0,
                        backgroundColor: activeLayout.logo.background.color,
                        px: activeLayout.logo.style.px,
                        py: activeLayout.logo.style.py,
                        boxSizing: "border-box",
                        borderTopWidth: activeLayout.logo.border.top,
                        borderLeftWidth: activeLayout.logo.border.left,
                        borderRightWidth: activeLayout.logo.border.right,
                        borderBottomWidth: activeLayout.logo.border.bottom,
                        borderTopColor: activeLayout.logo.border.color,
                        borderLeftColor: activeLayout.logo.border.color,
                        borderRightColor: activeLayout.logo.border.color,
                        borderBottomColor: activeLayout.logo.border.color,
                        borderStyle: activeLayout.logo.border.style,
                        borderRadius: activeLayout.logo.border.radius,
                     }}
                  >
                     <Grid
                        item
                        sx={{
                           position: "relative",
                           width: "100%",
                           height: "100%",
                        }}
                     >
                        {activeLayout.logo.style.image !== "none" &&
                           activeLayout.logo.parameters.visible && (
                              <img
                                 src={`${SERVER_URL}/uploads/${activeLayout.logo.style.image}`}
                                 alt='Logo'
                                 style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    maxWidth: "100%",
                                    transform:
                                       "translateY(-50%) translateX(-50%)",
                                    zIndex: 5,
                                 }}
                              />
                           )}
                        {activeLayout.logo.background.image !== "none" &&
                           activeLayout.logo.parameters.visible && (
                              <motion.div
                                 initial={{ opacity: 0 }}
                                 animate={{ opacity: 1 }}
                              >
                                 <img
                                    src={`${SERVER_URL}/uploads/${activeLayout.logo.background.image}`}
                                    style={{
                                       width: "100%",
                                       position: "absolute",
                                       top: "50%",
                                       left: "50%",
                                       zIndex: 1,
                                       transform:
                                          "translateX(-50%) translateY(-50%)",
                                    }}
                                 />
                              </motion.div>
                           )}
                     </Grid>
                  </Grid>
               </Container>
               {/* TIMER */}

               {activeTimer && (
                  <Container
                     previewScale={previewScale}
                     handleModal={handleModal}
                     handleUpdate={handleUpdate}
                     handleAdjust={handleAdjust}
                     edit={edit}
                     key='timer'
                     name='timer'
                     data={activeLayout.timer}
                     zIndex={210}
                  >
                     <Box
                        key={"timer-child"}
                        className='timer'
                        sx={{
                           color: activeLayout.timer.style.fontColor
                              ? activeLayout.timer.style.fontColor
                              : "",
                           textAlign: activeLayout.timer.style.textAlign,
                           fontFamily: activeLayout.timer.style.fontFamily
                              ? activeLayout.timer.style.fontFamily.split(
                                   "."
                                )[0]
                              : "unset",
                           fontSize: activeLayout.timer.style.fontSize
                              ? activeLayout.timer.style.fontSize
                              : "",
                           opacity: activeLayout.timer.parameters.visible
                              ? 1
                              : 0,
                           borderTopWidth: activeLayout.timer.border.top,
                           borderLeftWidth: activeLayout.timer.border.left,
                           borderRightWidth: activeLayout.timer.border.right,
                           borderBottomWidth: activeLayout.timer.border.bottom,
                           borderTopColor: activeLayout.timer.border.color,
                           borderLeftColor: activeLayout.timer.border.color,
                           borderRightColor: activeLayout.timer.border.color,
                           borderBottomColor: activeLayout.timer.border.color,
                           borderStyle: activeLayout.timer.border.style,
                           borderRadius: activeLayout.timer.border.radius,
                           height: "100%",
                           width: "100%",
                           boxSizing: "border-box",
                           overflow:
                              activeLayout.timer.background.image !== "none"
                                 ? "visible"
                                 : "hidden",
                        }}
                     >
                        <motion.div
                           style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              backgroundColor: activeLayout.timer.background
                                 .color
                                 ? activeLayout.timer.background.color
                                 : "transparent",
                              lineHeight: activeLayout.timer.position.height
                                 ? activeLayout.timer.position.height + 5 + "px"
                                 : "auto",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                           }}
                        >
                           {activeLayout.timer.background.image !== "none" &&
                              activeLayout.timer.parameters.visible && (
                                 <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                 >
                                    <img
                                       src={`${SERVER_URL}/uploads/${activeLayout.timer.background.image}`}
                                       style={{
                                          width: "100%",
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          zIndex: 1,
                                          transform:
                                             "translateX(-50%) translateY(-50%)",
                                       }}
                                    />
                                 </motion.div>
                              )}

                           <div
                              style={{
                                 position: "relative",
                                 zIndex: 100,
                              }}
                           >
                              <div
                                 style={{
                                    position: "relative",
                                    zIndex: 100,
                                    paddingTop:
                                       activeLayout.timer.style.paddingTop,
                                 }}
                              >
                                 {activePoll.endTime && (
                                    <Countdown
                                       date={new Date(activePoll.endTime)}
                                       renderer={renderer}
                                       onComplete={() => setActiveTimer(false)}
                                    />
                                 )}
                              </div>
                           </div>
                        </motion.div>
                     </Box>
                  </Container>
               )}

               {edit && (
                  <Container
                     previewScale={previewScale}
                     handleModal={handleModal}
                     handleUpdate={handleUpdate}
                     handleAdjust={handleAdjust}
                     edit={edit}
                     key='timer'
                     name='timer'
                     data={activeLayout.timer}
                     zIndex={210}
                  >
                     <Box
                        key={"timer-child"}
                        className='timer'
                        sx={{
                           color: activeLayout.timer.style.fontColor
                              ? activeLayout.timer.style.fontColor
                              : "",
                           textAlign: activeLayout.timer.style.textAlign,
                           fontFamily: activeLayout.timer.style.fontFamily
                              ? activeLayout.timer.style.fontFamily.split(
                                   "."
                                )[0]
                              : "unset",
                           fontSize: activeLayout.timer.style.fontSize
                              ? activeLayout.timer.style.fontSize
                              : "",
                           opacity: activeLayout.timer.parameters.visible
                              ? 1
                              : 0,
                           borderTopWidth: activeLayout.timer.border.top,
                           borderLeftWidth: activeLayout.timer.border.left,
                           borderRightWidth: activeLayout.timer.border.right,
                           borderBottomWidth: activeLayout.timer.border.bottom,
                           borderTopColor: activeLayout.timer.border.color,
                           borderLeftColor: activeLayout.timer.border.color,
                           borderRightColor: activeLayout.timer.border.color,
                           borderBottomColor: activeLayout.timer.border.color,
                           borderStyle: activeLayout.timer.border.style,
                           borderRadius: activeLayout.timer.border.radius,
                           height: "100%",
                           width: "100%",
                           boxSizing: "border-box",
                           overflow:
                              activeLayout.timer.background.image !== "none"
                                 ? "visible"
                                 : "hidden",
                        }}
                     >
                        <motion.div
                           style={{
                              position: "relative",
                              width: "100%",
                              height: "100%",
                              backgroundColor: activeLayout.timer.background
                                 .color
                                 ? activeLayout.timer.background.color
                                 : "transparent",
                              lineHeight: activeLayout.timer.position.height
                                 ? activeLayout.timer.position.height + 5 + "px"
                                 : "auto",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                           }}
                        >
                           {activeLayout.timer.background.image !== "none" &&
                              activeLayout.timer.parameters.visible && (
                                 <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                 >
                                    <img
                                       src={`${SERVER_URL}/uploads/${activeLayout.timer.background.image}`}
                                       style={{
                                          width: "100%",
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          zIndex: 1,
                                          transform:
                                             "translateX(-50%) translateY(-50%)",
                                       }}
                                    />
                                 </motion.div>
                              )}

                           <div
                              style={{
                                 position: "relative",
                                 zIndex: 100,
                                 paddingTop:
                                    activeLayout.timer.style.paddingTop + "px",
                              }}
                           >
                              <Countdown
                                 date={Date.now() + 100000}
                                 renderer={edit ? rendererEdit : renderer}
                              />
                           </div>
                        </motion.div>
                     </Box>
                  </Container>
               )}

               {/* FRAME */}
               <Box
                  className='frame'
                  sx={{
                     position: "absolute",
                     boxSizing: "border-box",
                     width: activeLayout.frame.position.width,
                     height: activeLayout.frame.position.height,
                     borderTopWidth: activeLayout.frame.border.top,
                     borderLeftWidth: activeLayout.frame.border.left,
                     borderRightWidth: activeLayout.frame.border.right,
                     borderBottomWidth: activeLayout.frame.border.bottom,
                     borderTopColor: activeLayout.frame.border.color,
                     borderLeftColor: activeLayout.frame.border.color,
                     borderRightColor: activeLayout.frame.border.color,
                     borderBottomColor: activeLayout.frame.border.color,
                     borderStyle: activeLayout.frame.border.style,
                     borderRadius: activeLayout.frame.border.radius,
                     backgroundColor: activeLayout.frame.background.color,
                     backgroundImage:
                        activeLayout.frame.background.image != "none" &&
                        `url(${SERVER_URL}/uploads/${activeLayout.frame.background.image})`,
                     backgroundPosition: `${activeLayout.frame.background.topBottom} ${activeLayout.frame.background.leftRight}`,
                     backgroundRepeat: activeLayout.frame.background.repeat,
                     backgroundSize: activeLayout.frame.background.size
                        ? activeLayout.frame.background.size
                        : "auto",
                     [activeLayout.frame.position.xAlign]:
                        activeLayout.frame.position.xPos,
                     [activeLayout.frame.position.yAlign]:
                        activeLayout.frame.position.yPos,
                     fontFamily: activeLayout.global.style.fontFamily
                        ? activeLayout.global.style.fontFamily.split(".")[0]
                        : "unset",
                     fontSize: activeLayout.global.style.fontSize,
                     fontWeight: activeLayout.global.style.fontWeight,
                  }}
               >
                  <Box
                     sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        top: -activeLayout.frame.border.top,
                        left: -activeLayout.frame.border.left,
                     }}
                  >
                     {/* TITLE */}
                     <Container
                        previewScale={previewScale}
                        handleModal={handleModal}
                        handleUpdate={handleUpdate}
                        handleAdjust={handleAdjust}
                        edit={edit}
                        key='header'
                        name='header'
                        data={activeLayout.header}
                        zIndex={150}
                     >
                        {activeLayout.header.parameters.visible && (
                           <Box
                              sx={{
                                 position: "relative",
                                 display: "block",
                                 boxSizing: "border-box",
                                 height: "100%",
                                 width: "100%",
                                 textAlign: activeLayout.header.style.textAlign,
                                 fontFamily: activeLayout.header.style
                                    .fontFamily
                                    ? activeLayout.header.style.fontFamily.split(
                                         "."
                                      )[0]
                                    : "unset",
                                 fontSize: activeLayout.header.style.fontSize,
                                 fontWeight:
                                    activeLayout.header.style.fontWeight,
                                 maxWidth: activeLayout.header.style.width
                                    ? activeLayout.header.width
                                    : "auto",

                                 backgroundColor:
                                    activeLayout.header.background.color,
                                 backgroundImage:
                                    activeLayout.header.background.image !=
                                       "none" &&
                                    `url(${SERVER_URL}/uploads/${activeLayout.header.background.image})`,
                                 backgroundPosition: `${activeLayout.header.background.topBottom} ${activeLayout.header.background.leftRight}`,
                                 backgroundRepeat:
                                    activeLayout.header.background.repeat,
                                 backgroundSize: activeLayout.header.background
                                    .size
                                    ? activeLayout.header.background.size
                                    : "auto",

                                 borderTopWidth: activeLayout.header.border.top,
                                 borderLeftWidth:
                                    activeLayout.header.border.left,
                                 borderRightWidth:
                                    activeLayout.header.border.right,
                                 borderBottomWidth:
                                    activeLayout.header.border.bottom,
                                 borderTopColor:
                                    activeLayout.header.border.color,
                                 borderLeftColor:
                                    activeLayout.header.border.color,
                                 borderRightColor:
                                    activeLayout.header.border.color,
                                 borderBottomColor:
                                    activeLayout.header.border.color,
                                 borderStyle: activeLayout.header.border.style,
                                 borderRadius:
                                    activeLayout.header.border.radius,
                              }}
                           >
                              <Box
                                 sx={{
                                    width: "100%",
                                    height: "auto",
                                    position: "relative",
                                    display: "block",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    pt: activeLayout.header.style.py,
                                    px: activeLayout.header.style.px,
                                    color: activeLayout.header.style.color,
                                    WebkitTextStroke:
                                       activeLayout.header.style
                                          .textBorderWidth +
                                       "px " +
                                       activeLayout.header.style
                                          .textBorderColor,
                                    boxSizing: "border-box",
                                    zIndex: 2,
                                 }}
                              >
                                 {activePoll.title &&
                                    activeLayout.header.parameters.visible && (
                                       <>{activePoll.title}</>
                                    )}
                                 {!activePoll.title &&
                                    activeLayout.header.parameters.visible && (
                                       <>Poll</>
                                    )}
                                 {!activeLayout.header.parameters.visible && (
                                    <> </>
                                 )}
                              </Box>

                              {/* TITLE DROP SHADOW */}
                              {activeLayout.header.dropshadow.enable && (
                                 <Box
                                    sx={{
                                       width: "100%",
                                       height: "auto",
                                       position: "absolute",
                                       left:
                                          activeLayout.header.dropshadow.xPos +
                                          "px",
                                       top:
                                          "calc(53% + " +
                                          activeLayout.header.dropshadow.yPos +
                                          "px)",
                                       transform: "translateY(-50%)",
                                       pt: activeLayout.header.style.py,
                                       px: activeLayout.header.style.px,
                                       display: "block",
                                       maxWidth: activeLayout.header.style.width
                                          ? activeLayout.header.style.width
                                          : "auto",
                                       color: activeLayout.header.dropshadow
                                          .color,

                                       WebkitTextStroke:
                                          activeLayout.header.dropshadow
                                             .borderWidth +
                                          "px " +
                                          activeLayout.header.dropshadow
                                             .borderColor,
                                       zIndex: 1,
                                       boxSizing: "border-box",
                                    }}
                                 >
                                    {activePoll.title &&
                                       activeLayout.header.parameters
                                          .visible && <>{activePoll.title}</>}
                                    {!activePoll.title &&
                                       activeLayout.header.parameters
                                          .visible && <>Poll</>}
                                    {!activeLayout.header.parameters
                                       .visible && <> </>}
                                 </Box>
                              )}
                           </Box>
                        )}
                     </Container>

                     {/* CONTAINER */}
                     <Container
                        previewScale={previewScale}
                        handleModal={handleModal}
                        handleUpdate={handleUpdate}
                        handleAdjust={handleAdjust}
                        edit={edit}
                        key='container'
                        name='container'
                        data={activeLayout.container}
                        zIndex={50}
                     >
                        <Box
                           className='container'
                           sx={{
                              position: "absolute",

                              borderTopWidth: activeLayout.container.border.top,
                              borderLeftWidth:
                                 activeLayout.container.border.left,
                              borderRightWidth:
                                 activeLayout.container.border.right,
                              borderBottomWidth:
                                 activeLayout.container.border.bottom,
                              borderTopColor:
                                 activeLayout.container.border.color,
                              borderLeftColor:
                                 activeLayout.container.border.color,
                              borderRightColor:
                                 activeLayout.container.border.color,
                              borderBottomColor:
                                 activeLayout.container.border.color,
                              borderStyle: activeLayout.container.border.style,
                              borderRadius:
                                 activeLayout.container.border.radius,
                              backgroundColor:
                                 activeLayout.container.background.color,
                              backgroundImage:
                                 activeLayout.container.background.image !=
                                    "none" &&
                                 `url(${SERVER_URL}/uploads/${activeLayout.container.background.image})`,
                              backgroundPosition: `${activeLayout.container.background.topBottom} ${activeLayout.container.background.leftRight}`,
                              backgroundRepeat:
                                 activeLayout.container.background.repeat,
                              backgroundSize: activeLayout.container.background
                                 .size
                                 ? activeLayout.container.background.size
                                 : "auto",

                              boxSizing: "border-box",
                              width: "100%",
                              height: "100%",
                           }}
                        >
                           {activePoll && (
                              <>
                                 <Grid
                                    className='viewerTable'
                                    container
                                    direction={
                                       activeLayout.graph.parameters.direction
                                    }
                                    justifyContent={
                                       activeLayout.graph.parameters.columns
                                          ? "flex-start"
                                          : "center"
                                    }
                                    alignItems='flex-start'
                                    key={"activevertical-" + activePoll.id}
                                    sx={{
                                       zIndex: 100,
                                       height: "100%",
                                    }}
                                 >
                                    <Grid
                                       item
                                       xs={12}
                                       className='graphResults'
                                       sx={{
                                          height: "100%",
                                          width: "100%",
                                       }}
                                    >
                                       <motion.div
                                          key={"graphResults-child"}
                                          style={{
                                             position: "relative",
                                             width: "100%",
                                             left: 0,
                                             top: 0,
                                             height: "100%",
                                          }}
                                       >
                                          <Grid
                                             container
                                             direction='row'
                                             justifyContent='flex-start'
                                             alignItems='center'
                                             sx={{
                                                height: "100%",
                                             }}
                                          >
                                             {/* POLL RESULTS */}
                                             {Object.entries(
                                                activeResults
                                                   ? activeResults
                                                   : activePoll.results
                                             )
                                                .slice(
                                                   0,
                                                   parseInt(
                                                      activeLayout.graph
                                                         .parameters.maxItems
                                                         ? activeLayout.graph
                                                              .parameters
                                                              .maxItems
                                                         : Math.max(
                                                              Object.keys(
                                                                 activePoll.results
                                                              ).length,
                                                              activeLayout.graph
                                                                 .parameters
                                                                 .maxItems
                                                           )
                                                   )
                                                )
                                                .sort()
                                                .map((result, i) => (
                                                   <Grid
                                                      item
                                                      className='graphItem'
                                                      sx={{
                                                         display: "block",
                                                         position: "relative",
                                                         px: activeLayout.graph
                                                            .style.px,
                                                         py: activeLayout.graph
                                                            .style.py,
                                                         height:
                                                            activeLayout.graph
                                                               .parameters
                                                               .direction ===
                                                            "column"
                                                               ? activeLayout
                                                                    .graph
                                                                    .parameters
                                                                    .maxItems
                                                                  ? 100 /
                                                                       Math.min(
                                                                          activeLayout
                                                                             .graph
                                                                             .parameters
                                                                             .maxItems,
                                                                          Object.keys(
                                                                             activePoll.results
                                                                          )
                                                                             .length
                                                                       ) +
                                                                    "%"
                                                                  : 100 /
                                                                       Object.keys(
                                                                          activePoll.results
                                                                       )
                                                                          .length +
                                                                    "%"
                                                               : "100%",
                                                         width:
                                                            activeLayout.graph
                                                               .parameters
                                                               .direction ===
                                                            "row"
                                                               ? activeLayout
                                                                    .graph
                                                                    .parameters
                                                                    .maxItems
                                                                  ? 100 /
                                                                       Math.min(
                                                                          activeLayout
                                                                             .graph
                                                                             .parameters
                                                                             .maxItems,
                                                                          Object.keys(
                                                                             activePoll.results
                                                                          )
                                                                             .length
                                                                       ) +
                                                                    "%"
                                                                  : 100 /
                                                                       Object.keys(
                                                                          activePoll.results
                                                                       )
                                                                          .length +
                                                                    "%"
                                                               : "100%",
                                                      }}
                                                      key={"layoutResults" + i}
                                                   >
                                                      <Grid
                                                         container
                                                         direction='row'
                                                         justifyContent='flex-end'
                                                         alignItems='center'
                                                         key={
                                                            "activevertical-" +
                                                            activePoll.id
                                                         }
                                                         sx={{
                                                            height: "100%",
                                                         }}
                                                      >
                                                         {/* GRAPH ITEM CONTAINER */}
                                                         <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                               height: "100%",
                                                            }}
                                                         >
                                                            <Grid
                                                               container
                                                               direction='row'
                                                               justifyContent={
                                                                  activeLayout
                                                                     .graph
                                                                     .parameters
                                                                     .showTitle
                                                                     ? "right"
                                                                     : "center"
                                                               }
                                                               alignItems='center'
                                                               className='itemContainer'
                                                               sx={{
                                                                  height:
                                                                     "100%",
                                                               }}
                                                            >
                                                               {/* GRAPH ITEM TITLE */}
                                                               {activeLayout
                                                                  .graph
                                                                  .parameters
                                                                  .showTitle && (
                                                                  <Box
                                                                     sx={{
                                                                        zIndex: 100,
                                                                        position:
                                                                           "absolute",
                                                                        left: "50%",
                                                                        top: 0,
                                                                        transform:
                                                                           "translateX(-50%)",
                                                                        width: "100%",
                                                                        height:
                                                                           "100%",
                                                                        maxWidth:
                                                                           activeLayout
                                                                              .graph
                                                                              .style
                                                                              .titleMaxWidthPct +
                                                                           "%",
                                                                     }}
                                                                  >
                                                                     <Typography
                                                                        variant='h4'
                                                                        component='div'
                                                                        className='outline'
                                                                        key={
                                                                           "scrollingGraphBarTitle-" +
                                                                           activeLayout
                                                                              .graph
                                                                              .style
                                                                              .titleWidth +
                                                                           "-" +
                                                                           activeLayout
                                                                              .graph
                                                                              .style
                                                                              .titleFontSize +
                                                                           "-" +
                                                                           activeLayout
                                                                              .graph
                                                                              .dropshadow
                                                                              .enable +
                                                                           "-" +
                                                                           activeLayout
                                                                              .container
                                                                              .position
                                                                              .width +
                                                                           "-" +
                                                                           activeLayout
                                                                              .container
                                                                              .position
                                                                              .height +
                                                                           "-" +
                                                                           activeLayout
                                                                              .graph
                                                                              .parameters
                                                                              .showLetter +
                                                                           "-" +
                                                                           activeLayout
                                                                              .graph
                                                                              .parameters
                                                                              .maxItems
                                                                        }
                                                                        sx={{
                                                                           textAlign:
                                                                              activeLayout
                                                                                 .graph
                                                                                 .style
                                                                                 .textAlign,
                                                                           color: activeLayout
                                                                              .graph
                                                                              .style
                                                                              .titleColor,
                                                                           fontFamily:
                                                                              activeLayout
                                                                                 .graph
                                                                                 .style
                                                                                 .titleFontFamily
                                                                                 ? activeLayout.graph.style.titleFontFamily.split(
                                                                                      "."
                                                                                   )[0]
                                                                                 : "unset",
                                                                           fontSize:
                                                                              activeLayout
                                                                                 .graph
                                                                                 .style
                                                                                 .titleFontSize,
                                                                           fontWeight:
                                                                              activeLayout
                                                                                 .graph
                                                                                 .style
                                                                                 .titleFontWeight
                                                                                 ? activeLayout
                                                                                      .graph
                                                                                      .style
                                                                                      .titleFontWeight
                                                                                 : "normal",
                                                                           whiteSpace:
                                                                              "nowrap",
                                                                           position:
                                                                              "absolute",
                                                                           zIndex: 100,
                                                                           width: activeLayout
                                                                              .graph
                                                                              .style
                                                                              .titleWidth
                                                                              ? activeLayout
                                                                                   .graph
                                                                                   .style
                                                                                   .titleWidth +
                                                                                "%"
                                                                              : "100%",
                                                                           height:
                                                                              "auto",
                                                                           left: activeLayout
                                                                              .graph
                                                                              .style
                                                                              .titleX
                                                                              ? 50 +
                                                                                activeLayout
                                                                                   .graph
                                                                                   .style
                                                                                   .titleX +
                                                                                "%"
                                                                              : "50%",
                                                                           top: activeLayout
                                                                              .graph
                                                                              .style
                                                                              .titleY
                                                                              ? 50 +
                                                                                activeLayout
                                                                                   .graph
                                                                                   .style
                                                                                   .titleY +
                                                                                "%"
                                                                              : "50%",
                                                                           transform:
                                                                              "translateX(-50%) translateY(-50%)",
                                                                           WebkitTextStroke: `${activeLayout.graph.style.titleStroke}px ${activeLayout.graph.style.titleStrokeColor}`,
                                                                           textAlign:
                                                                              "center",
                                                                        }}
                                                                     >
                                                                        {activeLayout
                                                                           .graph
                                                                           .parameters
                                                                           .scrollLongText && (
                                                                           <TextScroller
                                                                              titleY={
                                                                                 activeLayout
                                                                                    .graph
                                                                                    .style
                                                                                    .titleY
                                                                              }
                                                                              dropshadow={
                                                                                 activeLayout
                                                                                    .graph
                                                                                    .dropshadow
                                                                              }
                                                                              showLetter={
                                                                                 !activeLayout
                                                                                    .graph
                                                                                    .parameters
                                                                                    .showLetter
                                                                                    ? result[0] +
                                                                                      ": "
                                                                                    : ""
                                                                              }
                                                                              text={
                                                                                 result[1]
                                                                                    .answer
                                                                              }
                                                                              key={
                                                                                 result[0] +
                                                                                 result[1]
                                                                                    .answer +
                                                                                 activePoll.layout +
                                                                                 "-" +
                                                                                 activeLayout
                                                                                    .container
                                                                                    .position
                                                                                    .width +
                                                                                 "-" +
                                                                                 activeLayout
                                                                                    .container
                                                                                    .position
                                                                                    .height +
                                                                                 "-" +
                                                                                 activeLayout
                                                                                    .graph
                                                                                    .parameters
                                                                                    .maxItems
                                                                              }
                                                                              pr={
                                                                                 0
                                                                              }
                                                                           />
                                                                        )}
                                                                        {!activeLayout
                                                                           .graph
                                                                           .parameters
                                                                           .scrollLongText && (
                                                                           <TextSquisher
                                                                              titleY={
                                                                                 activeLayout
                                                                                    .graph
                                                                                    .style
                                                                                    .titleY
                                                                              }
                                                                              dropshadow={
                                                                                 activeLayout
                                                                                    .graph
                                                                                    .dropshadow
                                                                              }
                                                                              showLetter={
                                                                                 !activeLayout
                                                                                    .graph
                                                                                    .parameters
                                                                                    .showLetter
                                                                                    ? result[0] +
                                                                                      ": "
                                                                                    : ""
                                                                              }
                                                                              text={
                                                                                 result[1]
                                                                                    .answer
                                                                              }
                                                                              key={
                                                                                 result[0] +
                                                                                 result[1]
                                                                                    .answer +
                                                                                 activePoll.layout +
                                                                                 "-" +
                                                                                 activeLayout
                                                                                    .container
                                                                                    .position
                                                                                    .width +
                                                                                 "-" +
                                                                                 activeLayout
                                                                                    .container
                                                                                    .position
                                                                                    .height +
                                                                                 "-" +
                                                                                 activeLayout
                                                                                    .graph
                                                                                    .parameters
                                                                                    .maxItems
                                                                              }
                                                                              pr={
                                                                                 0
                                                                              }
                                                                           />
                                                                        )}
                                                                     </Typography>
                                                                  </Box>
                                                               )}
                                                               {/* GRAPH BAR */}
                                                               <Grid
                                                                  item
                                                                  sx={{
                                                                     position:
                                                                        "relative",
                                                                     width: "100%",
                                                                     height:
                                                                        "100%",
                                                                     boxSizing:
                                                                        "border-box",
                                                                     py: activeLayout
                                                                        .graphBars
                                                                        .style
                                                                        .py,
                                                                     px: activeLayout
                                                                        .graphBars
                                                                        .style
                                                                        .px,
                                                                     borderTopWidth:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .top,
                                                                     borderLeftWidth:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .left,
                                                                     borderRightWidth:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .right,
                                                                     borderBottomWidth:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .bottom,
                                                                     borderTopColor:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .color,
                                                                     borderLeftColor:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .color,
                                                                     borderRightColor:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .color,
                                                                     borderBottomColor:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .color,
                                                                     borderStyle:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .style,
                                                                     borderRadius:
                                                                        activeLayout
                                                                           .graph
                                                                           .border
                                                                           .radius,
                                                                     backgroundColor:
                                                                        activeLayout
                                                                           .graph
                                                                           .background
                                                                           .color,
                                                                     backgroundImage:
                                                                        activeLayout
                                                                           .graph
                                                                           .background
                                                                           .image !=
                                                                           "none" &&
                                                                        `url(${SERVER_URL}/uploads/${activeLayout.graph.background.image})`,
                                                                     backgroundPosition: `${activeLayout.graph.background.topBottom} ${activeLayout.graph.background.leftRight}`,
                                                                     backgroundRepeat:
                                                                        activeLayout
                                                                           .graph
                                                                           .background
                                                                           .repeat,
                                                                     backgroundSize:
                                                                        activeLayout
                                                                           .graph
                                                                           .background
                                                                           .size
                                                                           ? activeLayout
                                                                                .graph
                                                                                .background
                                                                                .size
                                                                           : "auto",
                                                                     fontFamily:
                                                                        activeLayout
                                                                           .graph
                                                                           .style
                                                                           .fontFamily
                                                                           ? activeLayout.graph.style.fontFamily.split(
                                                                                "."
                                                                             )[0]
                                                                           : "unset",
                                                                     fontSize:
                                                                        activeLayout
                                                                           .graph
                                                                           .style
                                                                           .size,
                                                                     boxSizing:
                                                                        "border-box",
                                                                     overflow:
                                                                        "visible",
                                                                  }}
                                                               >
                                                                  <GraphBar
                                                                     key={
                                                                        "active-" +
                                                                        activePoll.id +
                                                                        "bar" +
                                                                        i
                                                                     }
                                                                     orientation={
                                                                        activeLayout
                                                                           .graph
                                                                           .parameters
                                                                           .orientation
                                                                     }
                                                                     pct={
                                                                        activeResultTotal
                                                                           ? Math.floor(
                                                                                (result[1]
                                                                                   .qty /
                                                                                   activeResultTotal) *
                                                                                   100
                                                                             )
                                                                           : edit
                                                                           ? Math.floor(
                                                                                (result[1]
                                                                                   .qty /
                                                                                   activePoll.totalResults) *
                                                                                   100
                                                                             )
                                                                           : 0
                                                                     }
                                                                     optionText={
                                                                        activeLayout
                                                                           .graph
                                                                           .parameters
                                                                           .showLetter
                                                                           ? result[0]
                                                                           : ""
                                                                     }
                                                                     color={
                                                                        activeLayout
                                                                           .graph
                                                                           .style
                                                                           .color
                                                                     }
                                                                     barColor={
                                                                        activeLayout
                                                                           .graphBars
                                                                           .background
                                                                           ? activeLayout
                                                                                .graphBars
                                                                                .background
                                                                                .color
                                                                           : null
                                                                     }
                                                                     barStyles={
                                                                        activeLayout
                                                                           .graphBars
                                                                           .style
                                                                           ? activeLayout
                                                                                .graphBars
                                                                                .style
                                                                           : null
                                                                     }
                                                                     barBackground={
                                                                        activeLayout
                                                                           .graphBars
                                                                           .background
                                                                           ? activeLayout
                                                                                .graphBars
                                                                                .background
                                                                           : null
                                                                     }
                                                                     barBorders={
                                                                        activeLayout
                                                                           .graphBars
                                                                           .border
                                                                           ? activeLayout
                                                                                .graphBars
                                                                                .border
                                                                           : null
                                                                     }
                                                                     barCustomColors={
                                                                        activeLayout
                                                                           .graphBars
                                                                           .parameters
                                                                           .customColorsActive
                                                                           ? activeLayout
                                                                                .graphBars
                                                                                .customColors
                                                                           : null
                                                                     }
                                                                     barMinWidth={
                                                                        100
                                                                     }
                                                                     barId={i}
                                                                     pollId={
                                                                        activePoll.id
                                                                     }
                                                                     showPct={
                                                                        activeLayout
                                                                           .graph
                                                                           .parameters
                                                                           .showPct
                                                                     }
                                                                     showLetter={
                                                                        activeLayout
                                                                           .graph
                                                                           .parameters
                                                                           .showLetter
                                                                     }
                                                                     font={
                                                                        activeLayout
                                                                           .graph
                                                                           .style
                                                                           .fontFamily
                                                                           ? activeLayout.graph.style.fontFamily.split(
                                                                                "."
                                                                             )[0]
                                                                           : "unset"
                                                                     }
                                                                  />
                                                               </Grid>
                                                            </Grid>
                                                         </Grid>
                                                      </Grid>
                                                   </Grid>
                                                ))}
                                          </Grid>
                                       </motion.div>
                                    </Grid>
                                 </Grid>
                              </>
                           )}
                        </Box>
                     </Container>
                  </Box>
               </Box>
            </div>
         )}
      </>
   );
}
