import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import BorderTopIcon from "@mui/icons-material/BorderTop";
import BorderRightIcon from "@mui/icons-material/BorderRight";
import BorderBottomIcon from "@mui/icons-material/BorderBottom";
import BorderLeftIcon from "@mui/icons-material/BorderLeft";

// COMPONENTS
import ColorPicker from "../ColorPicker";
import ImageUploader from "../ImageUploader";
import InputSlider from "./InputSlider";
import SelectMenus from "./SelectMenus";

export default function TugofWar({
   init,
   parentKey,
   fieldKey,
   layoutId,
   activePanel,
   callback,
}) {
   const [data, setData] = React.useState(init);
   const [presetColors, setPresetColors] = useState([]);

   const handleFileChange = (id, section, field, value) => {
      const newData = {
         ...init,
         [section]: { ...init[section], [field]: value },
      };

      setData(newData);

      {
         /* callback(parentKey, fieldKey, newData); */
      }
   };

   const handleNumberSliderChange = (objectKey, property, value) => {
      const newData = {
         ...init,
         [objectKey]: { ...init[objectKey], [property]: value },
      };

      setData(newData);

      {
         /* callback(parentKey, "tugofwar", newData); */
      }
   };

   const handleSelectChange = (parentKey, fieldKey, value) => {
      const newData = {
         ...init,
         [fieldKey]: value,
      };

      setData(newData);

      {
         /* callback(parentKey, "background", newData); */
      }
   };

   // COLOR PICKER
   const updatePresetColors = () => {
      let colors = ["#000", "#fff", "rgba(0,0,0,0)"];

      setPresetColors(colors);
   };

   const colorObj2rgba = (object) => {
      return `rgba(${object.r},${object.g},${object.b},${object.a})`;
   };

   const convertColor2Obj = (color) => {
      if (color.includes("#")) {
         return hexToRGB(color);
      } else {
         let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
         return { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
      }
   };

   const hexToRGB = (hex) => {
      var r = parseInt(hex.slice(1, 3), 16),
         g = parseInt(hex.slice(3, 5), 16),
         b = parseInt(hex.slice(5, 7), 16);
      return { r: r, g: g, b: b, a: 1 };
   };

   const rgba2hex = (color) => {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
         (1 << 24) +
         (parseInt(rgba[0]) << 16) +
         (parseInt(rgba[1]) << 8) +
         parseInt(rgba[2])
      )
         .toString(16)
         .slice(1)}`;

      return hex;
   };

   // USE EFFECT

   React.useEffect(() => {
      callback(parentKey, fieldKey, data);
   }, [data]);

   const handleColorChange = (value) => {
      const newData = {
         ...init,
         background: { ...init["background"], color: colorObj2rgba(value) },
      };

      setData(newData);
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{ textAlign: "center" }}
         >
            <Grid item xs={12}>
               <Grid
                  container
                  spacing={2}
                  alignItems='center'
                  justifyContent='center'
                  sx={{ textAlign: "center" }}
               >
                  <Grid item xs={12}>
                     <Box sx={{ textAlign: "left", fontWeight: "bold", my: 1 }}>
                        Tug of War Object:
                     </Box>
                  </Grid>
                  <Grid item xs={12}>
                     <InputSlider
                        fieldLabel='Y Offset'
                        init={init.background.yPos}
                        min={-400}
                        max={400}
                        parentKey={"background"}
                        fieldKey={"yPos"}
                        handleNumberSliderChange={handleNumberSliderChange}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Box sx={{ textAlign: "left", fontWeight: "bold", my: 1 }}>
                        ICON:
                     </Box>
                     <Box sx={{ position: "relative" }}>
                        <ImageUploader
                           layoutId={layoutId}
                           activePanel={activePanel}
                           section={"icon"}
                           field={"image"}
                           image={init.icon.image}
                           callback={handleFileChange}
                        />
                     </Box>
                     <InputSlider
                        fieldLabel='Icon Scale'
                        init={init.icon.scale}
                        step={0.01}
                        min={0.1}
                        max={2}
                        parentKey={"icon"}
                        fieldKey={"scale"}
                        handleNumberSliderChange={handleNumberSliderChange}
                     />
                     <InputSlider
                        fieldLabel='X Offset'
                        init={init.icon.xPos}
                        min={-400}
                        max={400}
                        parentKey={"icon"}
                        fieldKey={"xPos"}
                        handleNumberSliderChange={handleNumberSliderChange}
                     />
                     <InputSlider
                        fieldLabel='Y Offset'
                        init={init.icon.yPos}
                        min={-400}
                        max={400}
                        parentKey={"icon"}
                        fieldKey={"yPos"}
                        handleNumberSliderChange={handleNumberSliderChange}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Box sx={{ textAlign: "left", fontWeight: "bold", my: 1 }}>
                        OVERLAY:
                     </Box>
                     <Box sx={{ position: "relative" }}>
                        <ImageUploader
                           layoutId={layoutId}
                           activePanel={activePanel}
                           section={"overlay"}
                           field={"image"}
                           image={init.overlay.image}
                           callback={handleFileChange}
                        />
                     </Box>
                     <InputSlider
                        fieldLabel='X Offset'
                        init={init.overlay.xPos}
                        min={-400}
                        max={400}
                        parentKey={"overlay"}
                        fieldKey={"xPos"}
                        handleNumberSliderChange={handleNumberSliderChange}
                     />
                     <InputSlider
                        fieldLabel='Y Offset'
                        init={init.overlay.yPos}
                        min={-400}
                        max={400}
                        parentKey={"overlay"}
                        fieldKey={"yPos"}
                        handleNumberSliderChange={handleNumberSliderChange}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Box sx={{ textAlign: "left", fontWeight: "bold", my: 1 }}>
                        BACKGROUND:
                     </Box>
                     <Box sx={{ position: "relative" }}>
                        <ImageUploader
                           layoutId={layoutId}
                           activePanel={activePanel}
                           section={"background"}
                           field={"image"}
                           image={init.background.image}
                           callback={handleFileChange}
                        />
                     </Box>
                  </Grid>
                  <Grid item xs={12}>
                     <Box sx={{ position: "relative", textAlign: "left" }}>
                        <ColorPicker
                           color={convertColor2Obj(init.background.color)}
                           updateSwatches={updatePresetColors}
                           callback={handleColorChange}
                           presetColors={presetColors}
                           width={100}
                           height={30}
                        />
                     </Box>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </Box>
   );
}
