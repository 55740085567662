export const language = {
   background: "Background",
   backgroundColor: "Background Color",
   backgroundColorActive: "Active Background Color",
   backgroundImage: "Background Image",
   backgroundSize: "Background Size",
   bkgndLeftRight: "Background Align",
   backgroundRepeat: "Background Repeat",
   bkgndTopBottom: "Background Vertical Align",
   border: "Border",
   borderBottomWidth: "Border Bottom Width",
   borderColor: "Border Color",
   borderLeftWidth: "Border Left Width",
   borderRadius: "Border Radius",
   borderRightWidth: "Border Right Width",
   borderStyle: "Border Style",
   borderTopWidth: "Border Top Width",
   color: "Color",
   colorActive: "Active Color",
   customColorsActive: "Custom Graph Colors Active?",
   direction: "Orientation",
   dropshadow: "Drop Shadow",
   dropShadow: "Enable Drop Shadow",
   dropShadowBorderColor: "Drop Shadow Border Color",
   dropShadowBorderWidth: "Drop Shadow Border Width",
   dropShadowColor: "Drop Shadow Color",
   dropShadowPositionX: "Drop Shadow X",
   dropShadowPositionY: "Drop Shadow Y",
   dropShadowTitle: "Title Drop Shadow?",
   dropShadowTitleBorderColor: "Drop Shadow Title Border Color",
   dropShadowTitleBorderWidth: "Drop Shadow Title Border Width",
   dropShadowTitleColor: "Drop Shadow Title Color",
   dropShadowTitleX: "Title Drop Shadow X",
   dropShadowTitleY: "Title Drop Shadow Y",
   dropShadowX: "Drop Shadow X",
   dropShadowY: "Drop Shadow Y",
   fontColor: "Font Color",
   fontFamily: "Font Family",
   fontFamilyHeader: "Font Family",
   fontSize: "Font Size",
   fontSizeHeader: "Font Size",
   fontWeight: "Font Weight",
   fontWeightHeader: "Font Weight",
   headerRow: "Header Row",
   height: "Height",
   image: "Image",
   icon: "Tug of War Icon",
   layoutTitle: "Layout Title",
   leaderboard: "Leaderboard",
   letterColor: "Letter Color",
   letterFontSize: "Letter Font Size",
   letterLeftRight: "Letter Align",
   letterStroke: "Letter Stroke Width",
   letterStrokeColor: "Letter Stroke Color",
   letterTopBottom: "Letter Vertical Align",
   letterX: "Letter X (% Offset)",
   letterY: "Letter Y (% Offset)",
   lockAspectRatio: "Lock Aspect Ratio?",
   maxItems: "Maximum Poll Items",
   minHeight: "Minimum Height",
   minWidth: "Minimum Width",
   orientation: "Graph Bar Direction",
   paddingTop: "Padding Top (Pixel Offset)",
   pctAlign: "Percentage Align",
   pctTopBottom: "Percentage Vertical Align",
   pctColor: "Percentage Color",
   pctFontSize: "Percentage Font Size",
   pctStroke: "Percentage Font Stroke",
   pctStrokeColor: "Percentage Stroke Color",
   pctX: "Percentage X (% Offset)",
   pctY: "Percentage Y (% Offset)",
   px: "Padding Left/Right",
   py: "Padding Top/Bottom",
   scrollLongText: "Scroll Long Text?",
   showLetter: "Show Letter?",
   showPct: "Show Percentage?",
   showTitle: "Show Title?",
   tableRows: "Table Rows",
   textAlign: "Text Align",
   textBorderColor: "Text Border Color",
   textBorderWidth: "Text Border Width",
   titleColor: "Title Color",
   titleFontFamily: "Title Font Family",
   titleFontSize: "Title Font Size",
   titleFontWeight: "Title Font Weight",
   titleLeftRight: "Title Align",
   titleStroke: "Title Stroke",
   titleStrokeColor: "Title Stroke Color",
   titleTopBottom: "Title Vertical Align",
   titleWidth: "Title Width (%)",
   titleX: "Title X (% Offset)",
   titleY: "Title Y (% Offset)",
   tugofwar: "Tug of War",
   visible: "Visible",
   width: "Width",
   xAlign: "Left/Right Alignment",
   xPos: "X Position",
   yAlign: "Top/Bottom Alignment",
   yPos: "Y Position",
   bar1Color: "Bar 1 Color",
   bar2Color: "Bar 2 Color",
   bar3Color: "Bar 3 Color",
   bar4Color: "Bar 4 Color",
   bar5Color: "Bar 5 Color",
   bar6Color: "Bar 6 Color",
   bar7Color: "Bar 7 Color",
   bar8Color: "Bar 8 Color",
   bar9Color: "Bar 9 Color",
   bar10Color: "Bar 10 Color",
};
