import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { motion } from "framer-motion";

export default function WheelComponent(props) {
   // SOCKET
   const socket = useContext(SocketContext);

   const [rotateX, setRotateX] = useState();
   const [delay, setDelay] = useState(0);

   useEffect(() => {
      if (props.result >= 0) {
         setRotateX(-(props.result * (360 / props.items.length) + 360 * 3));
         setDelay(props.edit ? 1 : props.result >= 0 ? props.duration : 0);
      } else {
         setRotateX(0);
         setDelay(0);
      }
   }, [props.result]);

   const generateSides = () => {
      const radius = props.diameter / 2;
      const angle = 360 / props.items.length;
      const circumference = Math.PI * props.diameter;

      const chordLength = 2 * radius * Math.sin((angle * (Math.PI / 180)) / 2);
      const segmentHeight =
         radius * (1 - Math.cos((angle * (Math.PI / 180)) / 2));

      let sides = [];

      for (let i = 0; i < props.items.length; i++) {
         let transform = `rotateX(${angle * i}deg) translateZ(${
            radius - segmentHeight
         }px)`;
         sides.push({
            id: i,
            transform: transform,
            height: chordLength,
            value: props.items[i],
         });
      }

      return sides;
   };

   const wheel = {
      init: { rotateX: 0 },
      spin: rotateX
         ? {
              rotateX: [0, 10, rotateX],
              transition: {
                 ease: "easeInOut",
                 duration: props.edit ? 1 : props.duration,
                 times: [0, 0.1, 1],
                 delayChildren: delay,
              },
           }
         : {
              rotateX: 0,
              transition: {
                 ease: "easeInOut",
                 duration: 0,
                 times: [0, 0.1, 1],
                 delayChildren: delay,
              },
           },
   };

   const activePanel = {
      init: {
         color: props.styles.container.style.color,
         backgroundColor: props.styles.container.style.backgroundColor,
      },
      spin: (active) =>
         rotateX
            ? {
                 backgroundColor: active
                    ? props.styles.container.style.backgroundColorActive
                    : props.styles.container.style.backgroundColor,
                 color: active
                    ? props.styles.container.style.colorActive
                    : props.styles.container.style.color,
                 transition: {
                    ease: "easeInOut",
                    duration: 0.5,
                 },
              }
            : {
                 backgroundColor: props.styles.container.style.backgroundColor,
                 transition: {
                    ease: "easeInOut",
                    duration: 0.5,
                 },
              },
   };

   return (
      <>
         <div
            className='wheeloverlay'
            style={{
               width: props.width,
               height: props.diameter,
               pointerEvents: "none",
            }}
         />
         <div className='wheel'>
            <motion.div
               className='wheel__inner'
               style={{
                  width: props.width,
                  height: props.diameter,
                  top:
                     -(Math.PI * props.diameter) / props.items.length / 2 +
                     "px",
                  transformOrigin:
                     "50% calc(50% + " +
                     (Math.PI * props.diameter) / props.items.length / 2 +
                     "px)",
               }}
               variants={wheel}
               initial='init'
               animate='spin'
               key={JSON.stringify(props.styles.container.style)}
            >
               {generateSides().map((side) => (
                  <motion.div
                     key={"wheelSide" + side.id}
                     className='wheel__segment'
                     style={{
                        transform: side.transform,
                        height: side.height,
                     }}
                     custom={side.id === props.result ? true : false}
                     variants={activePanel}
                  >
                     <span>{side.value}</span>
                  </motion.div>
               ))}
            </motion.div>
         </div>
      </>
   );
}
