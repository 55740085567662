import { useState, useEffect } from "react";
import _ from "lodash";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function List(props) {
   const name = "list";

   const [id, setId] = useState();
   const [numOptions, setNumOptions] = useState(4);
   const [title, setTitle] = useState("");
   const [answers, setAnswers] = useState({});
   const [layout, setLayout] = useState("");

   useEffect(() => {
      if (props.edit) {
         setId(props.wheel.id);
         setTitle(props.wheel.title);
         setAnswers(props.wheel.answers);
         setLayout(props.wheel.layout);
         setNumOptions(_.size(props.wheel.answers));
      }
   }, [props.edit]);

   const handleLayoutChange = (event) => {
      setLayout(event.target.value);
   };

   const updateTitle = (e) => {
      setTitle(e.target.value);
   };

   const updateAnswers = (e) => {
      setAnswers((prevState) => {
         let newState = { ...prevState };

         let name = e.target.getAttribute("name");
         let value = e.target.value;

         if (value) {
            newState[name] = value;
         } else {
            delete newState[name];
         }

         return newState;
      });
   };

   const addOption = () => {
      setNumOptions((prevState) => {
         let newNumOptions = prevState + 1;
         if (newNumOptions <= 30) {
            return newNumOptions;
         } else {
            return prevState;
         }
      });
   };

   const deleteOption = (key) => {
      setAnswers((prevState) => {
         let newState = { ...prevState };

         if (Object.keys(newState).length > 1) {
            delete newState[key];
            let updatedObject = {};
            Object.entries(newState).forEach(([key, value], i) => {
               updatedObject[i] = value;
            });
            setNumOptions(Object.keys(updatedObject).length);
            return updatedObject;
         } else {
            return newState;
         }
      });
   };

   return (
      <>
         <TextField
            key={`inputTitle`}
            label='Title'
            name='title'
            value={title}
            id='filled-size-small'
            variant='outlined'
            size='small'
            onChange={updateTitle}
            sx={{ mb: 4 }}
            fullWidth
         />
         <br />
         <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id={"addPollLayouts"}>Layout</InputLabel>
            <Select
               labelId={"layoutMenu"}
               value={layout}
               label='Layout'
               onChange={handleLayoutChange}
            >
               {props.layouts &&
                  Object.keys(props.layouts).map((layout) => (
                     <MenuItem key={"pollLayout-" + layout} value={layout}>
                        {props.layouts[layout].global.parameters.layoutTitle}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>

         {/* <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id={"addPollLayouts"}>Layout</InputLabel>
            <Select
               labelId={"layoutMenu"}
               value={layout}
               label='Layout'
               onChange={handleLayoutChange}
            >
               {props.layouts &&
                  Object.keys(props.layouts).map((layout) => (
                     <MenuItem key={"pollLayout-" + layout} value={layout}>
                        {props.layouts[layout].global.parameters.layoutTitle}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl> */}

         {[...Array(numOptions)].map((value, index) => (
            <TextField
               key={`inputKey${index}`}
               label={"Option " + (index + 1)}
               name={index}
               id='filled-size-small'
               value={answers[index] ? answers[index] : ""}
               variant='outlined'
               size='small'
               onChange={updateAnswers}
               sx={{ mb: 1.5 }}
               fullWidth
               InputProps={{
                  endAdornment: (
                     <InputAdornment position='end'>
                        <IconButton
                           edge='end'
                           color='primary'
                           onClick={() => deleteOption(index)}
                        >
                           <DeleteIcon />
                        </IconButton>
                     </InputAdornment>
                  ),
               }}
            />
         ))}
         <Button onClick={addOption} sx={{ mb: 4 }}>
            + Add Option
         </Button>
         <br />

         {!props.edit && (
            <Button
               variant='contained'
               disabled={
                  title && layout && Object.keys(answers).length === numOptions
                     ? false
                     : true
               }
               onClick={() => {
                  props.stageWheel({
                     id: id,
                     layout: layout,
                     title: title,
                     answers: answers,
                  });
                  setNumOptions(4);
                  setTitle("");
                  setAnswers({});
                  props.closeModal({});
               }}
            >
               SAVE
            </Button>
         )}

         {props.edit && (
            <Button
               variant='contained'
               sx={{ mb: 4 }}
               disabled={
                  Object.keys(answers).length === numOptions ? false : true
               }
               onClick={() => {
                  props.updateWheel({
                     id: id,
                     type: name,
                     title: title,
                     answers: answers,
                     layout: layout,
                  });
                  setNumOptions(2);
                  setLayout("");
                  setTitle("");
                  setAnswers({});
                  props.closeModal({});
               }}
            >
               Update
            </Button>
         )}
      </>
   );
}

export default List;
