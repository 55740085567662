import { useState } from "react";

import DurationComponent from "../components/DurationComponent";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function TierList(props) {
   const name = "tierlist";
   const [duration, setDuration] = useState();

   return (
      <Accordion>
         <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-${name}-content`}
            id={`panel-${name}-header`}
         >
            <Typography>Tierlist</Typography>
         </AccordionSummary>
         <AccordionDetails>
            <DurationComponent
               name={name}
               callback={setDuration}
               key={"tierlistDuration-" + props.lastUpdated}
            />

            <Button
               variant='contained'
               disabled={duration ? false : true}
               onClick={() =>
                  props.stagePoll({
                     type: name,
                     duration: duration,
                     answers: {
                        S: "s",
                        A: "a",
                        B: "b",
                        C: "c",
                        D: "d",
                        F: "f",
                     },
                  })
               }
            >
               Stage Poll
            </Button>
         </AccordionDetails>
      </Accordion>
   );
}

export default TierList;
