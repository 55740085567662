import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Checkbox from "@mui/material/Checkbox";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import BorderTopIcon from "@mui/icons-material/BorderTop";
import BorderRightIcon from "@mui/icons-material/BorderRight";
import BorderBottomIcon from "@mui/icons-material/BorderBottom";
import BorderLeftIcon from "@mui/icons-material/BorderLeft";

// COMPONENTS
import InputSlider from "./InputSlider";
import ColorPicker from "../ColorPicker";
import RowWrapper from "../RowWrapper";
import PropWrapper from "../PropWrapper";
import SelectMenus from "./SelectMenus";
import FontMenu from "../FontMenu";

export default function Leaderboard({
   init,
   layoutId,
   activepanel,
   fonts,
   parentKey,
   fieldKey,
   callback,
}) {
   const [data, setData] = React.useState(init);
   const [presetColors, setPresetColors] = useState([]);

   // COLOR PICKER
   const updatePresetColors = () => {
      let colors = ["#000", "#fff"];

      setPresetColors(
         colors.length != "undefined" ? colors : ["#ff0000", "#ccc"]
      );
   };

   const setColor = (parentKey, fieldKey, obj) => {
      const value = colorObj2rgba(obj);

      const newData = {
         ...data,
         [fieldKey]: value,
      };

      setData(newData);
      callback(parentKey, "leaderboard", newData);
   };

   const colorObj2rgba = (object) => {
      return `rgba(${object.r},${object.g},${object.b},${object.a})`;
   };

   const convertColor2Obj = (color) => {
      if (color.includes("#")) {
         return hexToRGB(color);
      } else {
         let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
         return { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
      }
   };

   const hexToRGB = (hex) => {
      var r = parseInt(hex.slice(1, 3), 16),
         g = parseInt(hex.slice(3, 5), 16),
         b = parseInt(hex.slice(5, 7), 16);
      return { r: r, g: g, b: b, a: 1 };
   };

   const rgba2hex = (color) => {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
         (1 << 24) +
         (parseInt(rgba[0]) << 16) +
         (parseInt(rgba[1]) << 8) +
         parseInt(rgba[2])
      )
         .toString(16)
         .slice(1)}`;

      return hex;
   };

   // USE EFFECT

   React.useEffect(() => {
      callback(parentKey, fieldKey, data);
   }, [data]);

   const handleFileChange = (id, parentKey, fieldKey, value) => {
      const newData = {
         ...data,
         [fieldKey]: value,
      };

      setData(newData);
      callback(parentKey, "leaderboard", newData);
   };

   const handleSelectChange = (parentKey, fieldKey, value) => {
      const newData = {
         ...data,
         [fieldKey]: value,
      };

      setData(newData);
      callback(parentKey, "leaderboard", newData);
   };

   const handleNumberSliderChange = (parentKey, fieldKey, value) => {
      const newData = {
         ...data,
         [fieldKey]: value,
      };

      setData(newData);
      callback(parentKey, "leaderboard", newData);
   };

   const handleCheckboxChange = (e) => {
      const fieldKey = e.target.getAttribute("fieldKey");
      const value = e.target.checked;

      const newData = {
         ...data,
         [fieldKey]: value,
      };

      setData(newData);
      callback(parentKey, "leaderboard", newData);
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{ textAlign: "center", pt: 1 }}
         >
            <Grid item xs={12}>
               <RowWrapper label={"headerRow"}>
                  <PropWrapper label={"fontFamilyHeader"}>
                     <Box sx={{ position: "relative" }}>
                        <FontMenu
                           layoutId={layoutId}
                           activePanel={activepanel}
                           section={parentKey}
                           field={"fontFamilyHeader"}
                           font={data.fontFamilyHeader}
                           fonts={fonts}
                           callback={handleFileChange}
                        />
                     </Box>
                  </PropWrapper>

                  <PropWrapper label={"fontSizeHeader"}>
                     <InputSlider
                        init={data.fontSizeHeader}
                        min={10}
                        max={200}
                        parentKey={parentKey}
                        fieldKey={"fontSizeHeader"}
                        handleNumberSliderChange={handleNumberSliderChange}
                     />
                  </PropWrapper>

                  <PropWrapper label={"fontWeightHeader"}>
                     <SelectMenus
                        init={data.fontWeightHeader}
                        parentKey={parentKey}
                        fieldKey={"fontWeightHeader"}
                        handleSelectChange={handleSelectChange}
                     />
                  </PropWrapper>

                  <Box sx={{ position: "relative", mt: 5 }}>
                     <ColorPicker
                        color={convertColor2Obj(data.colorHeader)}
                        updateSwatches={updatePresetColors}
                        callback={(e) =>
                           setColor(
                              parentKey ? parentKey : "",
                              "colorHeader",
                              e
                           )
                        }
                        presetColors={presetColors}
                        width={100}
                        height={30}
                     />
                  </Box>
               </RowWrapper>
            </Grid>

            <Grid item xs={12}>
               <RowWrapper label={"tableRows"}>
                  <PropWrapper label={"fontFamily"}>
                     <Box sx={{ position: "relative" }}>
                        <FontMenu
                           layoutId={layoutId}
                           activePanel={activepanel}
                           section={parentKey}
                           field={"fontFamily"}
                           font={data.fontFamily}
                           fonts={fonts}
                           callback={handleFileChange}
                        />
                     </Box>
                  </PropWrapper>

                  <PropWrapper label={"fontSize"}>
                     <InputSlider
                        init={data.fontSize}
                        min={10}
                        max={200}
                        parentKey={parentKey}
                        fieldKey={"fontSize"}
                        handleNumberSliderChange={handleNumberSliderChange}
                     />
                  </PropWrapper>

                  <PropWrapper label={"fontWeight"}>
                     <SelectMenus
                        init={data.fontWeight}
                        parentKey={parentKey}
                        fieldKey={"fontWeight"}
                        handleSelectChange={handleSelectChange}
                     />
                  </PropWrapper>

                  <Box sx={{ position: "relative", mt: 5 }}>
                     <ColorPicker
                        color={convertColor2Obj(data.color)}
                        updateSwatches={updatePresetColors}
                        callback={(e) =>
                           setColor(parentKey ? parentKey : "", "color", e)
                        }
                        presetColors={presetColors}
                        width={100}
                        height={30}
                     />
                  </Box>
               </RowWrapper>
            </Grid>
            <Grid item xs={12}>
               <RowWrapper label={"py"}>
                  <InputSlider
                     init={data.py}
                     step={0.1}
                     min={0}
                     max={50}
                     parentKey={parentKey}
                     fieldKey={"py"}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            </Grid>
         </Grid>
      </Box>
   );
}
