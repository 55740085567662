import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import Checkbox from "@mui/material/Checkbox";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import BorderTopIcon from "@mui/icons-material/BorderTop";
import BorderRightIcon from "@mui/icons-material/BorderRight";
import BorderBottomIcon from "@mui/icons-material/BorderBottom";
import BorderLeftIcon from "@mui/icons-material/BorderLeft";

// COMPONENTS
import InputSlider from "./InputSlider";
import ColorPicker from "../ColorPicker";
import RowWrapper from "../RowWrapper";

export default function DropShadow({ init, parentKey, fieldKey, callback }) {
   const [data, setData] = React.useState(init);
   const [presetColors, setPresetColors] = useState([]);

   // COLOR PICKER
   const updatePresetColors = () => {
      let colors = ["#000", "#fff"];

      setPresetColors(
         colors.length != "undefined" ? colors : ["#ff0000", "#ccc"]
      );
   };

   const setColor = (parentKey, fieldKey, obj) => {
      const value = colorObj2rgba(obj);

      const newData = {
         ...data,
         [fieldKey]: value,
      };

      setData(newData);
      callback(parentKey, "dropshadow", newData);
   };

   const colorObj2rgba = (object) => {
      return `rgba(${object.r},${object.g},${object.b},${object.a})`;
   };

   const convertColor2Obj = (color) => {
      if (color.includes("#")) {
         return hexToRGB(color);
      } else {
         let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
         return { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
      }
   };

   const hexToRGB = (hex) => {
      var r = parseInt(hex.slice(1, 3), 16),
         g = parseInt(hex.slice(3, 5), 16),
         b = parseInt(hex.slice(5, 7), 16);
      return { r: r, g: g, b: b, a: 1 };
   };

   const rgba2hex = (color) => {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
         (1 << 24) +
         (parseInt(rgba[0]) << 16) +
         (parseInt(rgba[1]) << 8) +
         parseInt(rgba[2])
      )
         .toString(16)
         .slice(1)}`;

      return hex;
   };

   // USE EFFECT

   React.useEffect(() => {
      callback(parentKey, fieldKey, data);
   }, [data]);

   const handleNumberSliderChange = (parentKey, fieldKey, value) => {
      const newData = {
         ...data,
         [fieldKey]: value,
      };

      setData(newData);
      callback(parentKey, "dropshadow", newData);
   };

   const handleCheckboxChange = (e) => {
      const fieldKey = e.target.getAttribute("fieldKey");
      const value = e.target.checked;

      const newData = {
         ...data,
         [fieldKey]: value,
      };

      setData(newData);
      callback(parentKey, "dropshadow", newData);
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{ textAlign: "center" }}
         >
            <Grid item xs={12}>
               <RowWrapper label={"dropShadow"} type='checkbox'>
                  <Checkbox
                     checked={data.enable}
                     inputProps={{
                        parentKey: parentKey ? parentKey : "",
                        fieldKey: "enable",
                     }}
                     onChange={handleCheckboxChange}
                  />
               </RowWrapper>
            </Grid>
            <Grid item xs={12}>
               <RowWrapper label={"dropShadowColor"}>
                  <Box sx={{ position: "relative" }}>
                     <ColorPicker
                        color={convertColor2Obj(data.color)}
                        updateSwatches={updatePresetColors}
                        callback={(e) =>
                           setColor(parentKey ? parentKey : "", "color", e)
                        }
                        presetColors={presetColors}
                        width={100}
                        height={30}
                     />
                  </Box>
               </RowWrapper>
            </Grid>
            <Grid item xs={12}>
               <RowWrapper label={"dropShadowPositionX"}>
                  <InputSlider
                     init={data.xPos}
                     min={-100}
                     max={100}
                     parentKey={parentKey}
                     fieldKey={"xPos"}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
               <RowWrapper label={"dropShadowPositionY"}>
                  <InputSlider
                     init={data.yPos}
                     min={-100}
                     max={100}
                     parentKey={parentKey}
                     fieldKey={"yPos"}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            </Grid>
            <Grid item xs={12}>
               <RowWrapper label={"dropShadowBorderWidth"}>
                  <InputSlider
                     init={data.borderWidth}
                     step={0.1}
                     min={0}
                     max={10}
                     parentKey={parentKey}
                     fieldKey={"borderWidth"}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            </Grid>
            <Grid item xs={12}>
               <RowWrapper label={"dropShadowBorderColor"}>
                  <Box sx={{ position: "relative" }}>
                     <ColorPicker
                        color={convertColor2Obj(data.borderColor)}
                        updateSwatches={updatePresetColors}
                        callback={(e) =>
                           setColor(
                              parentKey ? parentKey : "",
                              "borderColor",
                              e
                           )
                        }
                        presetColors={presetColors}
                        width={100}
                        height={30}
                     />
                  </Box>
               </RowWrapper>
            </Grid>
         </Grid>
      </Box>
   );
}
