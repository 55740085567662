import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import LayoutTemplateEditorTugofWar from "../layouts/LayoutTemplateEditorTugofWar";

import EditorPollDataForm from "../editor/EditorPollDataForm";

// MODAL
import Modal from "@mui/material/Modal";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 600,
   bgcolor: "#efefef",
   border: "2px solid #cccccc",
   boxShadow: 24,
   p: 4,
};

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function StyleEditorTugofWar(props) {
   // MODAL
   const [activePoll, setActivePoll] = useState({
      type: "tugofwar",
      title: "Test",
      answers: {
         A: "First Option",
         B: "Second Option",
      },
      layout: "a1",
      id: "e3bfd9440db794bcb253713f75cf62c578271238",
   });

   const [modalOpen, setModalOpen] = useState(false);

   const handleOpen = (poll) => {
      setModalOpen(true);
   };
   const handleClose = () => {
      setModalOpen(false);
   };

   const handleUpdatePoll = (poll) => {
      setActivePoll(poll);
   };

   // SOCKET
   const socket = useContext(SocketContext);

   // STYLES
   const [styles, setStyles] = useState();
   const [fonts, setFonts] = useState();
   const [selectedLayout, setSelectedLayout] = useState();

   const [polls, setPolls] = useState([]);

   useEffect(() => {
      // INITIAL LOAD
      socket.emit("getData", (response) => {
         setPolls(
            response.polls.filter((poll) => {
               return poll.type === "tugofwar";
            })
         );
      });

      socket.emit("getStyles", (response) => {
         setStyles(response.stylesTugofWar);
         setFonts(response.fonts);
      });
   }, []);

   useEffect(() => {
      socket.on("refreshStyles", () => {
         socket.emit("getData", (response) => {
            socket.emit("getStyles", (styleResponse) => {
               setStyles(styleResponse.stylesTugofWar);
               setFonts(styleResponse.fonts);
            });
         });
      });
   }, [socket]);

   const handleUpdateDB = (data) => {
      if (data.action == "insert") {
         data.layout.global.parameters.layoutTitle =
            "Copy of " + data.layout.global.parameters.layoutTitle;
      }

      socket.emit("updateLayoutTugofWar", data, (response) => {
         setSelectedLayout(response.id);
      });
   };

   useEffect(() => {
      if (fonts) {
         fonts.map((font) => {
            var fontFace = new FontFace(
               font.split(".")[0],
               "url(" + SERVER_URL + "/fonts/" + font + ")"
            );

            fontFace
               .load()
               .then(function (loaded_face) {
                  document.fonts.add(loaded_face);
                  document.body.style.fontFamily =
                     '"' + font.split(".")[0] + '", Arial';
               })
               .catch(function (error) {
                  console.log(error);
               });
         });
      }
   }, [fonts]);

   return (
      <>
         {styles && (
            <LayoutTemplateEditorTugofWar
               key={selectedLayout}
               activePoll={activePoll}
               fonts={fonts}
               styles={styles}
               selectedLayout={selectedLayout}
               setSelectedLayout={setSelectedLayout}
               updateDB={handleUpdateDB}
               openDataEditor={handleOpen}
            />
         )}

         {/* MODAL - EDIT PREVIEW DATA */}
         <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby='modal-title'
            aria-describedby='modal-description'
         >
            <Box sx={modalStyle}>
               <Typography id='modal-title' variant='h6' component='h2'>
                  Edit Preview Data
               </Typography>
               <Typography id='modal-description' sx={{ mt: 2 }}>
                  <EditorPollDataForm
                     poll={activePoll}
                     stagedPolls={polls}
                     updatePoll={handleUpdatePoll}
                     edit={true}
                     closeModal={handleClose}
                  />
               </Typography>
            </Box>
         </Modal>
      </>
   );
}
