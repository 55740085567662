import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

const Input = styled(MuiInput)`
   width: "100%";
`;

export default function InputSlider({
   init,
   step,
   min,
   max,
   parentKey,
   fieldKey,
   fieldLabel = null,
   handleNumberSliderChange,
}) {
   const [value, setValue] = React.useState(init);

   React.useEffect(() => {
      handleNumberSliderChange(parentKey, fieldKey, value);
   }, [value]);

   const handleSliderChange = (event, newValue) => {
      setValue(newValue);
   };

   const handleInputChange = (event) => {
      setValue(event.target.value === "" ? "" : Number(event.target.value));
   };

   const handleBlur = () => {
      if (value < min) {
         setValue(min);
      } else if (value > max) {
         setValue(max);
      }
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Grid container alignItems='center' sx={{ textAlign: "left" }}>
            {fieldLabel && (
               <Grid
                  item
                  xs={4}
                  sx={{
                     p: 1,
                     fontWeight: "bold",
                     textTransform: "uppercase",
                     fontSize: 11,
                  }}
               >
                  {fieldLabel}
               </Grid>
            )}
            <Grid item xs={fieldLabel ? 4 : 3}>
               <Input
                  placeholder={
                     fieldKey.toLowerCase().includes("fontsize") ? "unset" : 0
                  }
                  fullWidth
                  value={init}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                     step: step,
                     min: min,
                     max: max,
                     type: "number",
                     "aria-labelledby": "input-slider",
                  }}
                  endAdornment={
                     <IconButton
                        onClick={() =>
                           setValue(
                              fieldKey.toLowerCase().includes("fontsize")
                                 ? "unset"
                                 : Math.max(min, 0)
                           )
                        }
                     >
                        <ClearIcon />
                     </IconButton>
                  }
               />
            </Grid>
            <Grid item xs={fieldLabel ? 4 : 9}>
               <Slider
                  step={step}
                  min={min}
                  max={max}
                  value={typeof value === "number" ? init : 0}
                  onChange={handleSliderChange}
               />
            </Grid>
         </Grid>
      </Box>
   );
}
