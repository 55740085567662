import Typography from "@mui/material/Typography";

export default function ViewerWrapper({ children, activeLayout }) {
   return (
      <Typography
         variant='body1'
         component='div'
         key={"template"}
         sx={{
            position: "relative",
            width: 1920,
            height: 1080,
            boxSizing: "border-box",
            fontFamily: activeLayout
               ? activeLayout.global.style.fontFamily.split(".")[0]
               : "unset",
            fontWeight: activeLayout
               ? activeLayout.global.style.fontWeight
               : "normal",
            overflow: "hidden",
         }}
      >
         {children}
      </Typography>
   );
}
