import { useState, useEffect } from "react";
import { Rnd } from "react-rnd";
import { motion } from "framer-motion";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import IconButton from "@mui/material/IconButton";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const Handle = (rotate) => {
   return (
      <>
         <ArrowCircleRightIcon
            sx={{
               color: "#fff",
               transform: "rotate(" + (rotate ? rotate.angle : 0) + "deg)",
            }}
         />
      </>
   );
};

const HandleClasses = {
   bottom: "bottom",
   bottomLeft: "bottomLeft",
   bottomRight: "bottomRight",
   left: "left",
   right: "right",
   top: "top",
   topLeft: "topLeft",
   topRight: "topRight",
};

const HandleComponents = {
   top: <Handle />,
   right: <Handle />,
   bottom: <Handle />,
   left: <Handle />,
   topRight: <Handle />,
   bottomRight: <Handle />,
   bottomLeft: <Handle />,
   topLeft: <Handle />,
};

export default function RndContainer({
   children,
   id,
   data,
   zIndex,
   modal,
   callback,
   minSize,
   adjust,
   scale,
   sizeCallback,
}) {
   const [coords, setCoords] = useState(data.position);

   useEffect(() => {
      setCoords(data.position);
   }, [data.position]);

   const handleResize = (e, handle, ref, size, position) => {
      // UPDATE STATE
      let newPosition = { ...data.position };

      newPosition.xPos = parseFloat(position.x.toFixed(0));
      newPosition.yPos = parseFloat(position.y.toFixed(0));
      newPosition.width = parseInt(ref.style.width);
      newPosition.height = parseInt(ref.style.height);

      callback(id, "position", newPosition);
   };

   const handleMove = (e, position) => {
      // UPDATE STATE
      let newPosition = { ...data.position };

      newPosition.xPos = parseFloat(position.x.toFixed(0));
      newPosition.yPos = parseFloat(position.y.toFixed(0));

      callback(id, "position", newPosition);
   };

   const handleCoordsResize = (e, handle, ref, size, position) => {
      setCoords((prevState) => {
         let newState = { ...prevState };

         newState.xPos = position.x;
         newState.yPos = position.y;
         newState.width = parseInt(ref.style.width);
         newState.height = parseInt(ref.style.height);

         sizeCallback(newState);

         return newState;
      });
   };

   const handleCoordsMove = (e, position) => {
      setCoords((prevState) => {
         let newState = { ...prevState };

         newState.xPos = position.x;
         newState.yPos = position.y;

         return newState;
      });
   };

   const handleModal = () => {
      modal(id, data);
   };

   // FRAMER MOTION
   const rndVariants = {
      visible: {
         opacity: 1,
      },
      hover: {
         zIndex: 2000,
         opacity: 1,
         outline: "2px solid rgba(255,0,0,.5)",
      },
   };
   const rndInfoVariants = {
      visible: {
         opacity: 0,
      },
      hover: {
         opacity: 1,
      },
   };

   return (
      <>
         {data.position && (
            <Rnd
               as={motion.div}
               size={{
                  width: data.position.width,
                  height: data.position.height,
               }}
               scale={scale}
               minWidth={minSize ? minSize.width : 100}
               minHeight={minSize ? minSize.height : 50}
               position={{ x: data.position.xPos, y: data.position.yPos }}
               onMouseDown={handleModal}
               lockAspectRatio={data.parameters.lockAspectRatio}
               onResizeStop={handleResize}
               onResize={handleCoordsResize}
               onDragStop={handleMove}
               onDrag={handleCoordsMove}
               style={{
                  position: "absolute",
                  zIndex: zIndex,
                  boxSizing: "border-box",
                  padding: 0,
                  margin: 0,
               }}
               className='rndComponent'
               initial='visible'
               whileHover='hover'
               variants={rndVariants}
               resizeHandleWrapperClass={"handles"}
               resizeHandleComponent={HandleComponents}
               resizeHandleClasses={HandleClasses}
            >
               {adjust && id !== "logo" && id !== "timer" && (
                  <Grid
                     container
                     alignItems='center'
                     justifyContent='flex-start'
                     as={motion.div}
                     style={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        color: "#fff",
                        zIndex: 2000,
                        fontSize: 12,
                        fontWeight: "bold",
                        pointerEvents: "none",
                     }}
                     variants={rndInfoVariants}
                  >
                     <Grid item>
                        <Button
                           startIcon={<FitScreenIcon sx={{ mr: 2 }} />}
                           color='error'
                           variant='contained'
                           as={motion.div}
                           variants={rndInfoVariants}
                           sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pointerEvents: "all",
                              zIndex: 200,
                              cursor: "pointer",
                           }}
                           onClick={() => adjust(id, "fit")}
                        >
                           FIT TO FRAME
                        </Button>
                     </Grid>
                     <Grid item>
                        <Button
                           startIcon={
                              <VerticalAlignCenterIcon
                                 sx={{ transform: "rotate(90deg)", mr: 2 }}
                              />
                           }
                           color='error'
                           variant='contained'
                           as={motion.div}
                           variants={rndInfoVariants}
                           sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              pointerEvents: "all",
                              zIndex: 200,
                              cursor: "pointer",
                           }}
                           onClick={() => adjust(id, "center")}
                        >
                           CENTER
                        </Button>
                     </Grid>
                  </Grid>
               )}

               {/* DATA */}
               <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  as={motion.div}
                  style={{
                     position: "absolute",
                     bottom: -50,
                     left: "50%",
                     height: "auto",
                     height: 50,
                     width: 300,
                     transform: "translateX(-50%)",
                     backgroundColor: "rgba(0,0,0,.4)",
                     color: "#fff",
                     zIndex: -1,
                     fontSize: 12,
                     fontWeight: "bold",
                     fontFamily: "Arial",
                     whiteSpace: "nowrap",
                  }}
                  variants={rndInfoVariants}
               >
                  <Grid item sx={{ p: 0.5 }}>
                     x: {parseInt(coords.xPos)}
                  </Grid>
                  <Grid item sx={{ p: 0.5 }}>
                     y: {parseInt(coords.yPos)}
                  </Grid>
                  <Grid item sx={{ p: 0.5 }}>
                     width: {parseInt(coords.width)}
                  </Grid>
                  <Grid item sx={{ p: 0.5 }}>
                     height: {parseInt(coords.height)}
                  </Grid>
               </Grid>
               {children}
            </Rnd>
         )}
      </>
   );
}
