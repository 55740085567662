import React, { useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import { ErrorOutlineOutlined } from "@mui/icons-material";

import _ from "lodash";

export default function Ranking(props) {
   const [value, setValue] = useState(props.value);

   function handleClick(e) {
      if (parseInt(e.target.value) === value) {
         setValue();
         props.callback(props.arrayKey, props.objKey, props.row, props.col, 0);
      }
   }

   const handleChange = (e) => {
      let value = parseInt(e.target.value);
      setValue(value);
      props.callback(props.arrayKey, props.objKey, props.row, props.col, value);
   };

   return (
      <FormControl>
         <FormLabel id={"radio-label-${props.row}-${props.col}"}>
            <h6 style={{ margin: 0 }}>
               {props.points ? props.points * props.multiplier : 0} points
            </h6>
         </FormLabel>
         <RadioGroup
            aria-labelledby={"radio-label-${props.row}-${props.col}"}
            name={"radio-buttons-${props.row}-${props.col}"}
            value={props.value}
            onChange={handleChange}
         >
            {Array(props.options)
               .fill()
               .map((value, i) => (
                  <FormControlLabel
                     key={"radio-" + props.row + "-" + props.col + "-" + i}
                     value={i + 1}
                     control={<Radio onClick={handleClick} />}
                     style={{ position: "relative" }}
                     label={
                        <>
                           {i + 1}
                           {props.curSet &&
                              props.curSet[i] &&
                              props.curSet[i].alert && (
                                 <ErrorOutlineOutlined
                                    className='alert'
                                    style={{
                                       position: "absolute",
                                       top: 9,
                                       right: -30,
                                       color: "#ff0000",
                                    }}
                                 />
                              )}
                        </>
                     }
                     className={
                        props.curSet &&
                        props.curSet[i] &&
                        props.curSet[i].className
                     }
                  />
               ))}
         </RadioGroup>
      </FormControl>
   );
}
