import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function StyleMenu({
   styles,
   selectedLayout,
   setSelectedLayout,
}) {
   const handleChange = (event) => {
      setSelectedLayout(event.target.value);
   };

   return (
      <Box>
         <FormControl fullWidth>
            <InputLabel id='styles-menu'>Styles</InputLabel>
            <Select
               labelId='styles-menu'
               id='styles-menu-select'
               value={selectedLayout ? selectedLayout : ""}
               label='Style'
               onChange={handleChange}
            >
               {styles &&
                  Object.entries(styles).map((style) => (
                     <MenuItem
                        value={style[0]}
                        key={"selectedStyle-" + style[0]}
                     >
                        {style[1].global.parameters.layoutTitle}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
      </Box>
   );
}
