import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import BorderTopIcon from "@mui/icons-material/BorderTop";
import BorderRightIcon from "@mui/icons-material/BorderRight";
import BorderBottomIcon from "@mui/icons-material/BorderBottom";
import BorderLeftIcon from "@mui/icons-material/BorderLeft";

// COMPONENTS
import ColorPicker from "../ColorPicker";

export default function Borders({
   init,
   parentKey,
   fieldKey,
   handleBorderChange,
}) {
   const [activeParameter, setActiveParameter] = React.useState("all");
   const [activeValue, setActiveValue] = React.useState();
   const [borders, setBorders] = React.useState(init);

   const [presetColors, setPresetColors] = useState([]);

   // COLOR PICKER
   const updatePresetColors = () => {
      let colors = ["#000", "#fff"];

      setPresetColors(
         colors.length != "undefined" ? colors : ["#ff0000", "#ccc"]
      );
   };

   const colorObj2rgba = (object) => {
      return `rgba(${object.r},${object.g},${object.b},${object.a})`;
   };

   const convertColor2Obj = (color) => {
      if (color.includes("#")) {
         return hexToRGB(color);
      } else {
         let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
         return { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
      }
   };

   const hexToRGB = (hex) => {
      var r = parseInt(hex.slice(1, 3), 16),
         g = parseInt(hex.slice(3, 5), 16),
         b = parseInt(hex.slice(5, 7), 16);
      return { r: r, g: g, b: b, a: 1 };
   };

   const rgba2hex = (color) => {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
         (1 << 24) +
         (parseInt(rgba[0]) << 16) +
         (parseInt(rgba[1]) << 8) +
         parseInt(rgba[2])
      )
         .toString(16)
         .slice(1)}`;

      return hex;
   };

   // USE EFFECT

   React.useEffect(() => {
      handleBorderChange(parentKey, fieldKey, borders);
   }, [borders]);

   React.useEffect(() => {
      if (activeParameter == "all") {
         setActiveValue(0);
      } else {
         setActiveValue(borders[activeParameter]);
      }
   }, [activeParameter]);

   const handleSliderChange = (event, newValue) => {
      if (activeParameter === "all") {
         setBorders((prevState) => {
            return {
               ...prevState,
               top: newValue,
               left: newValue,
               bottom: newValue,
               right: newValue,
            };
         });
      } else {
         setBorders((prevState) => {
            return { ...prevState, [activeParameter]: newValue };
         });
      }
   };

   const handleInputChange = (event) => {
      if (activeParameter === "all") {
         setBorders((prevState) => {
            return {
               ...prevState,
               top: event.target.value,
               left: event.target.value,
               bottom: event.target.value,
               right: event.target.value,
            };
         });
      } else {
         setBorders((prevState) => {
            return { ...prevState, [activeParameter]: event.target.value };
         });
      }
   };

   const handleZeroInput = (type) => {
      if (type == "width") {
         if (activeParameter === "all") {
            setBorders((prevState) => {
               return {
                  ...prevState,
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
               };
            });
         } else {
            setBorders((prevState) => {
               return { ...prevState, [activeParameter]: 0 };
            });
         }
      } else {
         setBorders((prevState) => {
            return { ...prevState, [type]: 0 };
         });
      }
   };

   const handleRadiusSliderChange = (event, newValue) => {
      setBorders((prevState) => {
         return {
            ...prevState,
            radius: newValue,
         };
      });
   };

   const handleRadiusInputChange = (event) => {
      setBorders((prevState) => {
         return {
            ...prevState,
            radius: Number(event.target.value),
         };
      });
   };

   const handleColorChange = (value) => {
      setBorders((prevState) => {
         return { ...prevState, color: colorObj2rgba(value) };
      });
   };

   const handleStyleChange = (event) => {
      setBorders((prevState) => {
         return {
            ...prevState,
            style: event.target.value,
         };
      });
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{ textAlign: "center" }}
         >
            <Grid item xs={12}>
               <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  sx={{ textAlign: "center", fontSize: 10 }}
               >
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                     {borders["top"]}
                     <br />
                     <IconButton
                        color={
                           activeParameter === "top" ? "success" : "primary"
                        }
                        component='label'
                        onClick={() => setActiveParameter("top")}
                     >
                        <BorderTopIcon />
                     </IconButton>
                  </Grid>
                  <Grid item xs={4}></Grid>

                  <Grid item xs={4} sx={{ textAlign: "right" }}>
                     {borders["left"]}
                     <IconButton
                        color={
                           activeParameter === "left" ? "success" : "primary"
                        }
                        component='label'
                        onClick={() => setActiveParameter("left")}
                     >
                        <BorderLeftIcon />
                     </IconButton>
                  </Grid>
                  <Grid item xs={4}>
                     <IconButton
                        color={
                           activeParameter === "all" ? "success" : "primary"
                        }
                        component='label'
                        onClick={() => setActiveParameter("all")}
                     >
                        <BorderOuterIcon />
                     </IconButton>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "left" }}>
                     <IconButton
                        color={
                           activeParameter === "right" ? "success" : "primary"
                        }
                        component='label'
                        onClick={() => setActiveParameter("right")}
                     >
                        <BorderRightIcon />
                     </IconButton>
                     {borders["right"]}
                  </Grid>

                  <Grid item xs={4}></Grid>
                  <Grid item xs={4}>
                     <IconButton
                        color={
                           activeParameter === "bottom" ? "success" : "primary"
                        }
                        component='label'
                        onClick={() => setActiveParameter("bottom")}
                     >
                        <BorderBottomIcon />
                     </IconButton>
                     <br />
                     {borders["bottom"]}
                  </Grid>
                  <Grid item xs={4}></Grid>
               </Grid>
            </Grid>
            <Grid item xs={12}>
               <Grid
                  container
                  spacing={2}
                  alignItems='center'
                  justifyContent='center'
                  sx={{ textAlign: "center" }}
               >
                  <Grid item xs={6}>
                     <TextField
                        label={activeParameter.toUpperCase() + " WIDTH"}
                        fullWidth
                        value={
                           activeParameter !== "all"
                              ? borders[activeParameter]
                              : borders["top"]
                        }
                        onChange={handleInputChange}
                        type='number'
                        InputProps={{
                           inputProps: {
                              max: 50,
                              min: 0,
                           },
                           "aria-labelledby": "input-slider",
                           endAdornment: (
                              <IconButton
                                 onClick={() => handleZeroInput("width")}
                              >
                                 <ClearIcon />
                              </IconButton>
                           ),
                        }}
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <Slider
                        value={
                           activeParameter !== "all"
                              ? borders[activeParameter]
                              : borders["top"]
                        }
                        min={0}
                        max={50}
                        onChange={handleSliderChange}
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <TextField
                        label={"BORDER RADIUS"}
                        fullWidth
                        value={borders["radius"]}
                        onChange={handleRadiusInputChange}
                        type='number'
                        InputProps={{
                           inputProps: {
                              max: 50,
                              min: 0,
                           },
                           "aria-labelledby": "input-slider",
                           endAdornment: (
                              <IconButton
                                 onClick={() => handleZeroInput("radius")}
                              >
                                 <ClearIcon />
                              </IconButton>
                           ),
                        }}
                     />
                  </Grid>
                  <Grid item xs={6}>
                     <Slider
                        value={borders["radius"]}
                        onChange={handleRadiusSliderChange}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <FormControl fullWidth>
                        <InputLabel id='select-label'>STYLE</InputLabel>
                        <Select
                           labelId='select-label'
                           id='style-select'
                           value={borders["style"]}
                           label='Border Style'
                           onChange={handleStyleChange}
                        >
                           <MenuItem value={"none"}>None</MenuItem>
                           <MenuItem value={"solid"}>Solid —</MenuItem>
                           <MenuItem value={"dashed"}>Dashed - -</MenuItem>
                           <MenuItem value={"dotted"}>Dotted • • •</MenuItem>
                           <MenuItem value={"double"}>Double =</MenuItem>
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                     <Box sx={{ position: "relative", textAlign: "left" }}>
                        <ColorPicker
                           color={convertColor2Obj(borders["color"])}
                           updateSwatches={updatePresetColors}
                           callback={handleColorChange}
                           presetColors={presetColors}
                           width={100}
                           height={30}
                        />
                     </Box>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </Box>
   );
}
