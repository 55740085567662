import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";

// COMPONENTS
import ColorPicker from "./ColorPicker";
import ImageUploader from "./ImageUploader";
import FontMenu from "./FontMenu";
import RowWrapper from "./RowWrapper";

// CONTROLS
import InputSlider from "./controls/InputSlider";
import SelectMenus from "./controls/SelectMenus";
import TugofWar from "./controls/TugofWar";

// OBJECT EDIT COMPONENT FOR TEMPLATE EDITOR PANELS
export default function ObjectEdit({
   layoutId,
   fonts,
   data,
   activePanel,
   parentKey,
   handleCheckboxChange,
   handleNumberChange,
   handleNumberSliderChange,
   handleTextChange,
   handleSelectChange,
   handleFileChange,
   handleObjectChange,
   handleBackgroundChange,
   handleBorderChange,
   colorPicker,
}) {
   const renderField = (value, parentKey, fieldKey) => {
      switch (fieldKey) {
         case "customColorsActive":
         case "lockAspectRatio":
         case "showLetter":
         case "showPct":
         case "showTitle":
         case "visible":
         case "scrollLongText":
            return (
               <RowWrapper label={fieldKey} type='checkbox'>
                  <Checkbox
                     checked={value}
                     inputProps={{
                        parentKey: parentKey ? parentKey : "",
                        fieldKey: fieldKey,
                     }}
                     onChange={handleCheckboxChange}
                  />
               </RowWrapper>
            );
            break;
         case "layoutTitle":
            return (
               <RowWrapper label={fieldKey}>
                  <TextField
                     fullWidth
                     size='small'
                     inputProps={{
                        parentKey: parentKey ? parentKey : "",
                        fieldKey: fieldKey,
                     }}
                     value={value}
                     onChange={handleTextChange}
                  />
               </RowWrapper>
            );
            break;
         case "tugofwar":
            return (
               <RowWrapper label='tugofwar'>
                  <TugofWar
                     init={value}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     layoutId={layoutId}
                     activePanel={activePanel}
                     callback={handleObjectChange}
                  />
               </RowWrapper>
            );
            break;
         case "backgroundColor":
         case "color":
         case "backgroundColorActive":
         case "colorActive":
         case "fontColor":
         case "letterColor":
         case "letterStrokeColor":
         case "pctColor":
         case "pctStrokeColor":
         case "textBorderColor":
         case "titleColor":
         case "titleStrokeColor":
            return (
               <RowWrapper label={fieldKey}>
                  <Box sx={{ position: "relative" }}>
                     <ColorPicker
                        color={colorPicker.convertColor2Obj(value)}
                        updateSwatches={colorPicker.updatePresetColors}
                        callback={(e) =>
                           colorPicker.setColor(
                              parentKey ? parentKey : "",
                              fieldKey,
                              e
                           )
                        }
                        presetColors={colorPicker.presetColors}
                        width={100}
                        height={30}
                     />
                  </Box>
               </RowWrapper>
            );
            break;
         case "width":
         case "height":
         case "xPos":
         case "xPos":
         case "yPos":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     min={-1920}
                     max={1920}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "letterX":
         case "letterY":
         case "titleX":
         case "titleY":
         case "pctX":
         case "pctY":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     min={-150}
                     max={150}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "fontSize":
         case "letterFontSize":
         case "minHeight":
         case "minWidth":
         case "pctFontSize":
         case "titleFontSize":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     min={10}
                     max={200}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "titleStroke":
         case "letterStroke":
         case "pctStroke":
         case "textBorderWidth":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     step={0.1}
                     min={0}
                     max={10}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "px":
         case "py":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     step={0.1}
                     min={0}
                     max={50}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "paddingTop":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     step={1}
                     min={0}
                     max={100}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "maxItems":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     step={1}
                     min={0}
                     max={8}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "height":
         case "letterStroke":
         case "maxItems":
         case "pctStroke":
         case "textBorderWidth":
         case "titleStroke":
         case "width":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     min={0}
                     max={100}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "titleWidth":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     min={10}
                     max={100}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "backgroundSize":
            return (
               <RowWrapper label={fieldKey}>
                  <InputSlider
                     init={value}
                     min={0}
                     max={400}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleNumberSliderChange={handleNumberSliderChange}
                  />
               </RowWrapper>
            );
            break;
         case "backgroundImage":
         case "image":
         case "icon":
            return (
               <RowWrapper label={fieldKey}>
                  <Box sx={{ position: "relative" }}>
                     <ImageUploader
                        layoutId={layoutId}
                        activePanel={activePanel}
                        section={parentKey}
                        field={fieldKey}
                        image={value}
                        callback={handleFileChange}
                     />
                  </Box>
               </RowWrapper>
            );
            break;
         case "pctAlign":
         case "textAlign":
         case "bkgndLeftRight":
         case "letterLeftRight":
         case "titleLeftRight":
         case "xAlign":
         case "bkgndTopBottom":
         case "titleTopBottom":
         case "yAlign":
         case "backgroundRepeat":
         case "fontWeight":
         case "titleFontWeight":
         case "direction":
         case "orientation":
            return (
               <RowWrapper label={fieldKey}>
                  <SelectMenus
                     init={value}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleSelectChange={handleSelectChange}
                  />
               </RowWrapper>
            );
            break;
         case "pctTopBottom":
         case "letterTopBottom":
            return (
               <RowWrapper label={fieldKey}>
                  <SelectMenus
                     init={value}
                     parentKey={parentKey}
                     fieldKey={fieldKey}
                     handleSelectChange={handleSelectChange}
                  />
               </RowWrapper>
            );
            break;
         case "fontFamily":
         case "titleFontFamily":
            return (
               <RowWrapper label={fieldKey}>
                  <Box sx={{ position: "relative" }}>
                     <FontMenu
                        layoutId={layoutId}
                        activePanel={activePanel}
                        section={parentKey}
                        field={fieldKey}
                        font={value}
                        fonts={fonts}
                        callback={handleFileChange}
                     />
                  </Box>
               </RowWrapper>
            );
            break;
         case "bar1Color":
         case "bar2Color":
         case "bar3Color":
         case "bar4Color":
         case "bar5Color":
         case "bar6Color":
         case "bar7Color":
         case "bar8Color":
         case "bar9Color":
         case "bar10Color":
            return (
               <RowWrapper label={fieldKey}>
                  <Box sx={{ position: "relative" }}>
                     <ColorPicker
                        color={colorPicker.convertColor2Obj(value)}
                        updateSwatches={colorPicker.updatePresetColors}
                        callback={(e) =>
                           colorPicker.setColor(
                              parentKey ? parentKey : "",
                              fieldKey,
                              e
                           )
                        }
                        presetColors={colorPicker.presetColors}
                        width={100}
                        height={30}
                     />
                  </Box>
               </RowWrapper>
            );
            break;
      }
   };

   return (
      <Grid
         container
         direction='row'
         justifyContent='center'
         alignItems='center'
      >
         {data && (
            <>
               {Object.entries(data).map((section) => {
                  return renderField(section[1], parentKey, section[0]);
               })}
            </>
         )}
      </Grid>
   );
}
