import { useState, useEffect } from "react";
import _ from "lodash";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function EditorDataForm(props) {
   return (
      <>
         <h5>Load data from existing:</h5>
         <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id={"layout"}>Wheel Data</InputLabel>
            <Select
               defaultValue={""}
               labelId={"datamenu"}
               label='Fill data'
               onChange={(e) =>
                  props.update(
                     _.filter(props.data.wheels, { id: e.target.value })[0]
                  )
               }
            >
               {props.data.wheels &&
                  props.data.wheels.length > 0 &&
                  props.data.wheels.map((wheel) => (
                     <MenuItem key={"style"} value={wheel.id}>
                        {wheel.title}
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
      </>
   );
}
