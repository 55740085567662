import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

// COMPONENT
export default function TextSquisher(props) {
   const textRef = useRef(null);
   const parentRef = useRef(null);

   const [scaleX, setScaleX] = useState(1);

   const [parentWidth, setParentWidth] = useState();
   const [width, setWidth] = useState();
   const [height, setHeight] = useState();

   const [opacity, setOpacity] = useState(0);

   useEffect(() => {
      setTimeout(function () {
         setParentWidth(parentRef.current.clientWidth);
         setHeight(textRef.current.clientHeight);
         setWidth(textRef.current.clientWidth);

         setOpacity(1);
      }, 300);
   }, []);

   useEffect(() => {
      setScaleX(width > parentWidth ? parentWidth / width : 1);
   }, [width, parentWidth]);

   return (
      <div
         ref={parentRef}
         style={{
            width: "100%",
            height: "auto",
            position: "relative",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            padding: 0,
            margin: 0,
         }}
      >
         <motion.div
            ref={textRef}
            style={{
               whiteSpace: "nowrap",
               position: "absolute",
               top: "50%",
               left: "50%",
               overflow: "visible",
               textAlign: "left",
               transform:
                  "translateX(-50%) translateY(-50%) scaleX(" + scaleX + ")",
               transformOrigin: "center center",
               margin: 0,
               padding: 0,
            }}
         >
            <motion.div
               initial={{ opacity: 0 }}
               animate={{ opacity }}
               style={{
                  border: "10px solid transparent",
                  boxSizing: "border-box",
               }}
            >
               {props.text}
            </motion.div>

            {props.dropshadow && props.dropshadow.enable && (
               <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity }}
                  style={{
                     position: "absolute",
                     whiteSpace: "nowrap",
                     top: props.dropshadow.yPos,
                     left: props.dropshadow.xPos,
                     color: props.dropshadow.color,

                     WebkitTextStroke:
                        props.dropshadow.borderWidth +
                        "px " +
                        props.dropshadow.borderColor,
                     zIndex: -1,
                     border: "10px solid transparent",
                     boxSizing: "border-box",
                  }}
               >
                  {props.text}
               </motion.div>
            )}
         </motion.div>
      </div>
   );
}
