import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useParams } from "react-router-dom";

import ViewerWrapper from "./ViewerWrapper";
import ViewerComponent from "./ViewerComponent";

//HOOKS
import useGlobal from "../hooks/useGlobal";

export default function Viewer(props) {
   // USE GLOBAL HOOK
   const [fonts, activeLayout, state, updateState, { subscribePolls }] =
      useGlobal();

   useEffect(() => {
      subscribePolls();
   }, []);

   return (
      <>
         {activeLayout && (
            <ViewerWrapper key='pollViewer' activeLayout={activeLayout}>
               <ViewerComponent
                  key={"viewer"}
                  edit={false}
                  activeLayout={activeLayout}
                  activePoll={state.data}
                  activeTimer={state.activeTimer}
                  setActiveTimer={(value) => updateState("activeTimer", value)}
                  activeResultTotal={state.activeResultTotal}
                  activeResults={state.activeResults}
               />
            </ViewerWrapper>
         )}
      </>
   );
}
