import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import BorderOuterIcon from "@mui/icons-material/BorderOuter";
import BorderTopIcon from "@mui/icons-material/BorderTop";
import BorderRightIcon from "@mui/icons-material/BorderRight";
import BorderBottomIcon from "@mui/icons-material/BorderBottom";
import BorderLeftIcon from "@mui/icons-material/BorderLeft";

// COMPONENTS
import ColorPicker from "../ColorPicker";
import ImageUploader from "../ImageUploader";
import InputSlider from "./InputSlider";
import SelectMenus from "./SelectMenus";

export default function Background({
   init,
   parentKey,
   fieldKey,
   layoutId,
   activePanel,
   handleBackgroundChange,
}) {
   const [background, setBackground] = React.useState(init);
   const [presetColors, setPresetColors] = useState([]);

   const handleFileChange = (id, parentKey, key, value) => {
      const newData = {
         ...init,
         image: value,
      };

      setBackground(newData);

      handleBackgroundChange(parentKey, fieldKey, newData);
   };

   const handleNumberSliderChange = (parentKey, fieldKey, value) => {
      const newData = {
         ...init,
         [fieldKey]: value,
      };

      setBackground(newData);

      handleBackgroundChange(parentKey, "background", newData);
   };

   const handleSelectChange = (parentKey, fieldKey, value) => {
      const newData = {
         ...init,
         [fieldKey]: value,
      };

      setBackground(newData);

      handleBackgroundChange(parentKey, "background", newData);
   };

   // COLOR PICKER
   const updatePresetColors = () => {
      let colors = ["#000", "#fff", "rgba(0,0,0,0)"];

      setPresetColors(colors);
   };

   const colorObj2rgba = (object) => {
      return `rgba(${object.r},${object.g},${object.b},${object.a})`;
   };

   const convertColor2Obj = (color) => {
      if (color.includes("#")) {
         return hexToRGB(color);
      } else {
         let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
         return { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
      }
   };

   const hexToRGB = (hex) => {
      var r = parseInt(hex.slice(1, 3), 16),
         g = parseInt(hex.slice(3, 5), 16),
         b = parseInt(hex.slice(5, 7), 16);
      return { r: r, g: g, b: b, a: 1 };
   };

   const rgba2hex = (color) => {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
         (1 << 24) +
         (parseInt(rgba[0]) << 16) +
         (parseInt(rgba[1]) << 8) +
         parseInt(rgba[2])
      )
         .toString(16)
         .slice(1)}`;

      return hex;
   };

   // USE EFFECT

   React.useEffect(() => {
      handleBackgroundChange(parentKey, fieldKey, background);
   }, [background]);

   const handleColorChange = (value) => {
      setBackground((prevState) => {
         return { ...prevState, color: colorObj2rgba(value) };
      });
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Grid
            container
            spacing={2}
            alignItems='center'
            justifyContent='center'
            sx={{ textAlign: "center" }}
         >
            <Grid item xs={12}>
               <Grid
                  container
                  spacing={2}
                  alignItems='center'
                  justifyContent='center'
                  sx={{ textAlign: "center" }}
               >
                  <Grid item xs={12}>
                     <Box sx={{ position: "relative" }}>
                        <ImageUploader
                           layoutId={layoutId}
                           activePanel={activePanel}
                           section={parentKey}
                           field={fieldKey}
                           image={init.image}
                           callback={handleFileChange}
                        />
                     </Box>
                  </Grid>

                  {parentKey !== "timer" && parentKey !== "logo" && (
                     <>
                        <Grid item xs={12}>
                           <InputSlider
                              fieldLabel='Background Size'
                              init={init.size}
                              min={0}
                              max={3000}
                              parentKey={parentKey}
                              fieldKey={"size"}
                              handleNumberSliderChange={
                                 handleNumberSliderChange
                              }
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <SelectMenus
                              fieldLabel='Repeat'
                              init={init.repeat}
                              parentKey={parentKey}
                              fieldKey={"repeat"}
                              handleSelectChange={handleSelectChange}
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <SelectMenus
                              fieldLabel='Align Left/Right'
                              init={init.leftRight}
                              parentKey={parentKey}
                              fieldKey={"leftRight"}
                              handleSelectChange={handleSelectChange}
                           />
                        </Grid>

                        <Grid item xs={12}>
                           <SelectMenus
                              fieldLabel='Align Top/Bottom'
                              init={init.topBottom}
                              parentKey={parentKey}
                              fieldKey={"topBottom"}
                              handleSelectChange={handleSelectChange}
                           />
                        </Grid>
                     </>
                  )}

                  <Grid item xs={12}>
                     <Box sx={{ position: "relative", textAlign: "left" }}>
                        <ColorPicker
                           color={convertColor2Obj(background["color"])}
                           updateSwatches={updatePresetColors}
                           callback={handleColorChange}
                           presetColors={presetColors}
                           width={100}
                           height={30}
                        />
                     </Box>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </Box>
   );
}
