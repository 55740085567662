import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import Delete from "@mui/icons-material/DeleteForever";
import Edit from "@mui/icons-material/Edit";

function WheelForm(props) {
   const [wheel, setWheel] = useState();
   const [layout, setLayout] = useState();

   useEffect(() => {
      setWheel(props.wheel);
      setLayout(props.wheel.layout);
   }, [props]);

   const handleLayoutChange = (e) => {
      setWheel((prevState) => {
         let newState = { ...prevState };
         let value = e.target.value;

         newState["layout"] = value;

         props.updateWheel(newState);

         return newState;
      });
   };

   const updateTitle = (e) => {
      setWheel((prevState) => {
         let newState = { ...prevState };

         let name = e.target.getAttribute("name");
         let value = e.target.value;

         newState[name] = value;

         return newState;
      });
   };

   return (
      <>
         {wheel && (
            <Box
               sx={{
                  px: 3,
                  py: 2,
                  position: "relative",
               }}
               key={wheel.id}
            >
               <Button
                  size='small'
                  color='error'
                  sx={{
                     position: "absolute",
                     zIndex: 10,
                     top: 15,
                     right: 23,
                  }}
                  onClick={() => props.deleteWheel(wheel.id)}
                  startIcon={<Delete />}
               >
                  Delete
               </Button>
               {props.edit && (
                  <Button
                     size='small'
                     color='warning'
                     sx={{
                        position: "absolute",
                        zIndex: 10,
                        top: 15,
                        right: 120,
                     }}
                     onClick={() => props.handleOpen(wheel)}
                     startIcon={<Edit />}
                  >
                     Edit
                  </Button>
               )}
               {wheel.totalResults > 0 && (
                  <>
                     <b>Total Answers:</b> {wheel.totalResults}
                     <br />
                     <br />
                  </>
               )}
               <br />
               <br />
               <TextField
                  key={`inputTitle`}
                  label='Title'
                  name='title'
                  id='filled-size-small'
                  variant='outlined'
                  value={wheel.title}
                  size='small'
                  onChange={updateTitle}
                  onBlur={() => props.updateWheel(wheel)}
                  sx={{ mb: 1.5 }}
                  fullWidth
               />
               <br />

               <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id={"addPollLayouts"}>Layout</InputLabel>
                  <Select
                     labelId={"layoutMenu"}
                     value={wheel.layout}
                     label='Layout'
                     onChange={handleLayoutChange}
                  >
                     {props.layouts &&
                        Object.keys(props.layouts).map((layout) => (
                           <MenuItem
                              key={"pollLayout-" + layout}
                              value={layout}
                           >
                              {
                                 props.layouts[layout].global.parameters
                                    .layoutTitle
                              }
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>

               {!wheel.results && (
                  <>
                     <br />
                     <b>Answers</b>
                     <ol>
                        {Object.entries(wheel.answers)
                           .sort()
                           .map((answer) => (
                              <li key={"saved-" + wheel.id + answer[0]}>
                                 {answer[1]}
                              </li>
                           ))}
                     </ol>
                  </>
               )}
               {wheel.results && (
                  <>
                     <br />
                     <b>Live Results</b>
                     {Object.entries(wheel.results).map((result) => (
                        <Grid container key={"saved-" + wheel.id + result[0]}>
                           <Grid item xs={2}>
                              {result[0]}
                           </Grid>
                           <Grid item xs={4}>
                              {result[1].answer}
                           </Grid>
                           <Grid item xs={3}>
                              {result[1].qty}
                           </Grid>
                           <Grid item xs={3}>
                              {result[1].qty === 0
                                 ? "0"
                                 : Math.floor(
                                      (result[1].qty / wheel.totalResults) * 100
                                   )}
                              %
                           </Grid>
                        </Grid>
                     ))}
                     <br />
                  </>
               )}
            </Box>
         )}
      </>
   );
}

export default WheelForm;
