import { useState, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";
import { useParams } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import LayoutTemplateEditor from "../layouts/LayoutTemplateEditorLeaderboard";

// MODAL
import Modal from "@mui/material/Modal";

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 600,
   bgcolor: "#efefef",
   border: "2px solid #cccccc",
   boxShadow: 24,
   p: 4,
};

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function StyleEditor(props) {
   // MODAL
   const [activeLeaderboard, setActiveLeaderboard] = useState({});

   const [modalOpen, setModalOpen] = useState(false);

   const handleOpen = (poll) => {
      setModalOpen(true);
   };
   const handleClose = () => {
      setModalOpen(false);
   };

   const handleUpdate = (leaderboard) => {
      setActiveLeaderboard(leaderboard);
   };

   // SOCKET
   const socket = useContext(SocketContext);

   // STYLES
   const [styles, setStyles] = useState();
   const [fonts, setFonts] = useState();
   const [selectedLayout, setSelectedLayout] = useState();

   useEffect(() => {
      // INITIAL LOAD
      socket.emit("getDBLeaderboard", (response) => {
         setActiveLeaderboard(response);
      });

      socket.emit("getStyles", (response) => {
         setStyles(response.stylesLeaderboard);
         setFonts(response.fonts);
      });
   }, []);

   useEffect(() => {
      socket.on("refreshStyles", () => {
         socket.emit("getDBLeaderboard", (response) => {
            socket.emit("getStyles", (styleResponse) => {
               setStyles(styleResponse.stylesLeaderboard);
               setFonts(styleResponse.fonts);
            });
         });
      });
   }, [socket]);

   const handleUpdateDB = (data) => {
      if (data.action == "insert") {
         data.layout.global.parameters.layoutTitle =
            "Copy of " + data.layout.global.parameters.layoutTitle;
      }

      socket.emit("updateLayoutLeaderboard", data, (response) => {
         setSelectedLayout(response.id);
      });
   };

   useEffect(() => {
      if (fonts) {
         fonts.map((font) => {
            var fontFace = new FontFace(
               font.split(".")[0],
               "url(" + SERVER_URL + "/fonts/" + font + ")"
            );

            fontFace
               .load()
               .then(function (loaded_face) {
                  document.fonts.add(loaded_face);
                  document.body.style.fontFamily =
                     '"' + font.split(".")[0] + '", Arial';
               })
               .catch(function (error) {
                  console.log(error);
               });
         });
      }
   }, [fonts]);

   return (
      <>
         {styles && (
            <LayoutTemplateEditor
               key={selectedLayout}
               activeLeaderboard={activeLeaderboard}
               fonts={fonts}
               styles={styles}
               selectedLayout={selectedLayout}
               setSelectedLayout={setSelectedLayout}
               updateDB={handleUpdateDB}
               openDataEditor={handleOpen}
            />
         )}
      </>
   );
}
