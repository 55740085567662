import { useState, useEffect } from "react";
import _ from "lodash";

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

function EditorPollDataForm(props) {
   const name = "list";
   const listKeys = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];

   const [previewPoll, setPreviewPoll] = useState(props.poll);
   const [numOptions, setNumOptions] = useState(
      Object.keys(props.poll.answers).length
   );
   const [stagedPolls, setStagedPolls] = useState(props.stagedPolls);
   const updateTitle = (e) => {
      setPreviewPoll((prevState) => {
         let newState = { ...prevState };
         newState.title = e.target.value;

         return newState;
      });
   };

   const updateAnswers = (e) => {
      setPreviewPoll((prevState) => {
         let newState = { ...prevState };

         let name = e.target.getAttribute("name");
         let value = e.target.value;

         newState.answers[name] = value;

         return newState;
      });

      setPreviewPoll((prevState) => {
         let newState = { ...prevState };

         let name = e.target.getAttribute("name");
         let value = e.target.value;

         newState.results[name]["answer"] = value;

         return newState;
      });
   };

   const updateQty = (e) => {
      setPreviewPoll((prevState) => {
         let newState = { ...prevState };

         let name = e.target.getAttribute("name");
         let value = e.target.value;

         if (value) {
            newState.results[name]["qty"] = parseInt(value);
         } else {
            delete newState.results[name];
         }

         newState.totalResults = _.sumBy(
            Object.entries(newState.results),
            (obj) => {
               return obj[1].qty;
            }
         );

         newState.lastRun = Date.now();
         newState.layout = "sample";

         return newState;
      });
   };

   const addOption = () => {
      setPreviewPoll((prevState) => {
         let newState = { ...prevState };
         let newKey = Object.keys(newState.answers).length;

         if (newKey <= 9) {
            newState = {
               ...newState,
               answers: {
                  ...newState.answers,
                  [listKeys[newKey]]: "",
               },
               results: {
                  ...newState.results,
                  [listKeys[newKey]]: { answer: "", qty: 0 },
               },
            };
         }

         return newState;
      });
   };

   const deleteOption = (key) => {
      setPreviewPoll((prevState) => {
         let newState = { ...prevState };
         let answers = { ...newState.answers };
         let results = { ...newState.results };

         delete answers[key];
         delete results[key];

         let updatedAnswers = {};
         let updatedResults = {};

         Object.entries(answers).forEach(([key, value], i) => {
            updatedAnswers[listKeys[i]] = value;
         });

         Object.entries(results).forEach(([key, value], i) => {
            updatedResults[listKeys[i]] = value;
         });

         newState = {
            ...newState,
            answers: updatedAnswers,
            results: updatedResults,
         };

         newState.totalResults = _.sumBy(
            Object.entries(newState.results),
            (obj) => {
               return obj[1].qty;
            }
         );

         newState.lastRun = Date.now();
         newState.layout = "sample";

         return newState;
      });
   };

   const handlePollDataFill = (e) => {
      setPreviewPoll((prevState) => {
         let newState = stagedPolls[e.target.value];
         newState.results = newState.answers;

         Object.keys(newState.results).forEach(function (key, i) {
            newState.results = {
               ...newState.results,
               [key]: { answer: newState.results[key], qty: i },
            };
         });

         newState.totalResults = _.sumBy(
            Object.entries(newState.results),
            (obj) => {
               return obj[1].qty;
            }
         );

         newState.lastRun = Date.now();
         newState.layout = "sample";

         return newState;
      });
   };

   return (
      <>
         <h5>Load data from existing poll:</h5>
         {stagedPolls && (
            <FormControl fullWidth sx={{ mb: 2 }}>
               <InputLabel id={"addPollLayouts"}>Layout</InputLabel>
               <Select
                  defaultValue={""}
                  labelId={"polldatamenu"}
                  label='Fill Poll data'
                  onChange={handlePollDataFill}
               >
                  {stagedPolls &&
                     stagedPolls.map((poll, i) => (
                        <MenuItem key={"poll" + i} value={i}>
                           {poll.title}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
         )}

         <h3>Preview Data</h3>

         <TextField
            key={`inputTitle`}
            label='Title'
            name='title'
            value={previewPoll.title}
            id='filled-size-small'
            variant='outlined'
            size='small'
            onChange={updateTitle}
            sx={{ mb: 3 }}
            fullWidth
         />

         {[...Array(Object.keys(previewPoll.answers).length)].map(
            (value, index) => (
               <Grid container spacing={2}>
                  <Grid item xs={9}>
                     <TextField
                        key={`inputKey${index}`}
                        label={listKeys[index]}
                        name={listKeys[index]}
                        id='filled-size-small'
                        value={
                           previewPoll.answers[listKeys[index]]
                              ? previewPoll.answers[listKeys[index]]
                              : ""
                        }
                        variant='outlined'
                        size='small'
                        onChange={updateAnswers}
                        sx={{ mb: 1.5 }}
                        fullWidth
                        InputProps={{
                           endAdornment: (
                              <InputAdornment position='end'>
                                 <IconButton
                                    edge='end'
                                    color='primary'
                                    onClick={() =>
                                       deleteOption(listKeys[index])
                                    }
                                 >
                                    <DeleteIcon />
                                 </IconButton>
                              </InputAdornment>
                           ),
                        }}
                     />
                  </Grid>
                  <Grid item xs={3}>
                     {previewPoll.results &&
                        previewPoll.results[listKeys[index]] && (
                           <TextField
                              key={`inputKey${index}`}
                              label='Quantity'
                              name={listKeys[index]}
                              id='filled-size-small'
                              value={
                                 previewPoll.results[listKeys[index]].qty
                                    ? previewPoll.results[listKeys[index]].qty
                                    : 0
                              }
                              variant='outlined'
                              size='small'
                              onChange={updateQty}
                              sx={{ mb: 1.5 }}
                              fullWidth
                           />
                        )}
                     {previewPoll.results &&
                        !previewPoll.results[listKeys[index]] && (
                           <TextField
                              key={`inputKey${index}`}
                              label='Quantity'
                              name={listKeys[index]}
                              id='filled-size-small'
                              value={0}
                              variant='outlined'
                              size='small'
                              onChange={updateQty}
                              sx={{ mb: 1.5 }}
                              fullWidth
                           />
                        )}
                  </Grid>
               </Grid>
            )
         )}
         <Button onClick={addOption}>+ Add Option</Button>

         <Button
            variant='contained'
            sx={{ mt: 2, display: "block" }}
            onClick={() => {
               props.updatePoll(previewPoll);
               props.closeModal({});
            }}
         >
            Update Preview Data
         </Button>
      </>
   );
}

export default EditorPollDataForm;
