import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";

import Box from "@mui/material/Box";
import RndContainer from "./RndContainer";

// CONTAINER FOR RND AND BOX SWAP IN LAYOUT
export default function Container({
   children,
   edit,
   name,
   data,
   zIndex,
   handleModal,
   handleUpdate,
   handleAdjust,
   previewScale,
   sizeCallback,
}) {
   if (edit) {
      return (
         <RndContainer
            key={name}
            id={name}
            as={motion.div}
            data={data}
            zIndex={zIndex}
            modal={handleModal}
            callback={handleUpdate}
            minSize={{ width: 100, height: 50 }}
            scale={previewScale}
            adjust={handleAdjust}
            scale={previewScale}
            sizeCallback={sizeCallback ? sizeCallback : function () {}}
         >
            {children}
         </RndContainer>
      );
   } else {
      return (
         <Box
            key={name}
            as={motion.div}
            sx={{
               position: "absolute",
               zIndex: { zIndex },
               boxSizing: "border-box",
               padding: 0,
               margin: 0,
               width: data.position.width,
               height: data.position.height,
               left: data.position.xPos,
               top: data.position.yPos,
            }}
         >
            {children}
         </Box>
      );
   }
}
