import React, { useState, useEffect } from "react";
import _ from "lodash";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const getTitle = (type) => {
   switch (type) {
      case "list":
         return "List";
         break;
      case "tugofwar":
         return "Tug of War";
         break;
   }
};

function PollContainer(props) {
   const [poll, setPoll] = useState();

   useEffect(() => {
      setPoll(props.poll);
   }, [props]);
   const handleLayoutChange = (event) => {
      poll.layout = event.target.value;
      props.updatePoll(poll);
   };

   const updateTitle = (e) => {
      setPoll((prevState) => {
         let newState = { ...prevState };

         let name = e.target.getAttribute("name");
         let value = e.target.value;

         newState[name] = value;

         return newState;
      });
   };

   const getEmoteData = (id) => {
      let index = _.findIndex(
         props.emotes,
         (row) => {
            return row.id === id;
         },
         0
      );

      if (index === -1) {
         return [{ id: "", name: "Emote Not Found" }];
      } else {
         return [props.emotes[index]];
      }
   };

   return (
      <>
         {poll && (
            <Box
               sx={{
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "boxOverride.border.dark",
                  px: 3,
                  py: 2,
                  mb: 2,
                  pb: 8,
                  position: "relative",
                  backgroundColor:
                     poll.type === "list"
                        ? "#0b1d31"
                        : poll.emote
                        ? "#1d0624"
                        : "#201907",
               }}
               key={poll.id}
            >
               {/* <Button
                  size='small'
                  color='error'
                  sx={{
                     position: "absolute",
                     top: 15,
                     right: 10,
                  }}
                  onClick={() => props.deletePoll(poll.id)}
               >
                  X
               </Button>
              {poll.type !== "tugofwar" && (
                  <Button
                     variant='contained'
                     size='small'
                     color='success'
                     sx={{
                        position: "absolute",
                        bottom: 20,
                        left: 24,
                     }}
                     onClick={() => props.loadPoll(poll)}
                  >
                     Load
                  </Button>
               )}
               {poll.type === "tugofwar" && (
                  <Button
                     variant='contained'
                     size='small'
                     color='success'
                     sx={{
                        position: "absolute",
                        bottom: 20,
                        left: 24,
                     }}
                     onClick={() => props.loadPoll(poll)}
                  >
                     Load Tug of War
                  </Button>
               )}
               {props.edit && (
                  <Button
                     variant='outlined'
                     size='small'
                     color='error'
                     sx={{
                        position: "absolute",
                        bottom: 20,
                        right: 24,
                     }}
                     onClick={() => props.handleOpen(poll)}
                  >
                     Edit
                  </Button>
               )} */}
               {poll.totalResults > 0 && (
                  <>
                     <b>Total Answers:</b> {poll.totalResults}
                  </>
               )}
               <Box
                  sx={{
                     mb: 3,
                     fontSize: 16,
                     textTransform: "uppercase",
                     fontWeight: "bold",
                  }}
               >
                  {getTitle(poll.type)} {poll.emote && "EMOTE"} POLL
               </Box>

               <Box>
                  <b>{poll.title}</b>
               </Box>

               {props.layouts.length > 0 && (
                  <Box>
                     <i>
                        {props.layouts.find((x) => x.id === poll.layout).title}
                     </i>
                  </Box>
               )}
               {!poll.results && (
                  <Grid
                     container
                     sx={{ mt: 2, mb: 0, p: 1, border: "1px solid #ccc" }}
                     justifyContent='center'
                     alignItems='center'
                  >
                     <Grid
                        item
                        xs={12}
                        sx={{
                           textAlign: "center",
                           fontWeight: "bold",
                        }}
                     >
                        Answers
                     </Grid>
                     {Object.entries(poll.answers)
                        .sort()
                        .map((answer) => (
                           <Grid
                              item
                              key={"saved-" + poll.id + answer[0]}
                              sx={{ m: 1, textAlign: "center" }}
                           >
                              {poll.type !== "tugofwar" && (
                                 <>
                                    {answer[0]}: {answer[1]}
                                 </>
                              )}
                              {poll.type === "tugofwar" && !poll.emote && (
                                 <>
                                    {answer[0]}: {answer[1]}
                                 </>
                              )}
                              {poll.type === "tugofwar" && poll.emote === true && (
                                 <>
                                    {getEmoteData(answer[1]).map((data) => (
                                       <React.Fragment
                                          key={"tugofwarEmote-" + data.id}
                                       >
                                          {data && data.images && (
                                             <img
                                                src={data.images.url_2x}
                                                style={{
                                                   display: "block",
                                                   margin: "1em",
                                                   width: 35,
                                                }}
                                                alt='Emote'
                                             />
                                          )}
                                          <b
                                             style={{
                                                color:
                                                   data.id === "" ? "red" : "",
                                             }}
                                          >
                                             {data.name}
                                          </b>
                                       </React.Fragment>
                                    ))}
                                 </>
                              )}
                           </Grid>
                        ))}
                  </Grid>
               )}
               {poll.results && (
                  <Box>
                     <b>Results</b>
                     {Object.entries(poll.results)
                        .sort()
                        .map((result) => (
                           <Grid container key={"saved-" + poll.id + result[0]}>
                              <Grid item xs={2}>
                                 {result[0]}
                              </Grid>
                              <Grid item xs={4}>
                                 {result[1].answer}
                              </Grid>
                              <Grid item xs={3}>
                                 {result[1].qty}
                              </Grid>
                              <Grid item xs={3}>
                                 {result[1].qty === 0
                                    ? "0"
                                    : Math.floor(
                                         (result[1].qty / poll.totalResults) *
                                            100
                                      )}
                                 %
                              </Grid>
                           </Grid>
                        ))}
                  </Box>
               )}
               {poll.type === "list" && (
                  <Box>
                     <b>Duration:</b> {poll.duration} seconds
                  </Box>
               )}
               {poll.totalResults > 0 && (
                  <Box>
                     <b>Last Run: </b>
                     {new Date(poll.lastRun).toLocaleString()}
                  </Box>
               )}
            </Box>
         )}
      </>
   );
}

export default PollContainer;
